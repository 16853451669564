<template>
  <div class="py-16 flex justify-around">
    <table class="table-auto p-3 border-1 rounded-md">
      <thead>
        <tr>
          <th>Email</th>
          <th v-if="isB2BManager">B2B access</th>
          <th v-if="isSunsureManager">Sunsure access</th>
          <th v-if="isLeadFormManager">Lead Form access</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.email }}</td>
          <td v-if="isB2BManager">
            <switch-component
              :value="user.hasB2BAccess"
              @input="(e) => updateUser(user.id, 'b2b', e)"
            ></switch-component>
          </td>
          <td v-if="isSunsureManager">
            <switch-component
              :value="user.hasSunsureAccess"
              @input="(e) => updateUser(user.id, 'sunsure', e)"
            ></switch-component>
          </td>
          <td v-if="isLeadFormManager">
            <switch-component
              :value="user.hasLeadFormAccess"
              @input="(e) => updateUser(user.id, 'leadForm', e)"
            ></switch-component>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import {
  cognito,
  adminUserPoolId,
  adminB2BGroupName,
  adminSunsureGroupName,
  adminLeadFormGroupName,
  toDDBFormat,
  ddb,
} from '@/aws'
import SwitchComponent from '@/components/utils/Switch'
import Auth from '@aws-amplify/auth'
import { useStore } from 'vuex'

async function fetchAllCognitoUsers(paginationToken) {
  const { Users: users, PaginationToken: nextPaginationToken } = await cognito
    .listUsers({
      UserPoolId: adminUserPoolId,
      Limit: 60,
      PaginationToken: paginationToken,
    })
    .promise()

  if (nextPaginationToken) return [...users, ...(await fetchAllCognitoUsers(nextPaginationToken))]
  else return users
}

async function fetchAllCognitoUsersInGroup(groupName, paginationToken) {
  const { Users: users, NextToken: nextPaginationToken } = await cognito
    .listUsersInGroup({
      UserPoolId: adminUserPoolId,
      Limit: 60,
      GroupName: groupName,
      NextToken: paginationToken,
    })
    .promise()

  if (nextPaginationToken)
    return [...users, ...(await fetchAllCognitoUsersInGroup(groupName, nextPaginationToken))]
  else return users
}

export default {
  components: {
    SwitchComponent,
  },
  setup() {
    const users = ref([])
    const b2bAdminName = ref('')

    async function fetchUsers() {
      const allUsers = await fetchAllCognitoUsers()
      const b2bUsers = await fetchAllCognitoUsersInGroup(adminB2BGroupName)
      const sunsureUsers = await fetchAllCognitoUsersInGroup(adminSunsureGroupName)
      const leadFormUsers = await fetchAllCognitoUsersInGroup(adminLeadFormGroupName)

      const get = (Attributes, n) =>
        Attributes.filter(({ Name }) => Name === n).map((a) => a.Value)[0]

      allUsers.sort((a, b) => (get(a.Attributes, 'email') > get(b.Attributes, 'email') ? 1 : -1))

      users.value = allUsers.map(({ Username, Attributes }) => {
        return {
          id: Username,
          email: get(Attributes, 'email'),
          hasSunsureAccess: !!sunsureUsers.find((s) => s.Username === Username),
          hasB2BAccess: !!b2bUsers.find((b) => b.Username === Username),
          hasLeadFormAccess: !!leadFormUsers.find((l) => l.Username === Username),
        }
      })
    }
    onMounted(fetchUsers)

    const updateUser = async (username, type, shouldHaveAccess) => {
      const fieldName = {
        b2b: 'hasB2BAccess',
        sunsure: 'hasSunsureAccess',
        leadForm: 'hasLeadFormAccess'
      }[type]
      const groupName = {
        b2b: adminB2BGroupName,
        sunsure: adminSunsureGroupName,
        leadForm: adminLeadFormGroupName,
      }[type]

      try {
        // store in ddb
        const {
          idToken: {
            payload: { email },
          },
        } = await Auth.currentSession()
        const logData = {
          admin_email: email,
          created_at: new Date().toISOString(),
          changed_admin_email: email,
          type,
          has_access: shouldHaveAccess ? 'YES' : 'NO',
        }
        const ddbParams = {
          Item: toDDBFormat(logData),
          TableName: 'admin_permission_logs',
        }

        const input = {
          UserPoolId: adminUserPoolId,
          GroupName: groupName,
          Username: username,
        }
        if (shouldHaveAccess) {
          await cognito.adminAddUserToGroup(input).promise()
        } else {
          await cognito.adminRemoveUserFromGroup(input).promise()
        }

        await ddb.putItem(ddbParams).promise()
        users.value.find(({ id }) => id === username)[fieldName] = shouldHaveAccess
      } catch (error) {
        alert(`Error: ${error.message}`)
      }
    }

    const store = useStore()
    const isB2BManager = computed(() => store.getters.isB2BManager)
    const isSunsureManager = computed(() => store.getters.isSunsureManager)
    const isLeadFormManager = computed(() => store.getters.isLeadFormManager)

    return {
      users,
      b2bAdminName,
      updateUser,
      isB2BManager,
      isSunsureManager,
      isLeadFormManager,
    }
  },
}
</script>

<style scoped lang="postcss">
td,
th {
  @apply p-2;
}
</style>
