<template>
  <div class="filter" :class="{ active: value !== '' && value.length !== 6 }">
    <template v-if="type === 'checkbox'">
      <fieldset>
        <legend @click="$emit('open')">
          {{ name }}
          <question-mark-icon class="w-5 inline-block" />
        </legend>

        <div>
          <div v-for="(option, i) in options" :key="i + 's'" class="relative flex items-start mt-3">
            <div class="flex items-center h-5 w-5">
              <input
                :id="option"
                :value="option"
                v-model="value"
                :type="type"
                class="focus:ring-orange-600 h-5 w-5 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div class="ml-3 text-md">
              <label :for="option" class="font-medium text-gray-700">{{ option }}</label>
            </div>
          </div>
        </div>
      </fieldset>
    </template>

    <template v-else>
      <label :for="filterName">{{ label }}</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <select :id="filterName" v-if="options" v-model="value">
          <option value="">Any</option>
          <option :value="value" v-for="[value, label] in options" :key="value">
            {{ label || value }}
          </option>
        </select>
        <input
          :type="type"
          v-else
          v-model.trim.lazy="value"
          :id="filterName"
          :placeholder="placeholder"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { computed } from 'vue'
import QuestionMarkIcon from 'heroicons/vue/outline/QuestionMarkCircle'

export default {
  props: {
    name: String,
    label: String,
    modelValue: [String, Number, Boolean, Array],
    options: Array,
    placeholder: String,
    type: {
      required: false,
      default: 'text',
      validator(val) {
        return ['number', 'text', 'checkbox'].includes(val)
      },
    },
  },
  components: {
    QuestionMarkIcon,
  },
  emits: ['update:modelValue', 'open'],
  setup(props, { emit }) {
    const value = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      },
    })

    return {
      value,
      filterName: computed(() => `${props.name}Filter`),
    }
  },
}
</script>

<style lang="postcss" scoped>
.filter {
  @apply mt-1 border-l-4 px-2 py-1;
}

.filter.active {
  @apply border-blue-400 bg-blue-100;
}

select,
input {
  @apply block w-full sm:text-sm border-gray-300 rounded-md;
}
</style>
