<template>
  <th class="whitespace-nowrap cursor-pointer" @click="emit('toggleSort', header)">
    <span :title="title">
      {{ headerName }}
    </span>
    <selector class="ml-1 w-4 inline-block" v-if="header !== sortBy"></selector>
    <template v-else>
      <arrow-narrow-up v-if="sortType === 'desc'" class="ml-1 w-4 inline-block"></arrow-narrow-up>
      <arrow-narrow-down
        v-if="sortType === 'asc'"
        class="ml-1 w-4 inline-block"
      ></arrow-narrow-down>
    </template>
  </th>
</template>

<script>
import { ArrowNarrowDown, ArrowNarrowUp, Selector } from 'heroicons/vue/solid/'

export default {
  emits: ['toggleSort'],
  props: ['sortType', 'sortBy', 'headerName', 'header', 'title'],
  components: {
    ArrowNarrowDown,
    ArrowNarrowUp,
    Selector,
  },
  setup(_, { emit }) {
    return {
      emit,
    }
  },
}
</script>
