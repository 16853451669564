<template>
  <StatusInfo v-if="showStatusInfo" @close="showStatusInfo = false" />
  <sidebar-layout>
    <template v-slot:sidebar>
      <div class="mb-5">
        <sg-button
          class="m-auto"
          :color="'green'"
          :text="'Create New Location'"
          @click="showCreateLocationForm = true"
        />
      </div>

      <div class="flex flex-row h-6 ml-1 mb-2">
        <div class="text-md font-semibold">Filters</div>
        <div v-if="!emptyFilter">
          <button class="small-blue-button ml-2" @click="resetFilter">Reset</button>
        </div>
      </div>

      <FilterInput
        label="Sales area"
        name="countryCode"
        v-model="filter.countryCode"
        :options="[
          ['NL', 'the Netherlands'],
          ['BE', 'Belgium'],
        ]"
      />

      <FilterInput
        label="Market type"
        name="hasLocationGroup"
        v-model="filter.hasLocationGroup"
        :options="[
          [false, 'B2C'],
          [true, 'B2B'],
        ]"
      />

      <FilterInput
        label="Status"
        name="Status"
        v-model="filter.locationStatusList"
        @open="showStatusInfo = true"
        :options="locationStatusFilterOptions"
        type="checkbox"
      />

      <FilterInput
        label="Account number"
        name="accountNumber"
        v-model="filter.accountNumber"
        type="number"
        placeholder="e.g. 123456"
      />

      <FilterInput
        label="Meter/inverter type"
        name="containsMeteringSystemBrand"
        v-model="filter.containsMeteringSystemBrand"
        :options="[['Omnik'], ['Solis'], ['SolarEdge'], ['Simpl', 'SIMPL']]"
      />

      <FilterInput
        label="B2B / Woco name"
        name="locationGroupId"
        v-model="filter.locationGroupId"
        :options="locationGroups ? locationGroups.map((lg) => [lg.id, lg.friendlyName]) : []"
      />

      <FilterInput
        label="System installed by"
        name="installedBySungevity"
        v-model="filter.installedBySungevity"
        :options="[
          [true, 'Sungevity'],
          [false, 'Other'],
        ]"
      />

      <FilterInput
        label="Address starts with"
        name="addressLike"
        v-model="filter.addressLike"
        placeholder="e.g. Kerkstraat"
      />

      <FilterInput
        label="Serial number / device name"
        name="containsMeteringSystemIdentifier"
        v-model="filter.containsMeteringSystemIdentifier"
        placeholder="e.g. XMX001234"
      />
    </template>
    <template v-slot:content>
      <CreateLocationForm
        v-if="showCreateLocationForm"
        @close="showCreateLocationForm = false"
        @success="redirectToLocation"
        :location-groups="locationGroups"
      />

      <!-- Page title & actions -->
      <div class="border-b border-gray-200 py-4 flex items-center justify-between px-8">
        <div class="flex-1 min-w-0">
          <h1 class="text-lg font-medium leading-6">
            Monitoring platform Locations
          </h1>
          <Spinner
            class="w-6 h-6 border-4 border-gray-500 rounded-full loader"
            v-if="loading"
          ></Spinner>
          <h2 class="text-md font-medium leading-6 text-gray-400 h-6" v-else>
            {{ `Found ${formatNumber(totalCount)} ${totalCount !== 1 ? 'locations' : 'location'}` }}
          </h2>
        </div>
        <div class="flex" v-if="locations && totalCount >= 1">
          <button
            class="flex px-3 py-2 items-center justify-center rounded-md bg-blue-300 text-white"
            :class="!exportLoading && 'hover:bg-blue-400'"
            @click="exportToCsv"
            :disabled="exportLoading"
          >
            <div
              class="w-4 h-4 border-2 border-blue-500 rounded-full loader"
              v-if="exportLoading"
            ></div>
            <span class="px-2">Export {{ totalCount }} location(s) to .csv</span>
          </button>
        </div>
      </div>

      <div
        class="p-4 sm:rounded-md bg-red-100 sm:w-1/2 lg:w-1/3 w-full mx-auto my-4"
        v-if="listError"
      >
        {{ listError }}
      </div>

      <table class="min-w-full divide-y divide-gray-300" :class="loading ? 'opacity-30' : ''">
        <thead class="bg-gray-200">
          <tr>
            <th>Address</th>
            <th>Account Number</th>
            <th>Inverter status</th>

            <SortableHeader
              :sortType="sortType"
              :sortBy="sortBy"
              :header="'alertStartedOn'"
              :headerName="'Start alert'"
              @toggleSort="toggleSort"
            />

            <SortableHeader
              :sortType="sortType"
              :sortBy="sortBy"
              :header="'absolutePerformance'"
              :headerName="'Performance'"
              :title="
                'Performance gain/loss calculated by using the average (median) performance of all locations in the past 3 months'
              "
              @toggleSort="toggleSort"
            />

            <th>Sales area</th>
            <th>Market type</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="location in locations" :key="location.id">
            <td>
              <div class="text-md">{{ location.address || '(empty)' }}</div>
              <div class="text-xs text-gray-400">{{ location.postalCode }} {{ location.city }}</div>
            </td>
            <td>{{ location.accountNumber }}</td>
            <td>
              <template v-if="location.meteringSystems.length > 0">
                <template v-for="(ms, index) in location.meteringSystems" :key="index + 'ms'">
                  <span
                    class="badge mt-2 whitespace-nowrap"
                    :class="getMeterStatusColor(ms)"
                    :title="`Last seen: ${location.lastReadingAt || 'never'}`"
                  >
                    {{ `${ms.brand}: ${ms.status}` }}
                  </span>
                  <br />
                </template>
              </template>

              <template v-else>
                <span class="badge bg-yellow-100 text-yellow-800">
                  {{ 'No metering system' }}
                </span>
              </template>
            </td>
            <td>
              {{ toFirstAlertStartedOn(location.meteringSystems) }}
            </td>
            <td>
              {{ formatWh(location.absolutePerformance, 0) }}
            </td>
            <td>
              {{ location.countryCode }}
            </td>
            <td>
              {{ location.locationGroupId ? 'B2B' : 'B2C' }}
            </td>
            <td>
              <router-link
                class="detail-button"
                :to="{ name: 'location-details', params: { id: location.id } }"
              >
                Details
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>

      <pagination :page="page" :pages="pages" @next="page += 1" @previous="page -= 1"></pagination>
    </template>
  </sidebar-layout>
</template>

<script>
import { computed, ref, watch } from 'vue'
import CreateLocationForm from '@/components/CreateLocationForm'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { useRoute, useRouter } from 'vue-router'
import { s3 } from '@/aws'
import { Spinner, Pagination, SidebarLayout, SortableHeader } from '@/components/utils'
import FilterInput from '@/components/FilterInput'
import { formatNumber, formatWh } from '@/utils'
import StatusInfo from '@/components/StatusInfo'
import { DateTime } from 'luxon'
import { equals } from 'rambda'

const numberOfLocationsPerPage = 20

export default {
  name: 'LocationsList',
  components: {
    CreateLocationForm,
    SidebarLayout,
    Pagination,
    Spinner,
    FilterInput,
    StatusInfo,
    SortableHeader,
  },
  setup() {
    const showCreateLocationForm = ref(false)
    const limit = numberOfLocationsPerPage
    const page = ref(0)
    const offset = computed(() => page.value * limit)
    const sortBy = ref(null)
    const sortType = ref(null)
    const router = useRouter()
    const route = useRoute()

    const toFirstAlertStartedOn = (meteringsystems) => {
      const alertDates = meteringsystems
        .filter((ms) => !ms.inactive)
        .map((ms) => ms.alert && ms.alert.startedOn)
        .filter(Boolean)
        .map((d) => DateTime.fromISO(d))
      if (alertDates.length === 0) return '-'

      const firstDate = DateTime.min(...alertDates)
      return firstDate.toISODate()
    }

    function redirectToLocation(id) {
      router.push({
        name: 'location-details',
        params: {
          id,
        },
      })
    }

    function toggleSort(sortCategory) {
      sortBy.value = sortCategory
      sortType.value = sortType.value === 'desc' ? 'asc' : 'desc'
    }

    const locationStatusFilterOptions = [
      'Functioning',
      'Never connected',
      'Recently disconnected',
      'Zero production measured from beginning',
      'Zero production measured recently',
      'Faulty production measured recently',
      'Inactive',
    ]

    const emptyFilterValue = {
      countryCode: '',
      hasLocationGroup: '',
      accountNumber: '',
      containsMeteringSystemBrand: '',
      locationGroupId: '',
      addressLike: '',
      containsMeteringSystemIdentifier: '',
      locationStatusList: locationStatusFilterOptions,
      installedBySungevity: '',
    }

    const filter = ref({
      ...emptyFilterValue,
      accountNumber: route.query.accountNumber || '',
    })
    function resetFilter() {
      filter.value = { ...emptyFilterValue }
    }
    const emptyFilter = computed(() => {
      // we need to standardize the order of the location status list otherwise the equals doesn't match with the empty filter value
      filter.value.locationStatusList = filter.value.locationStatusList.sort(
        (a, b) =>
          locationStatusFilterOptions.findIndex((s) => s === a) -
          locationStatusFilterOptions.findIndex((s) => s === b)
      )
      return equals(filter.value, emptyFilterValue)
    })

    const queryFilter = computed(() => {
      return {
        countryCode: filter.value.countryCode || null,
        // hasLocationGroup can be true, false or an empty string
        hasLocationGroup:
          filter.value.hasLocationGroup === '' ? null : filter.value.hasLocationGroup,
        accountNumber: filter.value.accountNumber || null,
        locationStatusList: filter.value.locationStatusList,
        containsMeteringSystemBrand: filter.value.containsMeteringSystemBrand || null,
        locationGroupId: filter.value.locationGroupId || null,
        addressLike: filter.value.addressLike || null,
        containsMeteringSystemIdentifier: filter.value.containsMeteringSystemIdentifier || null,
        installedBySungevity:
          filter.value.installedBySungevity === '' ? null : filter.value.installedBySungevity,
      }
    })
    // when the filter changes, move back to page 1
    watch(filter, () => (page.value = 0), { deep: true })

    const { result, loading, error: listError } = useQuery(
      gql`
        query getLocations(
          $filter: LocationsFilter
          $limit: Int!
          $offset: Int!
          $sortBy: SortBy
          $sortType: SortType
        ) {
          getLocations(
            filter: $filter
            limit: $limit
            offset: $offset
            sortBy: $sortBy
            sortType: $sortType
          ) {
            totalCount
            nodes {
              id
              address
              postalCode
              city
              accountNumber
              countryCode
              lastReadingAt
              locationGroupId
              absolutePerformance
              meteringSystems {
                id
                inactive {
                  since
                  setBy
                  type
                }
                status
                brand
                alert {
                  category
                  startedOn
                }
              }
            }
          }
        }
      `,
      {
        filter: queryFilter,
        limit,
        offset,
        sortBy,
        sortType,
      },
      {
        debounce: 1000,
      }
    )

    const totalCount = computed(() => result.value?.getLocations?.totalCount || 0)
    const locations = computed(() => result.value?.getLocations?.nodes)
    const pages = computed(() => Math.ceil(totalCount.value / limit))

    watch(
      () => route.query.accountNumber,
      (accountNumber) => {
        if (accountNumber) {
          filter.value.accountNumber = accountNumber
        }
      }
    )
    watch(loading, () => {
      if (!loading.value && route.query.accountNumber) {
        if (locations.value.length === 1) {
          redirectToLocation(locations.value[0].id)
        } else {
          router.push({ name: 'locations-list', query: {} })
        }
      }
    })

    const { result: locationGroupsResult } = useQuery(gql`
      query getLocationGroups {
        getAbstractLocationGroups {
          id
          friendlyName
        }
      }
    `)
    const locationGroups = computed(
      () => locationGroupsResult.value && locationGroupsResult.value.getAbstractLocationGroups
    )

    async function s3FileExist(bucket, key) {
      try {
        console.log(`Checking if S3 file exists bucket:${bucket}, key:${key}`)
        await s3.headObject({ Bucket: bucket, Key: key }).promise()
        return true
      } catch (e) {
        if (e.name === 'NotFound') return false
        else {
          notifyUserOnFailedCSVExport(
            `Checking for S3 file returned unexpected exception ${e.message}`
          )
          throw e
        }
      }
    }

    const sleep = (ms) => {
      console.log(`Sleeping for ${ms}ms`)
      return new Promise((res) => setTimeout(res, ms))
    }

    async function waitTillS3FileExists(bucket, key, n = 1) {
      if (await s3FileExist(bucket, key)) return true
      else if (n > 100) return false
      else {
        await sleep(Math.pow(n, 1.5) * 1000)
        return waitTillS3FileExists(bucket, key, n + 1)
      }
    }

    // If we introduce more exports, we might introduce a component for a CSV export.
    // See the SunSure overview for a similar export functionality.
    const { mutate: exportToCsv, loading: exportLoading, onDone, onError } = useMutation(
      gql`
        mutation exportLocations($filter: LocationsFilter) {
          export: exportLocationsWithCustomerDetailsToCsv(filter: $filter) {
            s3Key
            s3Bucket
          }
        }
      `,
      () => ({
        variables: {
          filter: queryFilter.value,
        },
      })
    )

    function getMeterStatusColor(ms) {
      if (ms.status === 'Inactive') return 'badge bg-yellow-200 text-yellow-700'
      else if (ms.alert && ms.alert.category) return 'badge bg-red-100 text-red-800'
      else return 'badge bg-green-100 text-green-800'
    }

    function notifyUserOnFailedCSVExport(detailMessage) {
      alert(
        `Ooops, this is unexpected, the CSV export failed. We are sorry for that. Please create a helpdesk ticket for the IT so that they can take a look at it.` +
          (detailMessage ? ` Detail for IT: ${detailMessage}` : '')
      )
    }

    const isCheckingForS3FileInProgress = ref(false)
    onDone(async (response) => {
      if (!response.data?.export?.s3Key) {
        notifyUserOnFailedCSVExport(`No s3Key found in GraphQL response`)
        return
      }

      const { s3Bucket, s3Key } = response.data.export
      isCheckingForS3FileInProgress.value = true
      const s3FileExists = await waitTillS3FileExists(s3Bucket, s3Key)
      isCheckingForS3FileInProgress.value = false

      if (!s3FileExists) {
        notifyUserOnFailedCSVExport(`File not found in S3: ${s3Bucket}/${s3Key}`)
        return
      }

      window.location.href = await s3.getSignedUrlPromise('getObject', {
        Bucket: s3Bucket,
        Key: s3Key,
      })
    })

    // could be easier to use the error from the useMutation call, see https://v4.apollo.vuejs.org/api/use-mutation.html
    onError((error) => {
      console.error(`Encountered error while downloading the CSV file`, JSON.stringify(error))
      const errorMessage =
        error.networkError?.result?.errors?.[0]?.message || error.graphQLErrors?.[0]?.message
      notifyUserOnFailedCSVExport(errorMessage)
    })

    return {
      filter,
      redirectToLocation,
      getMeterStatusColor,
      showCreateLocationForm,
      locations,
      listError,
      loading,
      pages,
      page,
      totalCount,
      locationGroups,
      exportToCsv,
      exportLoading: computed(() => exportLoading.value || isCheckingForS3FileInProgress.value),
      emptyFilter,
      resetFilter,
      formatNumber,
      locationStatusFilterOptions,
      showStatusInfo: ref(false),
      sortBy,
      sortType,
      toggleSort,
      toFirstAlertStartedOn,
      formatWh,
    }
  },
}
</script>

<style lang="postcss" scoped>
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}

th {
  @apply px-6 py-3 text-xs font-medium uppercase text-left;
}

.badge {
  @apply px-2 py-1 inline-flex text-xs font-semibold rounded-full;
}

td {
  @apply px-6 py-3;
}

.detail-button {
  @apply bg-blue-100 text-blue-800 hover:bg-blue-200 px-3 py-2 rounded-md;
}
.small-blue-button {
  @apply bg-blue-100 text-xs text-blue-700 hover:bg-blue-200 px-2 py-1 rounded-md;
}
</style>
