<template>
  <dialog-layout title="Update Complex Number">
    <template v-slot:content>
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
          <label for="state" class="block text-sm font-medium text-gray-700">
            Complex Number
          </label>
          <input
            type="text"
            name="state"
            v-model.trim="vv.customerReference.$model"
            id="state"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div class="mt-5 text-red-600 text-sm" v-if="vv.$error || error">
        {{ error || vv.$errors[0].$message }}
      </div>
    </template>
    <template v-slot:buttons>
      <button
        class="submit-button"
        type="button"
        @click="onSubmit"
        v-if="!loading"
      >
        Confirm
      </button>
      <button
        class="cancel-button"
        type="button"
        @click="$emit('close')"
        v-if="!loading"
      >
        Cancel
      </button>
      <Spinner
        class="w-6 h-6 border-4 border-gray-500 rounded-full loader"
        v-if="loading"
      ></Spinner>
    </template>
  </dialog-layout>
</template>

<script>
import { computed, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, maxLength } from '@vuelidate/validators'
import gql from 'graphql-tag'
import { useMutation } from '@vue/apollo-composable'
import DialogLayout from '@/components/utils/DialogLayout'
import Spinner from '@/components/utils/Spinner'

export default {
  components: {
    DialogLayout,
    Spinner,
  },
  emits: ['success', 'close'],
  props: {
    locationId: Number,
  },
  setup(props, { emit }) {
    const customerReference = ref('')

    const vv = useVuelidate(
      {
        customerReference: {
          required: helpers.withMessage('You must enter a value', required),
          maxLength: maxLength(20)
        },
      },
      {
        customerReference,
      } 
    )

    const { mutate, loading, error } = useMutation(gql`
      mutation UpdateCustomerReference(
        $locationId: Int!
        $customerReference: String!
      ) {
        updateCustomerReference(
          locationId: $locationId
          customerReference: $customerReference
        ) {
          id
          customerReference
        }
      }
    `)

    const onSubmit = async () => {
      vv.value.$touch()
      if (vv.value.$invalid) return

      const variables = {
        locationId: props.locationId,
        customerReference: customerReference.value,
      }

      if (
        !confirm(
          `This will update the complex number to ${customerReference.value} in the monitoring platform. Continue?`
        )
      )
        return

      const result = await mutate(variables)
      if (result.errors) {
        error.value = result.errors[0]
        return
      }
      if (result.data) {
        emit('close')
      }
    }

    return {
      vv,
      onSubmit,
      loading,
      error: computed(() => error.value && error.value.message),
    }
  },
}
</script>

<style scoped lang="postcss">
.submit-button {
  @apply w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm
}
.cancel-button {
  @apply mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
}
</style>
