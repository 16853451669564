<template>
  <transition name="modal">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border-t-4"
          :class="{
            isWarning: 'border-yellow-400',
            isInfo: 'border-gray-400',
          }"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="flex flex-row">
            <div
              v-if="isWarning"
              class="rounded-full bg-yellow-100 text-yellow-400 w-10 h-10 m-3 p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div>
              <div class="p-2 text-lg items-center">
                <slot name="header"> </slot>
              </div>

              <div class="p-2 text-gray-500">
                <slot name="body"> </slot>
              </div>
            </div>
          </div>

          <div class="mt-2 p-3 border-2 bg-gray-100 flex flex-row-reverse">
            <slot name="confirm">
              <button
                class="border-1 border-blue-700 p-3 rounded-md bg-yellow-400 hover:bg-yellow-300 mx-3 font-semibold text-yellow-800"
                @click="$emit('confirm')"
              >
                Add inverter
              </button>
            </slot>
            <button
              class="border-1 border-gray-700 p-3 rounded-md bg-gray-100 hover:bg-gray-200 mx-3"
              @click="$emit('cancel')"
            >
              <slot name="reject">
                Cancel
              </slot>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'dialog-modal',
  slots: ['type'],
  computed: {
    isWarning() {
      return this.type === 'warning'
    },
    isInfo() {
      return this.type === 'info'
    },
  },
}
</script>
