<template>
  <div>
    <spinner
      class="border-blue-500 w-8 h-8 border-4 mx-auto mt-5"
      v-if="loading && !meteringSystem"
    ></spinner>

    {{ error }}

    <div
      class="border border-gray-300 shadow-md rounded-xl px-4 pt-6 pb-6 bg-white relative"
      v-if="meteringSystem"
    >
      <h1 class="leading-12 text-3xl">
        {{ meteringSystem.brand }} {{ meteringSystem.hardwareIdentifier.logicalDeviceName }}
      </h1>

      <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          @click="close"
        >
          <span class="sr-only">Close</span>
          <XIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <div class="mt-2">
        <div v-for="measurement in measurements" :key="measurement.id" class="select-measurement">
          <input
            type="checkbox"
            v-model="selectedMeasurements"
            :id="measurement.id"
            :value="measurement.id"
          />
          <label :for="measurement.id">{{ measurement.typeName }} [{{ measurement.unit }}]</label>
        </div>
      </div>

      <div class="flex items-center mt-2">
        <date-picker v-model="selectedDate" class="rounded border-gray-400"></date-picker>
        <spinner class="border-gray-400 w-6 h-6 border-4 mx-auto ml-5" v-if="loading"></spinner>
      </div>

      <line-chart
        :class="{ loading }"
        :data="data"
        :library="{
          scales: {
            x: {
              display: true,
              type: 'time',
              time: {
                tooltipFormat: 'HH:mm',
                unit: 'hour',
                displayFormats: {
                  hour: 'HH:mm',
                },
              },
            },
          },
        }"
      ></line-chart>

      <div class="disclaimer">
        This data is retrieved from the SIMPL platform directly and is not related to the data we
        show in MySungevity.
      </div>
    </div>
  </div>
</template>

<script>
import { useQuery, useResult } from '@vue/apollo-composable'
import { useRoute, useRouter } from 'vue-router'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import Spinner from '@/components/utils/Spinner'
import DatePicker from 'vue3-datepicker'
import { DateTime } from 'luxon'
import XIcon from 'heroicons/vue/outline/X'

export default {
  components: {
    Spinner,
    DatePicker,
    XIcon,
  },
  setup() {
    const route = useRoute()
    const selectedMeasurements = ref([])
    const selectedDate = ref(
      DateTime.local()
        .minus({ day: 1 })
        .toJSDate()
    )

    const { result, loading, error } = useQuery(
      gql`
        query GetMeteringSystem(
          $id: Int!
          $intervalStartAt: AWSDateTime!
          $intervalEndAt: AWSDateTime!
        ) {
          getMeteringSystem(id: $id) {
            id
            brand
            hardwareIdentifier {
              serialNumber
              logicalDeviceName
            }
            measurements {
              id
              typeName
              unit
              values(intervalStartAt: $intervalStartAt, intervalEndAt: $intervalEndAt) {
                value
                intervalStartAt
              }
            }
          }
        }
      `,
      () => {
        const dateInUtc = DateTime.fromJSDate(selectedDate.value)
          // the keeplocaltime prevents the date from shifting
          // we're not interested in the time, only the date
          .setZone('UTC', { keepLocalTime: true })
        return {
          id: route.params.meteringSystemId,
          intervalStartAt: dateInUtc.set({ hours: 4, minutes: 0 }).toISO(),
          intervalEndAt: dateInUtc.set({ hours: 22, minutes: 0 }).toISO(),
        }
      }
    )
    const meteringSystem = useResult(result)
    const measurements = computed(() => meteringSystem.value && meteringSystem.value.measurements)
    watch(measurements, (val) => {
      if (val && val.length > 0 && selectedMeasurements.value.length === 0)
        selectedMeasurements.value = [val[0].id]
    })

    const data = computed(
      () =>
        measurements.value &&
        measurements.value
          .filter((m) => selectedMeasurements.value.includes(m.id))
          .map((measurement) => ({
            name: `${measurement.typeName} [${measurement.unit}]`,
            data: measurement.values.map((m) => [m.intervalStartAt, m.value]),
          }))
    )

    const router = useRouter()
    function close() {
      router.push({
        name: 'location-details',
        params: route.params,
      })
    }

    return {
      measurements,
      loading,
      error,
      meteringSystem,
      selectedMeasurements,
      data,
      selectedDate,
      close,
    }
  },
}
</script>

<style lang="postcss" scoped>
.select-measurement {
  @apply rounded border-blue-200 inline-block mr-2;
}
.select-measurement label {
  @apply ml-1;
}
.select-measurement input {
  @apply border-gray-300 rounded;
}
.loading {
  opacity: 0.3;
}
.disclaimer {
  @apply mt-3 text-xs;
}
</style>
