<template>
  <div class="mt-5">

    <div class="p-4 sm:rounded-md bg-red-100 sm:w-1/2 lg:w-1/3 w-full mx-auto mt-4" v-if="error">
      {{ error }}
    </div>

    <spinner class="border-blue-500 w-8 h-8 border-4 mx-auto mt-5" v-if="loading"></spinner>

    <div v-else-if="!location.sunsureDurationYears">
      This location has no Sunsure. 
    </div>

    <div v-else-if="!location.accountNumber" class="text-red-500">
      This location has no owner, it's not possible to view the Sunsure anniversaries.
    </div>

    <div v-else>
      <div v-if="result.totalCount > 0">
        <table>
          <thead>
            <tr>
              <th rowspan="2">Anniversary</th>
              <th colspan="2" class="bg-gray-200">Production</th>
              <th colspan="2">Under/over production</th>
              <th class="bg-gray-200" rowspan="2">Result</th>
            </tr>
            <tr>
              <th class="bg-gray-200">Guaranteed ({{ Math.round(location.sunsureGuaranteeRatio * 100) }}%)</th>
              <th class="bg-gray-200">Actual</th>
              <th>Yearly</th>
              <th>Cumulative</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="birthday in result.nodes" :key="birthday.birthday">
              <td>{{ formatDate(birthday.birthday) }}</td>
              <td>{{ formatKwh(birthday.productionDeltas.guaranteedKwh) }}</td>
              <td>{{ formatKwh(birthday.productionDeltas.actualKwh) ?? '(no production data)' }}</td>
              <td>
                <span 
                  :class="birthday.productionDeltas.actualKwh > birthday.productionDeltas.guaranteedKwh ?
                    'overproduction-badge' : 'underproduction-badge'
                  "
                >
                  <chevron-down-icon class="down-icon w-4 h-4" />
                  <chevron-up-icon class="up-icon w-4 h-4" />
                  {{ formatKwh(birthday.productionDeltas.actualKwh-birthday.productionDeltas.guaranteedKwh) }}  
                </span>
              </td>
              <td>
                <span 
                  :class="birthday.productionCumulative.actualKwh > birthday.productionCumulative.guaranteedKwh ?
                    'overproduction-badge' : 'underproduction-badge'
                  "
                >
                  <chevron-down-icon class="down-icon w-4 h-4" />
                  <chevron-up-icon class="up-icon w-4 h-4" />
                  {{ formatKwh(birthday.productionCumulative.actualKwh-birthday.productionCumulative.guaranteedKwh) }}  
                </span>
              </td>
              <td>
                <span
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                  :class="
                    birthday.sunsureResult === 'SUCCESS'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  "
                >
                  {{ birthday.sunsureResult }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-sm text-gray-400 mt-5">
          The numbers shown in the table are rounded to the nearest kWh. For B2C locations, a panel degradation of 0,5% per year is part of the guaranteed production calculation. 
        </div>
      </div>
      
      <div v-else>
        The first Sunsure anniversary will happen {{ formatDate(firstAnniversary) }}.
      </div>
    </div>
    

  </div>
</template>

<script>
import Spinner from '@/components/utils/Spinner'
import { useQuery, useResult } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { formatDate, formatKwh } from '@/utils'
import ChevronUpIcon from "heroicons/vue/outline/ChevronUp"
import ChevronDownIcon from "heroicons/vue/outline/ChevronDown"
import { DateTime } from "luxon"
import { computed } from 'vue-demi'

export default {
  components: {
    Spinner,
    ChevronUpIcon,
    ChevronDownIcon,
  },
  props: ['location'],
  setup(props) {
    const { result, loading, error } = useQuery(
      gql`
        query SunsureBirthdays($limit: Int!, $offset: Int!, $filter: SunsureBirthdayFilter) {
          getSunsureBirthdays(limit: $limit, offset: $offset, filter: $filter) {
            totalCount
            nodes {
              birthday
              sunsureResult
              productionDeltas {
                guaranteedKwh
                actualKwh
              }
              productionCumulative {
                guaranteedKwh
                actualKwh
              }
              location {
                id
              }
            }
          }
        }
      `,
      () => ({
        limit: 100,
        offset: 0,
        filter: {
          accountNumber: props.location.accountNumber,
        }
      })
    )

    const firstAnniversary = computed(() => props.location && DateTime.fromISO(props.location.onlineSince).plus({ year: 1, day: 1 }))

    return {
      formatDate, 
      formatKwh,
      loading,
      error,
      firstAnniversary,
      result: useResult(result),
    }
  }
}
</script>

<style lang="postcss" scoped>
th {
  @apply py-2;
}
td {
  @apply px-3 py-2 border-b
}
thead {
  @apply bg-gray-100 uppercase text-xs text-gray-500;
}
table {
  @apply table-auto w-full border border-gray-200;
}
.underproduction-badge, .overproduction-badge {
  @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full
}
.overproduction-badge {
  @apply bg-green-50 text-green-800
}
.underproduction-badge {
  @apply bg-red-50 text-red-800
}
.overproduction-badge > .down-icon {
  display: none;
}
.underproduction-badge > .up-icon {
  display: none;
}
</style>
