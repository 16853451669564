<template>
  <dialog-layout
    :title="`SIMPL communication status ${meteringSystem.hardwareIdentifier?.logicalDeviceName}`"
  >
    <template v-slot:content>
      <div class="text-xs text-gray-400 mb-3">
        Information about the latest verification (Sungate), production(E350/Landis&amp;Gyr) or
        connection test is shown.
      </div>

      <div
        :class="
          `mb-1 p-3 rounded ${statusClass(meteringSystem.details.communicationStatus.connection)}`
        "
      >
        <div class="text-md text-gray-500">
          Connection test
        </div>
        <div v-if="meteringSystem.details.communicationStatus.connection">
          Result:
          {{
            meteringSystem.details.communicationStatus.connection.success ? 'Success' : 'Failure'
          }}
          <div v-if="meteringSystem.details.communicationStatus.connection.result">
            {{ meteringSystem.details.communicationStatus.connection.result }}
          </div>
          <div>
            Date:
            {{ formatDateFull(meteringSystem.details.communicationStatus.connection.performedOn) }}
          </div>
        </div>
        <div v-else>
          Connection test was never performed.
        </div>
      </div>

      <div
        v-if="isSungateMeter"
        :class="
          `p-3 rounded ${statusClass(meteringSystem.details.communicationStatus.verification)}`
        "
      >
        <div class="text-md text-gray-500">
          Verification test
        </div>
        <div v-if="meteringSystem.details.communicationStatus.verification">
          Result:
          {{
            meteringSystem.details.communicationStatus.verification.success ? 'Success' : 'Failure'
          }}
          <div v-if="meteringSystem.details.communicationStatus.verification.result">
            {{ meteringSystem.details.communicationStatus.verification.result }}
          </div>
          <div>
            Date:
            {{
              formatDateFull(meteringSystem.details.communicationStatus.verification.performedOn)
            }}
          </div>
        </div>
        <div v-else>
          Verification test was never performed.
        </div>
      </div>

      <div
        v-if="!isSungateMeter"
        :class="`p-3 rounded ${statusClass(meteringSystem.details.communicationStatus.production)}`"
      >
        <div class="text-md text-gray-500">
          Production test
        </div>
        <div v-if="meteringSystem.details.communicationStatus.production">
          Result:
          {{
            meteringSystem.details.communicationStatus.production.success ? 'Success' : 'Failure'
          }}
          <div v-if="meteringSystem.details.communicationStatus.production.result">
            {{ meteringSystem.details.communicationStatus.production.result }}
          </div>
          <div>
            Date:
            {{ formatDateFull(meteringSystem.details.communicationStatus.production.performedOn) }}
          </div>
        </div>
        <div v-else>
          Production test was never performed.
        </div>
      </div>

      <div class="mt-4 flex gap-4 items-center">
        <button class="medium-blue-button" @click="runConnectionTest" :disabled="loadingExecute">
          Run connection test
        </button>
        <button
          class="medium-blue-button"
          @click="runVerification"
          :disabled="loadingExecute"
          v-if="isSungateMeter"
        >
          Run verification test
        </button>
        <button
          class="medium-blue-button"
          @click="runProduction"
          :disabled="loadingExecute"
          v-if="!isSungateMeter"
        >
          Run production test
        </button>
        <Spinner class="h-4 w-4 border-blue-500 border-2" v-if="loadingExecute" />
      </div>

      <div
        class="flex gap-4 items-center mt-4"
        v-if="currentActionId && currentAction?.pending !== false"
      >
        <Spinner class="h-6 w-6 border-blue-500 border-4" />
        <div>Reaching out to the device, this might take a while.</div>
      </div>

      <div
        v-else-if="currentAction?.pending === false && !loadingExecute"
        class="mt-4 bg-yellow-100 p-1 rounded-md"
      >
        Remote action was executed on the device! (result is updated above)
      </div>
    </template>
    <template v-slot:buttons>
      <button class="medium-blue-button" @click="$emit('close')">Close</button>
    </template>
  </dialog-layout>
</template>

<script>
import { DialogLayout } from '@/components/utils'
import { useApolloClient, useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import Spinner from './utils/Spinner.vue'
import { ref, computed } from 'vue'
import { formatDateFull } from '@/utils'

export default {
  props: ['meteringSystem'],
  components: {
    DialogLayout,
    Spinner,
  },
  setup(props) {
    function statusClass(communicationStatusDetails) {
      const list = []
      if (!communicationStatusDetails) list.push('unknown-status')
      else if (communicationStatusDetails.success) list.push('success-status')
      else list.push('failed-status')

      if (
        currentAction.value?.performedOn &&
        currentAction.value?.performedOn === communicationStatusDetails?.performedOn
      ) {
        list.push('ring ring-yellow-300 ring-offset-1')
      }
      return list.join(' ')
    }

    const { mutate: execute, loading: loadingExecute, onDone: onDoneMutation } = useMutation(
      gql`
        mutation ExecuteMeteringSystemRemoteAction(
          $id: Int!
          $action: MeteringSystemRemoteActionType!
        ) {
          executeMeteringSystemRemoteAction(id: $id, brand: Simpl, action: $action) {
            started
            meteringSystemRemoteActionId
            error
          }
        }
      `
    )

    const currentActionType = ref(null)
    const currentActionId = ref(null)
    const currentAction = ref(null)
    const fetchCurrentActionEnabled = computed(
      () =>
        !!currentActionId.value &&
        (currentActionId.value !== currentAction.value?.id || currentAction.value?.pending)
    )

    const { onResult: onDoneCurrentAction } = useQuery(
      gql`
        query GetMeteringSystemRemoteAction($id: String!, $type: MeteringSystemRemoteActionType!) {
          getMeteringSystemRemoteAction(id: $id, type: $type) {
            id
            type
            pending
            performedOn
            result
            success
          }
        }
      `,
      () => ({
        id: currentActionId.value,
        type: currentActionType.value,
      }),
      {
        pollInterval: 5000,
        enabled: fetchCurrentActionEnabled,
      }
    )

    onDoneMutation(({ data }) => {
      const result = data.executeMeteringSystemRemoteAction
      if (result && result.started) {
        // start polling for a result
        currentActionId.value = result.meteringSystemRemoteActionId
      }
    })

    const client = useApolloClient().client
    onDoneCurrentAction(({ data }) => {
      if (data?.getMeteringSystemRemoteAction) {
        currentAction.value = data?.getMeteringSystemRemoteAction
      }

      if (data?.getMeteringSystemRemoteAction?.pending === false) {
        if (data.getMeteringSystemRemoteAction.type === 'ConnectionTest') {
          client.writeFragment({
            id: `MeteringSystem:{"id":${props.meteringSystem.id},"brand":"${props.meteringSystem.brand}"}`,
            fragment: gql`
              fragment CommStatusConnection on MeteringSystem {
                details {
                  communicationStatus {
                    connection {
                      performedOn
                      result
                      success
                    }
                  }
                }
              }
            `,
            data: {
              details: {
                // We need to be explicit here about the typename 'MeteringSystemDetails' to make sure the response of the migration is correctly store in the apollo store.
                __typename: 'MeteringSystemDetails',
                communicationStatus: {
                  connection: data.getMeteringSystemRemoteAction,
                },
              },
            },
          })
        } else if (data.getMeteringSystemRemoteAction.type === 'VerificationTest') {
          client.writeFragment({
            id: `MeteringSystem:{"id":${props.meteringSystem.id},"brand":"${props.meteringSystem.brand}"}`,
            fragment: gql`
              fragment CommStatusVerification on MeteringSystem {
                details {
                  communicationStatus {
                    verification {
                      performedOn
                      result
                      success
                    }
                  }
                }
              }
            `,
            data: {
              details: {
                __typename: 'MeteringSystemDetails',
                communicationStatus: {
                  verification: data.getMeteringSystemRemoteAction,
                },
              },
            },
          })
        } else if (data.getMeteringSystemRemoteAction.type === 'ProductionTest') {
          client.writeFragment({
            id: `MeteringSystem:{"id":${props.meteringSystem.id},"brand":"${props.meteringSystem.brand}"}`,
            fragment: gql`
              fragment CommStatusProduction on MeteringSystem {
                details {
                  communicationStatus {
                    production {
                      performedOn
                      result
                      success
                    }
                  }
                }
              }
            `,
            data: {
              details: {
                __typename: 'MeteringSystemDetails',
                communicationStatus: {
                  production: data.getMeteringSystemRemoteAction,
                },
              },
            },
          })
        }
      }
    })

    function clear() {
      currentActionId.value = null
      currentAction.value = null
      currentActionType.value = null
    }

    function runVerification() {
      clear()
      currentActionType.value = 'VerificationTest'
      return execute({
        id: props.meteringSystem.id,
        action: 'VerificationTest',
      })
    }
    function runConnectionTest() {
      clear()
      currentActionType.value = 'ConnectionTest'
      return execute({
        id: props.meteringSystem.id,
        action: 'ConnectionTest',
      })
    }

    function runProduction() {
      clear()
      currentActionType.value = 'ProductionTest'
      return execute({
        id: props.meteringSystem.id,
        action: 'ProductionTest',
      })
    }

    return {
      statusClass,
      runVerification,
      runConnectionTest,
      runProduction,
      loadingExecute,
      currentAction,
      currentActionId,
      formatDateFull,
      isSungateMeter: props.meteringSystem.details?.simplMeterType === 'sungate',
    }
  },
}
</script>

<style lang="postcss" scoped>
.success-status {
  @apply bg-green-100;
}
.failed-status {
  @apply bg-red-100;
}
.unknown-status {
  @apply bg-yellow-100;
}
.medium-blue-button {
  @apply text-sm rounded-md bg-blue-200 py-2 px-3 hover:bg-blue-300 disabled:bg-blue-100;
}
</style>
