<template>
  <div class="grid grid-cols-4 gap-6 mt-5">
    <div class="text-gray-400 py-2">
      Account number
    </div>
    <div class="col-span-3 flex items-center gap-2">
      <div>
        {{ location.accountNumber || '(no owner)' }}
        <span
          v-if="location.ownershipHistory && location.ownershipHistory[0]"
          class="text-gray-400 text-sm"
        >
          starting at {{ formatDate(location.ownershipHistory[0].ownershipStartedAt) }}
        </span>
      </div>
      <button class="small-green-button" @click="$emit('open-add-owner-dialog')">
        Change owner
      </button>
    </div>
    <div class="text-gray-400">
      System online
    </div>
    <div class="col-span-3">
      {{ formatDate(location.onlineSince) || '(not set)' }}
    </div>
    <div class="text-gray-400">
      Address
    </div>
    <div class="col-span-3">
      <div class="text-sm">{{ location.address }}</div>
      <div class="text-sm">
        {{ location.postalCode }} {{ location.city }} ({{ location.countryCode }})
      </div>
    </div>
    <div class="text-gray-400">
      Meters and inverters
    </div>
    <div class="col-span-3">
      <MeteringSystemsTable
        :metering-systems="location.meteringSystems"
        :moveMeteringSystemDialog="(ms) => $emit('open-move-metering-system-dialog', ms)"
        :markMeteringSystemAsInactive="(ms) => $emit('open-mark-metering-system-as-inactive', ms)"
        :markMeteringSystemAsActive="(ms) => $emit('open-mark-metering-system-as-active', ms)"
        :removeMeteringSystem="(ms) => $emit('open-remove-metering-system', ms)"
        :compact="compact"
      />

      <div class="mt-5">
        <button class="small-blue-button mr-1" @click="$emit('open-metering-system-audit-log')">
          Audit log ({{ location.auditLog.length }})
        </button>

        <button class="small-green-button" @click="$emit('open-add-metering-system-dialog')">
          Add meter or inverter
        </button>
      </div>
    </div>
    <div class="text-gray-400 py-2">
      Sunsure
    </div>
    <div class="col-span-3">
      <span v-if="!location.sunsureDurationYears">No</span>
      <span v-else>
        Yes, {{ location.sunsureDurationYears }} years
        {{ Math.round(location.sunsureGuaranteeRatio * 100) }}%
      </span>
    </div>
    <div class="text-gray-400">
      Yearly production estimate
    </div>
    <div class="col-span-3 flex items-center gap-2">
      <div>
        {{ formatWh(location.annualProductionEstimateWh) || '(not set)' }}
      </div>
      <button
        class="small-green-button"
        @click="$emit('open-update-annual-production-estimate-dialog')"
      >
        Edit
      </button>
    </div>
    <div class="text-gray-400">
      Market type
    </div>
    <div class="col-span-3">
      <span v-if="!location.locationGroup">B2C</span>
      <span v-else>
        B2B:
        <strong>{{ location.locationGroup.friendlyName }}</strong>
      </span>
    </div>
    <template v-if="location.locationGroup">
      <div class="text-gray-400">
        Complex number
      </div>
      <div class="col-span-3 flex items-center gap-2">
        {{ location.customerReference ?? '(not set)' }}
        <button class="small-green-button" @click="$emit('open-update-customer-reference-dialog')">
          Edit
        </button>
      </div>
    </template>
    <div class="text-gray-400" v-if="showOwnershipHistory">
      Ownership history
    </div>
    <div class="col-span-3" v-if="showOwnershipHistory">
      <table>
        <thead>
          <tr>
            <th class="py-2">Account number</th>
            <th>Ownership started at</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in location.ownershipHistory" :key="index" class="border-t">
            <td class="px-3 py-2">{{ o.accountNumber }}</td>
            <td class="px-3 py-2">{{ formatDate(o.ownershipStartedAt) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import MeteringSystemsTable from '@/components/MeteringSystemsTable'
import { formatDate, formatKwh, formatWh } from '@/utils'
import { computed } from 'vue'

export default {
  components: {
    MeteringSystemsTable,
  },
  props: ['location', 'compact'],
  setup() {
    const showOwnershipHistory = computed(
      () => location.value && location.value.ownershipHistory.length > 1
    )

    return {
      formatKwh,
      formatWh,
      formatDate,
      showOwnershipHistory,
    }
  },
}
</script>

<style lang="postcss" scoped>
thead {
  @apply bg-gray-100 uppercase text-xs text-gray-500;
}
table {
  @apply table-auto w-full border border-gray-200;
}
.small-green-button {
  @apply text-xs rounded-md bg-green-200 text-green-800 py-2 px-3 hover:bg-green-300;
}
.small-blue-button {
  @apply text-xs rounded-md bg-blue-200 text-blue-800 py-2 px-3 hover:bg-blue-300;
}
</style>
