<template>
  <div class="container mx-auto px-4">
    <div class="mt-4 overflow-hidden rounded-lg w-full m-auto">
      <h2 class="text-xl m-auto text-center p-2">
        All Logins for
        <span class="bg-blue-100 text-blue-900 border border-blue-900 px-3 rounded-lg">
          {{ group.description }}
        </span>
      </h2>
      <div>
        <div class="mx-3 overflow-hidden sm:rounded-lg h-auto">
          <div class="w-5/6 m-auto">
            <table
              class="divide-y divide-gray-200 m-auto border-2 border-gray-100 rounded-lg w-full"
            >
              <thead class="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-50"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-50"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-50"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-50"
                  ></th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="user in users" :key="user">
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ user.name }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ user.email }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ user.status }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <sg-button
                      :color="'red'"
                      :text="'Delete Login'"
                      @click="deleteUser(user)"
                      v-if="!user.email.endsWith('@sungevity-intl.com')"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="rounded-xl p-4 mt-8 bg-gray-200" :class="formError ? 'border-red-500' : ''">
              <div class="flex items-center">
                <div class="flex-auto">
                  <input
                    v-model="newUser.firstName"
                    type="text"
                    name="first-name"
                    class="focus:ring-indigo-500 focus:border-indigo-500 block rounded-md sm:text-md border-gray-300"
                    placeholder="First Name"
                    :class="{
                      'ring-red-500 border-red-500': vv.newUser.firstName.$invalid,
                    }"
                  />
                </div>

                <div class="flex-auto">
                  <input
                    v-model="newUser.lastName"
                    type="text"
                    name="last-name"
                    class="focus:ring-indigo-500 focus:border-indigo-500 block rounded-md sm:text-md border-gray-300"
                    placeholder="Last Name"
                    :class="{
                      'ring-red-500 border-red-500': vv.newUser.lastName.$invalid,
                    }"
                  />
                </div>

                <div class="flex-auto">
                  <input
                    v-model="newUser.email"
                    type="text"
                    name="email"
                    class="focus:ring-indigo-500 focus:border-indigo-500 block rounded-md sm:text-md border-gray-300"
                    placeholder="Email"
                    :class="{
                      'ring-red-500 border-red-500': vv.newUser.email.$invalid,
                    }"
                  />
                </div>

                <div class="flex-auto">
                  <sg-button :color="'green'" :text="'Create Login'" @click="createNewUser" />
                </div>
              </div>

              <div v-if="formError" class="mt-2">
                <span class="p-1 text-sm mt-1 bg-red-100 text-red-900">{{ formError }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="false">
    <router-link :to="{ name: 'b2b-overview' }">Back to overview</router-link>

    <div class="container">
      <div class="child">
        <p>
          An email will be sent to the address containing a temporary password. When logging in the
          first time, the user is asked to create a new password.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue'
import { cognito, userPoolId } from '@/aws'
import { required, email } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup() {
    const users = ref([])
    const formError = ref('')

    const route = useRoute()
    const groupName = computed(() => route.params.groupName)
    const store = useStore()
    const group = computed(() => {
      const { cognitoGroups } = store.state.b2b
      return (
        Array.isArray(cognitoGroups) && cognitoGroups.find(({ name }) => name === groupName.value)
      )
    })

    const newUser = ref({
      email: '',
      firstName: '',
      lastName: '',
    })
    const validation = useVuelidate(
      {
        newUser: {
          email: { required, email },
          firstName: { required },
          lastName: { required },
        },
      },
      {
        newUser,
      }
    )
    const resetForm = () => {
      formError.value = ''
      newUser.value = {
        firstName: '',
        lastName: '',
        email: '',
      }
      validation.value.$reset()
    }

    const fetchUsersInGroup = async () => {
      const { Users: result } = await cognito
        .listUsersInGroup({
          GroupName: groupName.value,
          UserPoolId: userPoolId,
          Limit: 60,
        })
        .promise()

      if (result.length > 59) alert('Somebody alert IT: we need pagination')

      users.value = result.map(({ Username, Attributes, UserStatus }) => {
        const get = (n) => Attributes.filter(({ Name }) => Name === n).map((a) => a.Value)[0]
        return {
          id: Username,
          email: get('email'),
          name: `${get('given_name') || ''} ${get('family_name') || ''}`,
          status: UserStatus,
        }
      })
    }

    const createNewUser = async () => {
      validation.value.$touch()
      if (validation.value.$invalid) {
        return (formError.value = 'Please fill in all the fields')
      }

      const email = newUser.value.email.trim()
      if (email.endsWith('@sungevity-intl.com')) {
        return (formError.value = "Sungevity emails can't be B2B account logins")
      }
      const { firstName, lastName } = newUser.value
      resetForm()

      try {
        const { User: user } = await cognito
          .adminCreateUser({
            UserPoolId: userPoolId,
            Username: email,
            ForceAliasCreation: true,
            UserAttributes: [
              {
                Name: 'locale',
                Value: 'nl_NL',
              },
              {
                Name: 'given_name',
                Value: firstName.trim(),
              },
              {
                Name: 'family_name',
                Value: lastName.trim(),
              },
              {
                Name: 'email_verified',
                Value: 'True',
              },
              {
                Name: 'email',
                Value: email,
              },
            ],
          })
          .promise()

        await cognito
          .adminAddUserToGroup({
            UserPoolId: userPoolId,
            Username: user.Username,
            GroupName: groupName.value,
          })
          .promise()

        await fetchUsersInGroup()
      } catch (error) {
        formError.value = `Error: ${error.message}`
      }
    }

    store.dispatch('fetchCognitoGroupsIfNotSet')
    fetchUsersInGroup()

    const dialog = inject('openDialog')
    const deleteUser = async (user) => {
      const confirmed = await dialog({
        header: 'Delete user',
        body: `Do you want to delete ${user.email}?`,
        action: 'Delete',
      })
      if (confirmed) {
        await cognito
          .adminDeleteUser({
            UserPoolId: userPoolId,
            Username: user.id,
          })
          .promise()

        await fetchUsersInGroup()
      }
    }

    return {
      vv: validation,
      group,
      users,
      deleteUser,
      createNewUser,
      formError,
      newUser,
    }
  },
}
</script>
