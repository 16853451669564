<template>
  <dialog-layout title="Mark metering system as active" width="2xl">
    <template v-slot:content>
      <div class="mb-4 flex justify-between">
        <p>Are you sure you want to (re)activate this meter?</p>
      </div>
    </template>

    <template v-slot:buttons>
      <button
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        @click="onSubmit"
      >
        <Spinner
          class="w-6 h-6 border-4 border-gray-500 rounded-full loader"
          v-if="loading"
        ></Spinner>
        Confirm
      </button>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        @click="$emit('close')"
      >
        Cancel
      </button>
    </template>
  </dialog-layout>
</template>

<script>
import { computed } from 'vue'
import gql from 'graphql-tag'
import { useMutation } from '@vue/apollo-composable'
import DialogLayout from '@/components/utils/DialogLayout'
import Spinner from '@/components/utils/Spinner'

export default {
  components: {
    DialogLayout,
    Spinner,
  },
  emits: ['success', 'close'],
  props: {
    meteringSystem: Object,
    locationId: Number,
  },
  setup(props, { emit }) {
    const { mutate, loading, error } = useMutation(gql`
      mutation ActivateMeteringSystem($meteringSystemId: Int!, $brand: Brand!) {
        activateMeteringSystem(meteringSystemId: $meteringSystemId, brand: $brand) {
          id
          brand
          status
          inactive {
            since
            setBy
            type
          }
        }
      }
    `)

    async function onSubmit() {
      await mutate(
        {
          meteringSystemId: props.meteringSystem.id,
          brand: props.meteringSystem.brand,
        },
        {
          update: (cache, { data: { activateMeteringSystem: meteringSystem } }) => {
            cache.writeFragment({
              id: `MeteringSystem:{"id":${meteringSystem.id},"brand":"${meteringSystem.brand}"}`,
              fragment: gql`
                fragment ActivateInactiveMeteringSystem on MeteringSystem {
                  status
                  inactive {
                    since
                    setBy
                    type
                  }
                }
              `,
              data: meteringSystem,
            })
          },
        }
      )
      emit('close')
    }

    return {
      loading,
      error: computed(() => error.value && error.value.message),
      onSubmit,
    }
  },
}
</script>

<style lang="postcss" scoped>
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>
