<template>
  <dialog-layout title="Add New Location Owner">
    <template v-slot:content>
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
          <label for="state" class="block text-sm font-medium text-gray-700">Acccount Number</label>
          <input
            title="Account Number"
            type="number"
            v-model.number="accountNumber"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div class="col-span-6 bg-red-100 rounded-md text-red-900 p-2" v-if="error">
          {{ error }}
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        @click="onSubmit"
      >
        <Spinner
          class="w-6 h-6 border-4 border-gray-500 rounded-full loader"
          v-if="loading"
        ></Spinner>
        Confirm
      </button>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        @click="$emit('close')"
      >
        Cancel
      </button>
    </template>
  </dialog-layout>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import DialogLayout from '@/components/utils/DialogLayout'
import Spinner from '@/components/utils/Spinner'

export default {
  components: {
    DialogLayout,
    Spinner,
  },
  emits: ['success', 'close'],
  props: {
    locationId: Number,
  },
  setup(props, { emit }) {
    const isSubmitting = ref(false)
    const validationError = ref('')
    const accountNumber = ref('')

    watch(accountNumber, () => (error.value = validationError.value = ''))

    const { mutate, loading, error } = useMutation(gql`
      mutation AddLocationOwner($locationId: Int!, $accountNumber: Int!) {
        addLocationOwnership(locationId: $locationId, accountNumber: $accountNumber) {
          id
          ownershipHistory {
            ownershipStartedAt
            accountNumber
          }
          accountNumber
        }
      }
    `)

    const onSubmit = async () => {
      if (!accountNumber.value) {
        validationError.value = 'Account Number is required'
        return
      }

      const variables = {
        accountNumber: accountNumber.value,
        locationId: props.locationId,
      }
      if (
        !confirm(
          `This will make the account ${accountNumber.value} the owner of location with ID ${props.locationId}. Continue?`
        )
      )
        return

      const result = await mutate(variables)
      if (result.error) console.log('error adding location ownership', result.error)
      if (result.data) {
        emit('success')
        emit('close')
      }
    }

    return {
      accountNumber,
      onSubmit,
      isSubmitting,
      loading,
      error: computed(() => (error.value && error.value.message) || validationError.value),
    }
  },
}
</script>
