import auth from './auth'
import b2b from './b2b'
import { createStore } from 'vuex'

export default createStore({
  modules: {
    auth,
    b2b,
  },
})
