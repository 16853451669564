<template>
  <sidebar-layout>
    <template v-slot:sidebar>
      <span class="text-md font-semibold">Filters</span>

      <div class="filter">
        <label for="email">Email</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            name="e-mail"
            class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-20 rounded-none rounded-l-md sm:text-sm border-gray-300"
            placeholder="e.g. foo@bar.com"
            v-model="emailSearch"
            @keyup.enter="searchByEmail"
          />
        </div>
      </div>

      <div class="mt-4">
        <sg-button @click="searchByEmail" color="blue" text="Search"></sg-button>
      </div>
    </template>
    <template v-slot:content>
      <div
        class="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center justify-between sm:px-6 lg:px-8"
      >
        <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
          MySungevity users
        </h1>
        <div class="w-6 h-6 border-4 border-gray-500 rounded-full loader" v-if="loading"></div>
      </div>

      <span class="ml-60 text-semibold text-red-900" v-if="error">{{ error }}</span>

      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-100">
          <tr>
            <th>Customer</th>
            <th
              title="Activated? Then the customer can click 'Forgot password' in MySungevity. Not Activated? Then the customer should go back to the activation email to click on the link. You can also send a new activation email from Sugar with the button 'MySG Send Login"
            >
              Status ?
            </th>
            <th>Account</th>
            <th></th>
            <th>Date Created</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(user, index) in userList" :key="index">
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="flex items-center">
                <div>
                  <div class="text-sm font-medium text-gray-900">
                    {{ user.name }}
                  </div>
                  <div class="text-sm text-gray-500">
                    {{ user.email }}
                  </div>
                </div>
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <span
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                :class="{
                  'bg-green-100 text-green-800': user.userStatus === 'Activated',
                  'bg-yellow-100 text-yellow-800': user.userStatus === 'Not activated',
                }"
              >
                {{ user.userStatus }}
              </span>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ user.accountNumber }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <sg-button
                @click="redirectToLocation(user.accountNumber)"
                color="blue"
                text="Go to location"
              ></sg-button>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ user.userCreateDate }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <sg-button
                @click.stop="deleteUser(user, index)"
                color="red"
                icon-right="trash"
                text="Delete User"
              ></sg-button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="m-3" v-if="users.length > 49" @click="get50">
        <span
          class="h-10 inline-flex items-center px-3 rounded-md border bg-blue-100 text-blue-900 border-blue-900 text-sm cursor-pointer hover:bg-blue-500 hover:text-blue-50"
        >
          Next 50 >>
        </span>
      </div>
    </template>
  </sidebar-layout>
</template>

<script>
import { cognito, ddb, userPoolId, toDDBFormat } from '@/aws'
import { ref, computed, inject } from 'vue'
import { useRouter } from 'vue-router'
import Auth from '@aws-amplify/auth'
import { DateTime } from 'luxon'
import SidebarLayout from '@/components/utils/SidebarLayout'

const displayAccountStatus = (accountStatus) => {
  const message = {
    CONFIRMED: 'Activated',
    FORCE_CHANGE_PASSWORD: 'Not activated',
  }[accountStatus]
  return message || accountStatus
}

const toTableValues = (user) => ({
  username: user.Username,
  name: `${findInArray(user.Attributes, 'given_name')} ${findInArray(
    user.Attributes,
    'family_name'
  )}`,
  email: findInArray(user.Attributes, 'email'),
  accountNumber: findInArray(user.Attributes, 'custom:accountNumber'),
  // user create date is a date object
  userCreateDate: DateTime.fromJSDate(user.UserCreateDate).toFormat('LLL dd yyyy'),
  userStatus: displayAccountStatus(user.UserStatus),
})

const findInArray = (hayStack, needle) => {
  const property = hayStack.find((att) => att.Name === needle)
  return property ? property.Value : ''
}

export default {
  components: {
    SidebarLayout,
  },
  setup() {
    const router = useRouter()

    const loading = ref(false)
    const dangermodal = ref(null)
    const showModal = ref(false)
    const emailSearch = ref('')
    const error = ref('')
    const paginationToken = ref('')
    const userToDeleteIndex = ref('')
    const adminEmail = ref('')
    const users = ref([])
    const selectedUser = ref({})

    const userList = computed(() => users.value.map(toTableValues))

    const redirectToLocation = (accountNumber) =>
      router.push({
        name: 'locations-list',
        query: { accountNumber },
      })

    const getList = async (params, errorMsg) => {
      loading.value = true
      error.value = ''
      const options = {
        UserPoolId: userPoolId,
        Limit: 50,
        ...params,
      }
      const userList = await cognito.listUsers(options).promise()
      if (!userList || !userList.Users || !userList.Users.length) {
        error.value = errorMsg
        return
      }
      loading.value = false
      users.value = userList.Users
      paginationToken.value = userList.PaginationToken || ''
    }

    const get50 = () =>
      getList(
        {
          Filter: `email^="${emailSearch.value}"`,
          PaginationToken: paginationToken.value || undefined,
        },
        'Could not Find Users'
      )
    const searchByEmail = () =>
      getList(
        {
          Filter: `email^="${emailSearch.value}"`,
        },
        'Could not find Users with this email'
      )

    const openDialog = inject('openDialog')
    const deleteUser = async ({ name, email, username, accountNumber }, idx) => {
      const confirmed = await openDialog({
        header: 'Are you sure you want to delete this user?',
        body: `
          Name: ${name} <br />
          Email: ${email} <br />
          AccountNumber: ${accountNumber}
        `,
        action: 'Delete User',
      })
      if (!confirmed) return
      const params = {
        UserPoolId: userPoolId,
        Username: username,
      }
      const {
        idToken: { payload },
      } = await Auth.currentSession()
      const logData = {
        customer_email: email,
        created_at: new Date().toISOString(),
        admin_user: payload.email,
      }
      const ddbParams = {
        Item: toDDBFormat(logData),
        TableName: 'admin_portal_deletion_logs',
      }
      try {
        const userDeleted = await cognito.adminDeleteUser(params).promise()
        if (userDeleted) {
          users.value.splice(idx, 1)
          await ddb.putItem(ddbParams).promise()
        }
      } catch (e) {
        console.log(e)
      }
    }

    return {
      loading,
      dangermodal,
      emailSearch,
      error,
      paginationToken,
      userToDeleteIndex,
      adminEmail,
      users,
      selectedUser,
      userList,
      redirectToLocation,
      get50,
      searchByEmail,
      deleteUser,
      showModal,
    }
  },
}
</script>

<style scoped lang="postcss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}

.filter {
  @apply mt-5;
}

.filter select,
.filter input {
  @apply block w-full sm:text-sm border-gray-300 rounded-md;
}

th {
  @apply px-6 py-3 text-xs font-medium uppercase text-left;
}
</style>
