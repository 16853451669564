<template>
  <dialog-layout :title="`Move inverter/meter to a different location`">
    <template v-slot:content>
      <p class="mb-4">
        This action moves the meter or inverter including all production data to a different
        location.
      </p>

      <div v-if="!selectedLocation">
        <label for="locationSearchInput" class="block text-sm font-medium text-gray-700">
          New location address or account number
        </label>
        <div class="mt-1 relative">
          <input
            type="text"
            v-model.trim="locationSearchInput"
            name="locationSearchInput"
            id="locationSearchInput"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md pr-10"
            placeholder="e.g. Kerkstraat 18"
          />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <div
              class="w-5 h-5 border-4 border-gray-400 rounded-full loader"
              v-show="locationsLoading"
            ></div>
          </div>
        </div>
      </div>

      <ul class="divide-y divide-gray-200" v-if="!selectedLocation">
        <li
          class="py-4 px-2 hover:bg-gray-200 cursor-pointer"
          v-for="location in locations"
          :key="location.id"
          @click="selectedLocation = location"
        >
          <div class="flex items-center space-x-4">
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900 truncate">
                {{ location.address }}
              </p>
              <p class="text-sm text-gray-500 truncate">
                {{ location.postalCode }} {{ location.city }} ({{ location.countryCode }})
              </p>
            </div>
            <div
              class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 rounded-full text-gray-700"
            >
              {{ location.accountNumber || 'no owner' }}
            </div>
          </div>
        </li>
      </ul>

      <div v-if="selectedLocation">
        <div class="leading-3 mb-3">Move {{ meteringSystem.brand }} meter/inverter to:</div>
        <div class="flex items-center space-x-4 bg-blue-100 px-2 py-4 rounded-md">
          <div class="flex-1 min-w-0">
            <p class="text-sm font-medium text-gray-900 truncate">
              {{ selectedLocation.address }}
            </p>
            <p class="text-sm text-gray-500 truncate">
              {{ selectedLocation.postalCode }} {{ selectedLocation.city }} ({{
                selectedLocation.countryCode
              }})
            </p>
          </div>
          <div
            class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 rounded-full text-gray-700"
          >
            {{ selectedLocation.accountNumber || 'no owner' }}
          </div>
        </div>
      </div>
      <div class="mt-5" v-if="error">
        <p class="text-red-600">{{ error }}</p>
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        @click="onSubmit"
      >
        <Spinner
          class="w-6 h-6 border-4 border-gray-500 rounded-full loader"
          v-if="loading"
        ></Spinner>
        Confirm
      </button>
      <button
        :disabled="loading"
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        @click="$emit('close')"
      >
        Cancel
      </button>
    </template>
  </dialog-layout>
</template>

<script>
import { computed, ref, watch } from 'vue'
import gql from 'graphql-tag'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import DialogLayout from '@/components/utils/DialogLayout'
import Spinner from '@/components/utils/Spinner'

export default {
  components: {
    DialogLayout,
    Spinner,
  },
  emits: ['success', 'close'],
  props: {
    meteringSystem: Object,
    locationId: Number,
  },
  setup(props, { emit }) {
    const locationSearchInput = ref('')
    const selectedLocation = ref(false)

    const searchInput = ref(false)
    const locationSelector = useQuery(
      gql`
        query GetLocations($addressLike: String, $accountNumber: Int) {
          getLocations(
            filter: { addressLike: $addressLike, accountNumber: $accountNumber }
            limit: 5
            offset: 0
          ) {
            nodes {
              id
              address
              postalCode
              city
              accountNumber
              countryCode
            }
          }
        }
      `,
      searchInput,
      () => ({
        enabled: !!searchInput.value,
        debounce: 500,
      })
    )
    const locations = useResult(locationSelector.result)

    watch(locationSearchInput, (val) => {
      searchInput.value = {
        accountNumber: Number(val) || null,
        addressLike: !Number(val) ? val : null,
      }
    })

    const { mutate, loading, error } = useMutation(gql`
      mutation MoveMeteringSystem($id: Int!, $locationId: Int!, $brand: Brand!) {
        moveMeteringSystem(id: $id, locationId: $locationId, brand: $brand) {
          id
          address
          postalCode
          city
          accountNumber
          countryCode
          lastReadingAt
          locationGroupId
          onlineSince
          annualProductionEstimateWh
          sunsureGuaranteeRatio
          sunsureDurationYears
          customerReference
          absolutePerformance
          meteringSystems {
            id
            alert {
              category
              message
            }
            maxCumulativeEnergyWh
            brand
            firstReadingAt
            installedBySungevity
            lastReadingAt
            hardwareIdentifier {
              serialNumber
              logicalDeviceName
            }
            serviceAssignedIdentifier {
              plantId
              siteId
              countryCode
            }
            details {
              simplMeterType
              communicationStatus {
                verification {
                  performedOn
                  result
                  success
                }
                connection {
                  performedOn
                  result
                  success
                }
                production {
                  performedOn
                  result
                  success
                }
              }
            }
            status
            inactive {
              since
              setBy
              type
            }
          }
          auditLog {
            completedByEmail
            completedAt
            ... on MeteringSystemAuditLog {
              toLocation {
                id
                address
              }
              fromLocation {
                id
                address
              }
              meteringSystem {
                id
                brand
                hardwareIdentifier {
                  serialNumber
                  logicalDeviceName
                }
                serviceAssignedIdentifier {
                  plantId
                  siteId
                  countryCode
                }
              }
            }
          }
          locationGroup {
            friendlyName
          }
          ownershipHistory {
            accountNumber
            ownershipStartedAt
          }
        }
      }
    `)

    async function onSubmit() {
      if (!selectedLocation.value) return alert('Please click on a location to select it')
      const locationId = selectedLocation.value.id
      await mutate({
        id: props.meteringSystem.id,
        locationId,
        brand: props.meteringSystem.brand,
      })
      emit('success', locationId)
    }

    return {
      locations: computed(
        () =>
          (locations.value && locations.value.nodes.filter((l) => l.id !== props.locationId)) || []
      ),
      locationsLoading: locationSelector.loading,
      loading,
      error: computed(() => error.value && error.value.message),
      locationSearchInput,
      selectedLocation,
      onSubmit,
    }
  },
}
</script>

<style lang="postcss" scoped>
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>
