<template>
  <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
  <button 
    type="button" 
    class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
    :class="value ? 'bg-green-600' : 'bg-gray-200'"
    @click="toggle"
  >
    <span class="sr-only">Use setting</span>
    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
    <span 
      class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
      :class="value ? 'translate-x-5' : 'translate-x-0'"
    ></span>
  </button>
</template>

<script>
export default {
  props: ['value'],
  emits: ['input'],
  methods: {
    toggle() {
      this.$emit('input', !this.value)
    }
  }
}
</script>
