<template>
  <div class="container mx-auto px-4">
    <div>
      Showing images for account number
      <strong>{{ accountNumber }}</strong>

      <div v-for="image in images" :key="image.name">
        <p>
          <strong>{{ image.name }}</strong>
        </p>
        <img :src="image.url" />
      </div>
    </div>
  </div>
</template>

<script>
import { s3 } from '@/aws'
import { useRoute } from 'vue-router'
import { computed, onMounted, ref, watch } from 'vue'

export default {
  name: 'onboarding-images-page',
  setup() {
    const fetchedImages = ref({})
    const route = useRoute()

    const bucket = process.env.VUE_APP_ONBOARDING_IMAGES_BUCKET
    const accountNumber = computed(() => route.params.accountNumber)
    const images = computed(() => fetchedImages.value[accountNumber])

    async function fetchImages() {
      const prefix = `public/${accountNumber.value}`
      // getlist of objects from S3
      const list = await s3
        .listObjectsV2({
          Bucket: bucket,
          Prefix: prefix,
        })
        .promise()

      const keys = await Promise.all(
        list.Contents.map(async ({ Key: key }) => ({
          name: key.replace(`${prefix}/`, ''),
          url: await s3.getSignedUrlPromise('getObject', {
            Bucket: bucket,
            Key: key,
          }),
        }))
      )

      fetchedImages.value[accountNumber] = keys
    }

    onMounted(fetchImages)
    watch(accountNumber, fetchImages)

    return {
      images,
      accountNumber,
    }
  },
}
</script>
