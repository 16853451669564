<template>
  <dialog-layout title="Add an inverter or meter">
    <template v-slot:content>
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
          <label for="state" class="block text-sm font-medium text-gray-700">Platform</label>
          <select v-model="vv.brand.$model">
            <option>Solis</option>
            <option>Omnik</option>
            <option>SolarEdge</option>
            <option value="Simpl">Simpl/Sungate</option>
          </select>
        </div>
        <div class="col-span-6 sm:col-span-3 lg:col-span-3" v-if="vv.brand.$model !== 'Simpl'">
          <label for="state" class="block text-sm font-medium text-gray-700">
            Country Code
          </label>
          <select v-model="vv.serviceCountryCode.$model">
            <option>NL</option>
            <option>BE</option>
          </select>
        </div>
        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
          <label for="state" class="block text-sm font-medium text-gray-700">
            Site Id / Plant Id / XMX number
          </label>
          <input
            title="Site id / Plant id"
            type="text"
            name="state"
            v-model.trim="vv.serviceAssignedIdentifier.$model"
            id="state"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div class="mt-5 text-red-900 text-sm" v-if="vv.brand.$model === 'Simpl'">
        Warning: this will only add this meter to the monitoring platform. Please make sure it is
        properly onboarded in Simpl as well
      </div>
      <div class="mt-5 text-red-600 text-sm" v-if="vv.$error || error">
        {{ error || vv.$errors[0].$message }}
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        @click="onSubmit"
      >
        <Spinner
          class="w-6 h-6 border-4 border-gray-500 rounded-full loader"
          v-if="loading"
        ></Spinner>
        Confirm
      </button>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        @click="$emit('close')"
      >
        Cancel
      </button>
    </template>
  </dialog-layout>
</template>

<script>
import { computed, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, helpers } from '@vuelidate/validators'
import gql from 'graphql-tag'
import { useMutation } from '@vue/apollo-composable'
import DialogLayout from '@/components/utils/DialogLayout'
import Spinner from '@/components/utils/Spinner'

export default {
  components: {
    DialogLayout,
    Spinner,
  },
  emits: ['success', 'close'],
  props: {
    locationId: Number,
  },
  setup(props, { emit }) {
    const brand = ref('')
    const serviceCountryCode = ref('')
    const serviceAssignedIdentifier = ref('')

    function isOldPlatform() {
      return brand.value !== 'Simpl'
    }

    const vv = useVuelidate(
      {
        brand: {
          required: helpers.withMessage('You must select a brand', required),
        },
        serviceCountryCode: {
          requiredIf: helpers.withMessage(
            'You must select a country code',
            requiredIf(isOldPlatform)
          ),
        },
        serviceAssignedIdentifier: {
          required: helpers.withMessage('You must enter an identifier for the inverter', required),
        },
      },
      {
        brand,
        serviceCountryCode,
        serviceAssignedIdentifier,
      }
    )

    const { mutate, loading, error } = useMutation(gql`
      mutation AddMeteringSystem(
        $locationId: Int!
        $brand: Brand!
        $serviceAssignedIdentifier: String!
        $serviceCountryCode: CountryCode
      ) {
        addMeteringSystem(
          locationId: $locationId
          brand: $brand
          serviceAssignedIdentifier: $serviceAssignedIdentifier
          serviceCountryCode: $serviceCountryCode
        ) {
          id
          meteringSystems {
            id
            alert {
              category
              message
            }
            maxCumulativeEnergyWh
            brand
            firstReadingAt
            installedBySungevity
            lastReadingAt
            hardwareIdentifier {
              serialNumber
              logicalDeviceName
            }
            serviceAssignedIdentifier {
              plantId
              siteId
              countryCode
            }
            details {
              simplMeterType
              communicationStatus {
                verification {
                  performedOn
                  result
                  success
                }
                connection {
                  performedOn
                  result
                  success
                }
                production {
                  performedOn
                  result
                  success
                }
              }
            }
            status
            inactive {
              since
              setBy
              type
            }
          }
        }
      }
    `)

    const onSubmit = async () => {
      vv.value.$touch()
      if (vv.value.$invalid) return

      const variables = {
        locationId: props.locationId,
        brand: brand.value,
        serviceCountryCode: serviceCountryCode.value || null,
        serviceAssignedIdentifier: serviceAssignedIdentifier.value,
      }

      if (
        !confirm(
          `This will add a ${brand.value} in ${serviceCountryCode.value} with service id ${serviceAssignedIdentifier.value} to the monitoring platform. Continue?`
        )
      )
        return

      const result = await mutate(variables)
      if (result.errors) {
        error.value = result.errors[0]
        return
      }
      if (result.data) {
        emit('success')
        emit('close')
      }
    }

    return {
      vv,
      onSubmit,
      loading,
      error: computed(() => error.value && error.value.message),
    }
  },
}
</script>
