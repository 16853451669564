<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
  >
    <title :id="iconName" lang="en">{{ iconName }}</title>
    <g :fill="iconColor">
      <component :is="icon" />
    </g>
  </svg>
</template>
<script>
/*
Icons we use: https://heroicons.com/
Use copy svg function
extract path element to a component in @/components/utils/icons/${icon-name}.vue
import here
*/

import ExternalLink from 'heroicons/vue/solid/ExternalLink'
import Trash from 'heroicons/vue/outline/Trash'
import ChevronDown from 'heroicons/vue/solid/ChevronDown'
import Logout from 'heroicons/vue/solid/Logout'

const icons = {
  'external-link': ExternalLink,
  'trash': Trash,
  'chevron-down': ChevronDown,
  'logout': Logout,
}

export default {
  props: {
    viewBox: { type: String, default: '0 0 24 24' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 18 },
    height: { type: [Number, String], default: 18 },
    iconColor: { type: String, default: 'currentColor' },
  },
  setup(props) {
    const icon = icons[props.iconName]
    return { icon }
  },
}
</script>
