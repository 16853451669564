import { DateTime } from 'luxon'

export function formatDate(d) {
  return d && DateTime.fromISO(d).toLocaleString(DateTime.DATE_SHORT)
}

export function formatDateFull(d) {
  return d && DateTime.fromISO(d).toLocaleString(DateTime.DATETIME_FULL)
}

export function formatKwh(kwh, digits) {
  if (typeof kwh !== 'number') return
  return `${Intl.NumberFormat('nl-NL', { maximumFractionDigits: digits }).format(kwh)} kWh`
}

export function formatNumber(val, digits=0) {
  if (typeof val !== 'number') return
  return Intl.NumberFormat('nl-NL', { maximumFractionDigits: digits }).format(val)
}

export function formatWh(wh, digits) {
  if (typeof wh !== 'number') return
  return formatKwh(wh/1000, digits)
}

export function sum(list) {
  return list.reduce((a, b) => a + b, 0)
}

// these numbers represent the expected production in a given year 
// as a ratio of the expected production for a full year
// e.g. on average, in July, a PV system will produce 12,4% of the yearly estimate
export const monthRatio = {
  1: 0.03, // jan
  2: 0.047, // feb
  3: 0.092, // mar
  4: 0.126, // apr
  5: 0.13, // may
  6: 0.124, // jun
  7: 0.124, // jul
  8: 0.114, // aug
  9: 0.092, // sep
  10: 0.063, // oct
  11: 0.033, // nov
  12: 0.025, // dec
}

export function formatPercentage(ratio, digits=0) {
  if (typeof ratio !== 'number') return
  return `${Intl.NumberFormat('nl-NL', { maximumFractionDigits: digits }).format(ratio*100)}%`
}

export function kwhToWh(d) {
  return d*1000
}

export function range(length) {
  return Array.from({ length }, (_, i) => i)
}
