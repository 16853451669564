<template>
  <dialog-layout title="Create New Location">
    <template v-slot:content>
      <div class="text-xs mb-5">
        All fields are required, except those marked as optional.
      </div>

      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-3">
          <label for="accountNumber" class="block text-sm font-medium text-gray-700">
            Account number
          </label>
          <input
            type="text"
            name="accountNumber"
            id="accountNumber"
            v-model.lazy.trim="vv.accountNumber.$model"
          />
          <span class="text-red-900 text-sm" v-if="vv.$dirty && vv.accountNumber.$errors.length">
            {{ vv.accountNumber.$errors[0].$message }}
          </span>
        </div>
        <div class="col-span-3 grid grid-cols-6 gap-1">
          <div class="col-span-5">
            <label for="autoFillAccount" class="block text-sm font-medium text-gray-700">
              Autocomplete from CRM
            </label>
          </div>
          <div class="col-span-1">
            <input
              v-model="shouldAutoFillAccount"
              id="autoFillAccount"
              name="autoFillAccount"
              type="checkbox"
              class="h-4 w-4 border-gray-300 rounded mx-2"
            />
          </div>
          <div class="col-span-6">
            <Spinner
              class="border-blue-500 w-6 h-6 border-4 mx-auto mt-5"
              v-if="accountLoading"
            ></Spinner>
            <div v-else-if="accountError" class="bg-red-200 p-2 rounded text-sm">
              {{ accountError }}
            </div>
          </div>
        </div>
        <div class="col-span-1">
          <label for="location_type_b2c" class="text-sm font-medium text-gray-700 mr-1">B2C</label>
          <input
            v-model="locationType"
            type="radio"
            name="location_type"
            id="location_type_b2c"
            value="b2c"
            class=""
          />
        </div>
        <div class="col-span-4">
          <label for="location_type_b2b" class="text-sm font-medium text-gray-700 mr-1">B2B</label>
          <input
            v-model="locationType"
            type="radio"
            name="location_type"
            id="location_type_b2b"
            value="b2b"
            class=""
          />
        </div>
        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
          <label for="address" class="block text-sm font-medium text-gray-700">
            Street + Housenumber
          </label>
          <input
            title="straat en huisnummer"
            type="text"
            name="address"
            id="address"
            v-model="vv.address.$model"
          />
          <span class="text-red-900 text-sm" v-if="vv.$dirty && vv.address.$errors.length">
            {{ vv.address.$errors[0].$message }}
          </span>
        </div>
        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
          <label for="postal_code" class="block text-sm font-medium text-gray-700">
            Postal Code
          </label>
          <input
            type="text"
            name="postal_code"
            v-model.trim="vv.postalCode.$model"
            id="postal_code"
            autocomplete="postal-code"
          />
          <span class="text-red-900 text-sm" v-if="vv.$dirty && vv.postalCode.$errors.length">
            {{ vv.postalCode.$errors[0].$message }}
          </span>
        </div>
        <div class="col-span-6 sm:col-span-6 lg:col-span-3">
          <label for="city" class="block text-sm font-medium text-gray-700">City</label>
          <input v-model="vv.city.$model" type="text" name="city" id="city" />
          <span class="text-red-900 text-sm" v-if="vv.$dirty && vv.city.$errors.length">
            {{ vv.city.$errors[0].$message }}
          </span>
        </div>
        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
          <label for="country_code" class="block text-sm font-medium text-gray-700">
            Country Code
          </label>
          <select v-model="vv.countryCode.$model" name="country code" id="country_code">
            <option>NL</option>
            <option>BE</option>
          </select>
          <span class="text-red-900 text-sm" v-if="vv.$dirty && vv.countryCode.$errors.length">
            {{ vv.countryCode.$errors[0].$message }}
          </span>
        </div>
        <div class="col-span-6 sm:col-span-4">
          <label for="online_since" class="block text-sm font-medium text-gray-700">
            System Online Date
          </label>
          <input
            v-model="vv.onlineSince.$model"
            type="date"
            name="online_since"
            id="online_since"
            placeholder="01-01-2018"
          />
          <span class="text-red-900 text-sm" v-if="vv.$dirty && vv.onlineSince.$errors.length">
            {{ vv.onlineSince.$errors[0].$message }}
          </span>
        </div>
        <div class="col-span-4">
          <label
            for="annual_production_estimate_kwh"
            class="block text-sm font-medium text-gray-700"
          >
            Annual Production Estimate
            <span class="text-gray-300" v-if="locationType === 'b2b'">Optional</span>
          </label>
          <div class="flex">
            <input
              v-model.number="vv.annualProductionEstimateKwh.$model"
              type="number"
              id="annual_production_estimate_kwh"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div class="my-auto ml-1">
              kWh
            </div>
          </div>

          <span
            class="text-red-900 text-sm"
            v-if="vv.$dirty && vv.annualProductionEstimateKwh.$errors.length"
          >
            {{ vv.annualProductionEstimateKwh.$errors[0].$message }}
          </span>
        </div>

        <template v-if="locationType === 'b2b'">
          <div class="col-span-3">
            <label for="location_group_id" class="block text-sm font-medium text-gray-700">
              B2B company
            </label>
            <select
              v-model="vv.locationGroupId.$model"
              id="location_group_id"
              name="location group"
            >
              <option></option>
              <option :key="group.id" v-for="group in locationGroups" :value="group.id">
                {{ group.friendlyName }}
              </option>
            </select>
            <span
              class="text-red-900 text-sm"
              v-if="vv.$dirty && vv.locationGroupId.$errors.length"
            >
              {{ vv.locationGroupId.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-3">
            <label for="customer_reference" class="block text-sm font-medium text-gray-700">
              Complex Number
            </label>
            <input
              v-model="vv.customerReference.$model"
              type="text"
              id="customer_reference"
              name="customer_reference"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <span
              class="text-red-900 text-sm"
              v-if="vv.$dirty && vv.customerReference.$errors.length"
            >
              {{ vv.customerReference.$errors[0].$message }}
            </span>
          </div>
        </template>
        <div class="col-span-4">
          <label for="offers" class="block text-sm font-medium text-gray-700">Has Sunsure</label>
          <input
            v-model="hasSunsure"
            id="offers"
            name="offers"
            type="checkbox"
            class="h-4 w-4 border-gray-300 rounded mx-2"
          />
        </div>
        <template v-if="hasSunsure">
          <div class="col-span-3">
            <label for="sunsure_duration_years" class="block text-sm font-medium text-gray-700">
              Sunsure Duration
            </label>
            <div class="flex content-center">
              <input
                v-model="vv.sunsureDurationYears.$model"
                type="number"
                id="sunsure_duration_years"
                name="sunsure_duration_years"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div class="my-auto ml-1">
                years
              </div>
            </div>

            <span
              class="text-red-900 text-sm"
              v-if="vv.$dirty && vv.sunsureDurationYears.$errors.length"
            >
              {{ vv.sunsureDurationYears.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-3">
            <label for="sunsure_guarantee_ratio" class="block text-sm font-medium text-gray-700">
              Sunsure Guarantee
            </label>
            <div class="flex">
              <input
                v-model="vv.sunsureGuaranteeRatio.$model"
                min="0.01"
                max="1"
                step="0.01"
                type="number"
                id="sunsure_guarantee_ratio"
                name="sunsure_guarantee_ratio"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div class="my-auto ml-1">
                %
              </div>
            </div>

            <span
              class="text-red-900 text-sm"
              v-if="vv.$dirty && vv.sunsureGuaranteeRatio.$errors.length"
            >
              {{ vv.sunsureGuaranteeRatio.$errors[0].$message }}
            </span>
          </div>
        </template>
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        @click="onSubmit"
      >
        <Spinner
          class="w-6 h-6 border-4 border-gray-500 rounded-full loader"
          v-if="isSubmitting"
        ></Spinner>
        Confirm
      </button>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        @click="$emit('close')"
      >
        Cancel
      </button>
      <span class="text-red-900 text-sm mr-5" v-if="createLocationError">
        {{ createLocationError.message }}
      </span>
    </template>
  </dialog-layout>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, helpers } from '@vuelidate/validators'
import DialogLayout from '@/components/utils/DialogLayout'
import Spinner from '@/components/utils/Spinner'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import gql from 'graphql-tag'

export default {
  components: {
    DialogLayout,
    Spinner,
  },
  emits: ['close', 'success'],
  setup(_props, { emit }) {
    const accountNumber = ref(null)
    const shouldAutoFillAccount = ref(true)
    const city = ref('')
    const address = ref('')
    const postalCode = ref('')
    const countryCode = ref('NL')
    const locationGroupId = ref(null)
    const customerReference = ref('')
    const onlineSince = ref('')
    const locationType = ref('b2c')
    const hasSunsure = ref(true)
    const annualProductionEstimateKwh = ref(null)
    const sunsureDurationYears = ref('')
    const sunsureGuaranteeRatio = ref('')
    const responseError = ref('')

    const { result: locationGroupsResult } = useQuery(gql`
      query GetLocationGroups {
        getAbstractLocationGroups {
          id
          friendlyName
        }
      }
    `)
    const locationGroups = useResult(locationGroupsResult)

    const vv = useVuelidate(
      {
        city: { required },
        accountNumber: {
          requiredIf: helpers.withMessage(
            'Need an account number if address and postal code are not given',
            requiredIf(() => !address.value && !postalCode.value)
          ),
        },
        address: {
          requiredIf: helpers.withMessage(
            'Need an address if no account number is provided',
            requiredIf(() => !accountNumber.value)
          ),
          validAddress: helpers.withMessage('Address Must be a valid street and housenumber', (v) =>
            /^.*\s\d+\w?$/.test(v)
          ),
        },
        postalCode: {
          requiredIf: helpers.withMessage(
            'Need a postal code if no account number is provided',
            requiredIf(() => !accountNumber.value)
          ),
          validPostalCode: helpers.withMessage(
            'Must be a valid postalCode',
            (v) => /^\d{4}\s?\w{2}$/.test(v) || /^\d{4}$/.test(v)
          ),
        },
        countryCode: {
          required,
        },
        onlineSince: {
          required,
        },
        locationGroupId: {
          requiredIf: helpers.withMessage(
            'This Field is required for b2b locations',
            requiredIf(() => locationType.value === 'b2b')
          ),
        },
        customerReference: {
          requiredIf: helpers.withMessage(
            'This Field is required for b2b locations',
            requiredIf(() => locationType.value === 'b2b')
          ),
        },
        annualProductionEstimateKwh: {
          requiredIf: helpers.withMessage(
            'This Field is required for b2c locations',
            requiredIf(() => locationType.value === 'b2c')
          ),
        },
        sunsureDurationYears: {
          requiredIf: helpers.withMessage(
            'This Field is required if the location has sunsure',
            requiredIf(() => hasSunsure.value)
          ),
        },
        sunsureGuaranteeRatio: {
          requiredIf: helpers.withMessage(
            'This Field is required if the location has sunsure',
            requiredIf(() => hasSunsure.value)
          ),
        },
      },
      {
        hasSunsure,
        accountNumber,
        city,
        address,
        locationGroupId,
        customerReference,
        postalCode,
        countryCode,
        onlineSince,
        annualProductionEstimateKwh,
        sunsureDurationYears,
        sunsureGuaranteeRatio,
      }
    )

    const getAccountParams = ref({
      credentials: null,
      databaseName: null,
      accountNumber: accountNumber,
      accountInfo: {
        monitoringId: accountNumber,
        countryCode,
        city,
        address,
        postalCode,
      },
    })

    const getAccountEnabled = computed(() => {
      return (
        shouldAutoFillAccount.value &&
        ((!!accountNumber.value &&
          accountNumber.value.trim().length > 0 &&
          !vv.value['accountNumber'].$invalid) ||
          (countryCode.value.toUpperCase() === 'NL' &&
            !!address.value &&
            !vv.value['address'].$invalid &&
            !!postalCode.value &&
            !vv.value['postalCode'].$invalid) ||
          (!!city.value &&
            !!address.value &&
            !vv.value['address'].$invalid &&
            !!postalCode.value &&
            !vv.value['postalCode'].$invalid))
      )
    })

    const { result: accountResult, loading: accountLoading } = useQuery(
      gql`
        query GetAccount($getAccountParams: GetAccountParams!) {
          getAccount(getAccountParams: $getAccountParams) {
            accountNumber
            address
            city
            postalCode
            countryCode
          }
        }
      `,
      { getAccountParams },
      {
        enabled: getAccountEnabled,
        debounce: 1000,
      }
    )

    const account = computed(() => accountResult.value && accountResult.value.getAccount)
    watch(account, (info) => {
      if (info && info[0]) {
        const first = info[0]
        accountNumber.value = first.accountNumber
        address.value = first.address
        city.value = first.city
        postalCode.value = first.postalCode
        countryCode.value = first.countryCode
      }
    })

    const { result: locationsResult, loading: locationsLoading } = useQuery(
      gql`
        query CheckLocations($accountNumber: Int!) {
          getLocations(filter: { accountNumber: $accountNumber }, limit: 0, offset: 0) {
            totalCount
          }
        }
      `,
      { accountNumber },
      {
        enabled: computed(() => !!accountNumber.value),
      }
    )
    const locationsFound = computed(
      () => locationsResult.value && locationsResult.value.getLocations.totalCount
    )

    const accountError = computed(() => {
      if (
        shouldAutoFillAccount.value &&
        vv.value.accountNumber.$model &&
        !accountLoading.value &&
        !account.value
      ) {
        return 'Account not found in CRM. Please check contact info!'
      } else if (locationsFound.value > 0) {
        return `Found ${locationsFound.value} locations connected to this account number.`
      }
    })

    const createLocation = useMutation(gql`
      mutation CreateLocation($location: CreateLocationInput!) {
        createLocation(location: $location) {
          id
          address
          postalCode
          city
          accountNumber
          countryCode
          lastReadingAt
          locationGroupId
          onlineSince
          annualProductionEstimateWh
          sunsureGuaranteeRatio
          sunsureDurationYears
          meteringSystems {
            alert {
              message
            }
            status
            maxCumulativeEnergyWh
            brand
            firstReadingAt
            lastReadingAt
            hardwareIdentifier {
              serialNumber
            }
            serviceAssignedIdentifier {
              plantId
              siteId
              countryCode
            }
          }
          locationGroup {
            friendlyName
          }
          ownershipHistory {
            accountNumber
            ownershipStartedAt
          }
        }
      }
    `)

    async function onSubmit() {
      vv.value.$touch()
      responseError.value = ''

      if (vv.value.$invalid) throw 'validation error (invalid)'

      const { data } = await createLocation.mutate({
        location: {
          accountNumber: accountNumber.value,
          city: city.value,
          address: address.value,
          postalCode: postalCode.value,
          countryCode: countryCode.value,
          onlineSince: onlineSince.value,
          locationGroupId: locationType.value === 'b2b' ? locationGroupId.value : null,
          customerReference: customerReference.value || null,
          annualProductionEstimateWh: annualProductionEstimateKwh.value * 1000,
          sunsureDurationYears: hasSunsure.value ? sunsureDurationYears.value : null,
          sunsureGuaranteeRatio: hasSunsure.value ? sunsureGuaranteeRatio.value / 100 : null,
        },
      })

      if (data.createLocation) {
        emit('success', data.createLocation.id)
        emit('close')
      } else {
        alert('Something went wrong!')
      }
    }

    return {
      vv,
      onSubmit,
      isSubmitting: createLocation.loading,
      createLocationError: createLocation.error,
      locationType,
      hasSunsure,
      account,
      locationsFound,
      accountLoading: computed(() => accountLoading.value || locationsLoading.value),
      accountError,
      locationGroups,
      shouldAutoFillAccount,
    }
  },
}
</script>

<style lang="postcss" scoped>
input[type='text'],
input[type='number'],
input[type='date'],
select {
  @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}
label {
  cursor: pointer;
}
</style>
