<template>
  <sidebar-layout>
    <template v-slot:sidebar>
      <span class="text-md font-semibold">Filters</span>

      <!-- Sidebar Search -->
      <FilterInput
        label="Year"
        name="year"
        v-model="filter.year"
        :options="years.map((y) => [y])"
      />

      <FilterInput
        label="Month"
        name="month"
        v-model="filter.month"
        :options="months.map((month, index) => [index + 1, month])"
      />

      <FilterInput
        label="Sunsure Result"
        name="sunsureResult"
        v-model="filter.sunsureResult"
        :options="[
          ['SUCCESS', 'Success'],
          ['FAILURE', 'Failure'],
        ]"
      />

      <FilterInput
        label="Sales area"
        name="countryCode"
        v-model="filter.countryCode"
        :options="[
          ['NL', 'the Netherlands'],
          ['BE', 'Belgium'],
        ]"
      />

      <FilterInput
        label="Market type"
        name="hasLocationGroup"
        v-model="filter.hasLocationGroup"
        :options="[
          [false, 'B2C'],
          [true, 'B2B'],
        ]"
      />

      <FilterInput
        label="Account number"
        name="accountNumber"
        v-model="filter.accountNumber"
        type="number"
        placeholder="e.g. 123456"
      />
    </template>
    <template v-slot:content>
      <div
        class="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center justify-between sm:px-6 lg:px-8"
      >
        <div class="flex-1 min-w-0">
          <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
            Sunsure birthdays
          </h1>
          <span
            v-if="!loading && birthdays"
            class="text-md font-medium leading-6 text-gray-400 sm:truncate"
          >
            Found {{ birthdays.totalCount }} Sunsure birthdays
            <span v-if="pages">- showing {{ pageStart }}-{{ pageEnd }}</span>
          </span>
          <div class="w-6 h-6 border-4 border-gray-500 rounded-full loader" v-if="loading"></div>
        </div>
        <div class="flex" v-if="birthdays">
          <button
            class="flex px-3 py-2 items-center justify-center rounded-md bg-blue-300 text-white"
            :class="!exportLoading && 'hover:bg-blue-400'"
            @click="exportToCsv"
            :disabled="exportLoading"
          >
            <div
              class="w-4 h-4 border-2 border-blue-500 rounded-full loader"
              v-if="exportLoading"
            ></div>
            <span class="px-2">Export {{ birthdays.totalCount }} records to .csv</span>
          </button>
        </div>
      </div>
      <div v-if="errorMessage" class="py-3 px-6">
        <span class="text-red-800 font-semibold">An error occured: {{ errorMessage }}</span>
      </div>
      <div>
        <table
          class="min-w-full divide-y divide-gray-300"
          :class="loading ? 'opacity-30' : ''"
          v-if="birthdays"
        >
          <thead class="bg-gray-200">
            <tr>
              <th>Address</th>
              <th>Birthday</th>
              <th>Guaranteed year production</th>
              <th>Actual year production</th>
              <th>Sunsure result</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              v-for="birthday in birthdays.nodes"
              :key="`${birthday.birthday}${birthday.location.id}`"
              class="hover:bg-gray-100 cursor-pointer"
            >
              <td class="px-6 py-3">
                <div class="text-md">{{ birthday.location.address || '(empty)' }}</div>
                <div class="text-xs text-gray-400">
                  {{ birthday.location.postalCode }} {{ birthday.location.city }}
                </div>
              </td>
              <td class="px-6 py-3 text-lg font-light">
                {{ birthday.birthday }}
              </td>
              <td class="px-6 py-3 text-lg font-light">
                {{ format(birthday.productionDeltas.guaranteedKwh) }} kWh
              </td>
              <td class="px-6 py-3 text-lg font-light">
                <span
                  v-if="birthday.productionDeltas.actualKwh === null"
                  class="px-2 inline-flex text-xs leading-5 font-semibold uppercase rounded-full bg-red-100 text-red-800"
                >
                  no data
                </span>
                <span v-else>{{ format(birthday.productionDeltas.actualKwh) }} kWh</span>
              </td>
              <td class="px-6 py-3 text-xs font-medium tracking-wider">
                <span
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                  :class="
                    birthday.sunsureResult === 'SUCCESS'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  "
                >
                  {{ birthday.sunsureResult }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <pagination
          :page="page"
          :pages="pages"
          @next="page += 1"
          @previous="page -= 1"
        ></pagination>
      </div>
    </template>
  </sidebar-layout>
</template>

<script>
import { DateTime } from 'luxon'
import { computed, ref, watch } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { s3 } from '@/aws'
import { SidebarLayout, Pagination } from '@/components/utils'
import { range } from '@/utils'
import FilterInput from '@/components/FilterInput'

export const years = range(10).map((n) => DateTime.local().year - n)

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export default {
  components: {
    SidebarLayout,
    Pagination,
    FilterInput,
  },
  setup() {
    const limit = 30 // no of results per page
    const page = ref(0)
    const offset = computed(() => page.value * limit)

    const filter = ref({
      year: DateTime.local().year - 1,
      month: '',
      sunsureResult: '',
      countryCode: '',
      hasLocationGroup: '',
      accountNumber: '',
    })
    const queryFilter = computed(() => ({
      year: filter.value.year || null,
      month: filter.value.month || null,
      sunsureResult: filter.value.sunsureResult || null,
      countryCode: filter.value.countryCode || null,
      // hasLocationGroup can be true, false or an empty string
      hasLocationGroup: filter.value.hasLocationGroup === '' ? null : filter.value.hasLocationGroup,
      accountNumber: filter.value.accountNumber || null,
    }))

    // when the filter changes, move back to page 1
    watch(filter, () => (page.value = 0), { deep: true })

    const { result, loading, error: sunsureBirthdaysError } = useQuery(
      gql`
        query sunsureBirthdays($filter: SunsureBirthdayFilter, $limit: Int!, $offset: Int!) {
          getSunsureBirthdays(filter: $filter, limit: $limit, offset: $offset) {
            totalCount
            nodes {
              birthday
              cumulativeDeltasCountAverage
              sunsureResult
              productionDeltas {
                predictedKwh
                guaranteedKwh
                actualKwh
              }
              productionCumulative {
                predictedKwh
                guaranteedKwh
                actualKwh
              }
              location {
                id
                address
                postalCode
                city
              }
            }
          }
        }
      `,
      {
        filter: queryFilter,
        limit,
        offset,
      }
    )

    const totalCount = computed(() => result.value?.getSunsureBirthdays?.totalCount || 0)
    const pages = computed(() => Math.ceil(totalCount.value / limit))
    const pageStart = computed(() => offset.value + 1)
    const pageEnd = computed(() => Math.min(offset.value + limit, totalCount.value))

    const format = (n) => Intl.NumberFormat('nl-NL', { maximumFractionDigits: 0 }).format(n)

    // If we introduce more exports, we might introduce a component for a CSV export.
    // See the LocationList for a similar export functionality.
    async function objectExists(bucket, key) {
      try {
        await s3.headObject({ Bucket: bucket, Key: key }).promise()
        return true
      } catch (e) {
        if (e.name === 'NotFound') return false
        else throw e
      }
    }

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms))

    async function waitTillObjectExists(bucket, key, n = 1) {
      if (await objectExists(bucket, key)) return true
      else if (n > 100) return false
      else {
        await sleep(Math.pow(n, 1.5) * 1000)
        return waitTillObjectExists(bucket, key, n + 1)
      }
    }

    //ML: we might be missing errors here, that is, the `onError` callback
    const { mutate, loading: exportLoading, onDone } = useMutation(
      gql`
        mutation sunsureBirthdays($filter: SunsureBirthdayFilter) {
          exportSunsureBirthdaysToCsv(filter: $filter) {
            key
            bucket
          }
        }
      `,
      () => ({
        variables: {
          filter: queryFilter.value,
        },
      })
    )
    const checkingObject = ref(false)

    onDone(async (val) => {
      if (val.data?.exportSunsureBirthdaysToCsv?.key) {
        const { bucket, key } = val.data.exportSunsureBirthdaysToCsv
        checkingObject.value = true
        const exists = await waitTillObjectExists(bucket, key)
        checkingObject.value = false

        if (exists) {
          window.location.href = await s3.getSignedUrlPromise('getObject', {
            Bucket: bucket,
            Key: key,
          })
        } else {
          alert(
            'Something went really wrong fetching your CSV. We deeply regret this, but hey, things happen. Please create a helpdesk ticket for IT.'
          )
        }
      } else {
        alert(
          'Something went wrong fetching your CSV. We deeply regret this, but hey, things happen. Please create a helpdesk ticket for IT.'
        )
      }
    })

    const birthdays = computed(() => result.value?.getSunsureBirthdays)
    const exportToCsv = () => {
      if (birthdays.value?.totalCount > 10000) {
        alert('An export can contain a maximum of 10.000 birthdays.')
      } else {
        mutate()
      }
    }

    return {
      exportToCsv,
      exportLoading: computed(() => exportLoading.value || checkingObject.value),
      format,
      errorMessage: computed(() => sunsureBirthdaysError.value),
      filter,
      page,
      pages,
      pageStart,
      pageEnd,
      birthdays,
      loading,
      years,
      months,
    }
  },
}
</script>

<style scoped lang="postcss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}

th {
  @apply px-6 py-3 text-xs font-medium uppercase text-left;
}
</style>
