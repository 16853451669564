<template>
  <PageInfo v-if="displayPageInfo" @close="displayPageInfo = false" />
  <nav class="bg-white shadow-lg">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div
          class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
        >
          <div class="flex-shrink-0 flex items-center">
            <img
              class="block lg:hidden h-8 w-auto"
              src="@/assets/images/sun_orange.svg"
              alt="Workflow"
            />
            <img
              class="hidden lg:block h-8 w-auto"
              src="@/assets/images/sun_orange.svg"
              alt="Workflow"
            />
          </div>
          <div v-if="authenticated" class="hidden sm:block sm:ml-6">
            <div v-show="!hideMenu" class="flex space-x-4">

              <router-link 
                :to="{ name: 'accounts-list' }"
                class="menu-item-link" 
                role="menuitem"
                :class="{ active: routeName === 'accounts-list' }"
              >
                <users-icon></users-icon>
                MySungevity users
              </router-link>

              <router-link 
                :to="{ name: 'locations-list' }"
                class="menu-item-link" 
                role="menuitem"
                :class="{ active: routeName.startsWith('location') }"
              >
                <home-icon></home-icon>
                Locations &amp; inverters
              </router-link>

              <a 
                :href="`${mySungevityBaseUrl}/admin`"
                target="_blank"
                class="menu-item-link"
                role="menuitem"
              >
                <external-link-icon></external-link-icon>
                Impersonate User
              </a>
              
              <router-link
                v-show="isB2BManager || name !== 'b2b-overview'"
                :to="{ name: 'b2b-overview' }"
                class="menu-item-link" 
                role="menuitem"
                :class="{ active: routeName.startsWith('b2b') }"
                v-if="isB2BManager"
              >
                <user-group-icon></user-group-icon>
                B2B
              </router-link>


              <router-link
                :to="{ name: 'sunsure-overview' }"
                class="menu-item-link" 
                role="menuitem"
                :class="{ active: routeName === 'sunsure-overview' }"
                v-if="isSunsureManager"
              >
                <currency-euro-icon></currency-euro-icon>
                Sunsure
              </router-link>

              <router-link
                :to="{ name: 'lead-form' }"
                class="menu-item-link"
                role="menuitem"
                :class="{ active: routeName === 'lead-form' }"
                v-if="isLeadFormManager"
              >
                <news-paper-icon></news-paper-icon>
                Lead Form
              </router-link>

            </div>
          </div>
        </div>
        <div
          v-if="authenticated"
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <div class="relative">
            <div class="fixed inset-0 z-40" v-if="dropdownOpen" @click="dropdownOpen = false"></div>

            <button type="button" class="dropdown-button" id="options-menu" aria-haspopup="true" aria-expanded="true" @click="dropdownOpen = true">
              <user-circle-icon class="mr-1 mt-0 h-5 w-5"></user-circle-icon>
              {{name}}
              <chevron-down-icon class="-mr-1 ml-2 mt-0 h-5 w-5"></chevron-down-icon>
            </button>

            <div class="dropdown-menu" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" v-show="dropdownOpen">
              <div class="py-1">
                <router-link 
                  v-if="accessToPermissions"
                  :to="{ name: 'permissions' }"
                  role="menuitem"
                >
                  <adjustments-icon></adjustments-icon>
                  Permissions
                </router-link>
                
                <a 
                  href="#"
                  @click.prevent="logout"
                  class="red" 
                  role="menuitem"
                >
                  <logout-icon class="group-hover:text-red-500"></logout-icon>
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Auth from '@aws-amplify/auth'
import { mapGetters } from 'vuex'
import PageInfo from '@/components/PageInfo'
import ChevronDownIcon from 'heroicons/vue/solid/ChevronDown'
import UsersIcon from 'heroicons/vue/solid/Users'
import HomeIcon from 'heroicons/vue/solid/Home'
import ExternalLinkIcon from 'heroicons/vue/solid/ExternalLink'
import LogoutIcon from 'heroicons/vue/solid/Logout'
import AdjustmentsIcon from 'heroicons/vue/solid/Adjustments'
import UserCircleIcon from 'heroicons/vue/outline/UserCircle'
import CurrencyEuroIcon from 'heroicons/vue/outline/CurrencyEuro'
import UserGroupIcon from 'heroicons/vue/outline/UserGroup'
import NewsPaperIcon from "heroicons/vue/outline/Newspaper"

const mySungevityBaseUrl = process.env.VUE_APP_MYSUNGEVITY_URL

export default {
  name: 'app-header',
  components: {
    PageInfo,
    UsersIcon,
    ChevronDownIcon,
    HomeIcon,
    ExternalLinkIcon,
    LogoutIcon,
    AdjustmentsIcon,
    UserCircleIcon,
    CurrencyEuroIcon,
    UserGroupIcon,
    NewsPaperIcon,
  },
  data() {
    return {
      dropdownOpen: false,
      displayPageInfo: false,
      mySungevityBaseUrl,
      routes: {
        'accounts-list': 'Search for Email Address',
        'metering-db-view': 'Manage meters & inverters',
        'b2b-overview': 'B2B',
        'sunsure-overview': 'Sunsure',
        'lead-form': 'Lead Form'
      },
    }
  },
  methods: {
    logout() {
      Auth.signOut()
      return false
    },
    toggle(view) {
      this.$emit('toggleView', view)
    },
  },
  computed: {
    ...mapGetters(['authenticated', 'isB2BManager', 'isSunsureManager', 'isLeadFormManager']),
    hideMenu() {
      return this.$route.name === 'onboarding-images-view'
    },
    name() {
      return this.$store.state.auth.email.split('@')[0]
    },
    accessToPermissions() {
      return this.isB2BManager || this.isSunsureManager || this.isLeadFormManager
    },
    routeName() {
      return this.$route.name || ''
    }
  },
  mounted() {
    this.$router.afterEach(() => {
      this.dropdownOpen = false;
    })
  },
}
</script>

<style scoped lang="postcss">
.menu-item-link {
  @apply group flex items-center px-2 py-2 rounded-md text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-900
}
.menu-item-link.active {
  @apply bg-gray-200
}
.menu-item-link svg {
  @apply mr-2 h-5 w-5 text-gray-500 group-hover:text-gray-900
}
.dropdown-button {
  @apply bg-blue-100 inline-flex justify-center w-full px-4 py-2 bg-white text-sm font-medium rounded-md text-gray-800 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500
}
.dropdown-menu {
  @apply origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-50
}
.dropdown-menu a {
  @apply group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900
}
.dropdown-menu a svg {
  @apply mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500
}
.dropdown-menu a.red {
  @apply hover:bg-red-100 hover:text-red-900
}
</style>
