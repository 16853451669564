<template>
  <div>
    <div class="mx-5 mt-2">
      <router-link
        :to="{ name: 'locations-list' }"
        class="bg-blue-200 hover:bg-blue-300 rounded py-1 px-2 inline-flex items-center"
        tag="button"
      >
        <ChevronLeft class="w-3 h-3" />
        <div class="text-xs">Back to locations</div>
      </router-link>
    </div>

    <spinner class="border-blue-500 w-8 h-8 border-4 mx-auto mt-5" v-if="loading"></spinner>

    <div class="p-4 sm:rounded-md bg-red-100 sm:w-1/2 lg:w-1/3 w-full mx-auto mt-4" v-if="error">
      {{ error }}
    </div>

    <div class="grid grid-cols-10 items-start gap-5 mx-5 mt-3" v-if="location && !loading">
      <div
        class="border border-gray-300 shadow-md rounded-xl px-4 pt-6 pb-10 bg-white"
        :class="hasSubRoute ? 'col-span-10 md:col-span-4' : 'col-span-10 md:col-span-7'"
      >
        <AddLocationOwnerForm
          v-if="addOwnerDialog"
          @close="addOwnerDialog = false"
          :location-id="location.id"
        />
        <AddInverterToLocationForm
          v-if="addMeteringSystemDialog"
          @close="addMeteringSystemDialog = false"
          :location-id="location.id"
        />
        <MoveMeteringSystemForm
          v-if="moveMeteringSystem"
          @close="moveMeteringSystem = false"
          @success="redirectToNextLocation"
          :metering-system="moveMeteringSystem"
          :location-id="location.id"
        />
        <MeteringSystemAuditLog
          v-if="openMeteringSystemAuditLog"
          @close="openMeteringSystemAuditLog = false"
          :location="location"
        />

        <MarkMeteringSystemAsInactiveForm
          v-if="markMeteringSystemAsInactive"
          @close="markMeteringSystemAsInactive = false"
          :location-id="location.id"
          :metering-system="markMeteringSystemAsInactive"
        />

        <MarkMeteringSystemAsActiveForm
          v-if="markMeteringSystemAsActive"
          @close="markMeteringSystemAsActive = false"
          :location-id="location.id"
          :metering-system="markMeteringSystemAsActive"
        />

        <UpdateAnnualProductionEstimateForm
          v-if="updateAnnualProductionEstimateDialog"
          @close="updateAnnualProductionEstimateDialog = false"
          :location-id="location.id"
        />

        <RemoveMeteringSystem
          v-if="removeMeteringSystem"
          @close="removeMeteringSystem = false"
          :metering-system="removeMeteringSystem"
          :location-id="location.id"
        />

        <UpdateCustomerReferenceForm
          v-if="updateCustomerReferenceDialog"
          @close="updateCustomerReferenceDialog = false"
          :location-id="location.id"
        />
        <h1 class="leading-16 text-4xl">{{ location.address }}</h1>

        <div class="border-b border-gray-200">
          <nav
            class="-mb-px flex space-x-8"
            :class="hasSubRoute ? 'text-xs' : 'text-md'"
            aria-label="Tabs"
          >
            <a
              v-for="tab in tabs"
              :key="tab.name"
              :href="tab.href"
              :class="[
                tab.current
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'group inline-flex items-center py-4 px-1 border-b-2 font-medium',
              ]"
              :aria-current="tab.current ? 'page' : undefined"
            >
              <component
                :is="tab.icon"
                :class="[
                  tab.current ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                  '-ml-0.5 mr-2 h-5 w-5',
                ]"
                aria-hidden="true"
              />
              <span>{{ tab.name }}</span>
            </a>
          </nav>
        </div>

        <BasicLocationInfo
          :location="location"
          :compact="hasSubRoute"
          v-if="!currentTab || currentTab === '#info'"
          @open-move-metering-system-dialog="openMoveMeteringSystemDialog"
          @open-add-metering-system-dialog="addMeteringSystemDialog = true"
          @open-add-owner-dialog="addOwnerDialog = true"
          @open-metering-system-audit-log="openMeteringSystemAuditLog = true"
          @open-update-annual-production-estimate-dialog="
            updateAnnualProductionEstimateDialog = true
          "
          @open-mark-metering-system-as-inactive="openMarkMeteringSystemAsInactiveDialog"
          @open-mark-metering-system-as-active="openMarkMeteringSystemAsActiveDialog"
          @open-remove-metering-system="openRemoveMeteringSystem"
          @open-update-customer-reference-dialog="updateCustomerReferenceDialog = true"
        />
        <LocationSunsureResults v-if="currentTab === '#sunsure'" :location="location" />
        <keep-alive>
          <MissedProductionCalculator v-if="currentTab === '#calculator'" :location="location" />
        </keep-alive>
        <keep-alive>
          <LocationProductionReport v-if="currentTab === '#production'" :location="location" />
        </keep-alive>
        <keep-alive>
          <LocationProductionGraph v-if="currentTab === '#production-graph'" :location="location" />
        </keep-alive>
      </div>
      <div class="col-span-10 md:col-span-6">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { useQuery } from '@vue/apollo-composable'
import { useRoute, useRouter } from 'vue-router'
import gql from 'graphql-tag'
import { computed, ref, KeepAlive } from 'vue'
import Spinner from '@/components/utils/Spinner'
import AddLocationOwnerForm from '@/components/AddLocationOwnerForm'
import AddInverterToLocationForm from '@/components/AddInverterToLocationForm'
import MoveMeteringSystemForm from '@/components/MoveMeteringSystemForm'
import { formatDate, formatWh } from '@/utils'
import LocationSunsureResults from '@/components/LocationSunsureResults'
import MissedProductionCalculator from '@/components/MissedProductionCalculator'
import BasicLocationInfo from '@/components/BasicLocationInfo'
import LocationProductionReport from '@/components/LocationProductionReport'
import { User, ChevronLeft } from 'heroicons/vue/solid'
import LocationProductionGraph from '@/components/LocationProductionGraph'
import MeteringSystemAuditLog from '@/components/MeteringSystemAuditLog'
import UpdateAnnualProductionEstimateForm from '@/components/UpdateAnnualProductionEstimateForm'
import MarkMeteringSystemAsInactiveForm from '@/components/MarkMeteringSystemAsInactiveForm'
import MarkMeteringSystemAsActiveForm from '@/components/MarkMeteringSystemAsActiveForm'
import RemoveMeteringSystem from '@/components/RemoveMeteringSystem'
import UpdateCustomerReferenceForm from '@/components/UpdateCustomerReferenceForm'

export default {
  components: {
    Spinner,
    AddLocationOwnerForm,
    AddInverterToLocationForm,
    MoveMeteringSystemForm,
    LocationSunsureResults,
    MissedProductionCalculator,
    BasicLocationInfo,
    KeepAlive,
    LocationProductionReport,
    ChevronLeft,
    LocationProductionGraph,
    MeteringSystemAuditLog,
    UpdateAnnualProductionEstimateForm,
    MarkMeteringSystemAsInactiveForm,
    MarkMeteringSystemAsActiveForm,
    RemoveMeteringSystem,
    UpdateCustomerReferenceForm,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const { result, loading, error } = useQuery(
      gql`
        query getLocation($id: Int!) {
          getLocation(id: $id) {
            id
            address
            postalCode
            city
            accountNumber
            countryCode
            lastReadingAt
            locationGroupId
            onlineSince
            annualProductionEstimateWh
            sunsureGuaranteeRatio
            sunsureDurationYears
            customerReference
            meteringSystems {
              id
              id
              alert {
                category
                message
              }
              maxCumulativeEnergyWh
              brand
              firstReadingAt
              installedBySungevity
              lastReadingAt
              hardwareIdentifier {
                serialNumber
                logicalDeviceName
              }
              serviceAssignedIdentifier {
                plantId
                siteId
                countryCode
              }
              details {
                simplMeterType
                communicationStatus {
                  verification {
                    performedOn
                    result
                    success
                  }
                  connection {
                    performedOn
                    result
                    success
                  }
                  production {
                    performedOn
                    result
                    success
                  }
                }
              }
              status
              inactive {
                since
                setBy
                type
              }
            }
            auditLog {
              completedByEmail
              completedAt
              ... on MeteringSystemAuditLog {
                toLocation {
                  id
                  address
                }
                fromLocation {
                  id
                  address
                }
                meteringSystem {
                  id
                  brand
                  hardwareIdentifier {
                    serialNumber
                    logicalDeviceName
                  }
                  serviceAssignedIdentifier {
                    plantId
                    siteId
                    countryCode
                  }
                }
              }
            }
            locationGroup {
              friendlyName
            }
            ownershipHistory {
              accountNumber
              ownershipStartedAt
            }
          }
        }
      `,
      () => ({
        id: route.params.id,
      })
    )

    const moveMeteringSystem = ref(false)
    function redirectToNextLocation(locationId) {
      moveMeteringSystem.value = false
      router.push({
        name: 'location-details',
        params: {
          id: locationId,
        },
      })
    }

    const location = computed(() => result.value && result.value.getLocation)

    const tabs = computed(() => [
      {
        name: 'Basic information',
        href: '#info',
        icon: User,
        current: !route.hash || route.hash === '#info',
      },
      {
        name: 'Production graph',
        href: '#production-graph',
        icon: User,
        current: route.hash === '#production-graph',
      },
      {
        name: 'Sunsure anniversaries',
        href: '#sunsure',
        icon: User,
        current: route.hash === '#sunsure',
      },
      {
        name: 'Calculate missed production',
        href: '#calculator',
        icon: User,
        current: route.hash === '#calculator',
      },
      {
        name: 'Production report',
        href: '#production',
        icon: User,
        current: route.hash === '#production',
      },
    ])

    function openMoveMeteringSystemDialog(ms) {
      moveMeteringSystem.value = ms
    }

    const removeMeteringSystem = ref(false)

    function openRemoveMeteringSystem(ms) {
      removeMeteringSystem.value = ms
    }

    const markMeteringSystemAsInactive = ref(false)

    function openMarkMeteringSystemAsInactiveDialog(ms) {
      markMeteringSystemAsInactive.value = ms
    }

    const markMeteringSystemAsActive = ref(false)

    function openMarkMeteringSystemAsActiveDialog(ms) {
      markMeteringSystemAsActive.value = ms
    }

    const openMeteringSystemAuditLog = ref(false)

    router.beforeEach(() => {
      openMeteringSystemAuditLog.value = false
    })

    return {
      currentTab: computed(() => route.hash),
      tabs,
      formatWh,
      formatDate,
      loading,
      error,
      location,
      addOwnerDialog: ref(false),
      addMeteringSystemDialog: ref(false),
      showMissedProductionCalculator: ref(false),
      moveMeteringSystem,
      redirectToNextLocation,
      route,
      hasSubRoute: computed(() => route.name === 'metering-system-details'),
      openMoveMeteringSystemDialog,
      openMarkMeteringSystemAsInactiveDialog,
      openMarkMeteringSystemAsActiveDialog,
      openMeteringSystemAuditLog,
      updateAnnualProductionEstimateDialog: ref(false),
      markMeteringSystemAsInactive,
      markMeteringSystemAsActive,
      removeMeteringSystem,
      openRemoveMeteringSystem,
      updateCustomerReferenceDialog: ref(false),
    }
  },
}
</script>

<style lang="postcss" scoped>
thead {
  @apply bg-gray-100 uppercase text-xs text-gray-500;
}
table {
  @apply table-auto w-full border border-gray-200;
}
.small-green-button {
  @apply text-xs rounded-md bg-green-200 text-green-800 py-2 px-3 hover:bg-green-300;
}
.small-blue-button {
  @apply text-xs rounded-md bg-blue-200 text-blue-800 py-2 px-3 hover:bg-blue-300;
}
</style>
