<template>
  <dialog-layout :title="`Status definitions`" width="2xl">
    <template v-slot:content>
      <table class="min-w-full divide-y divide-gray-300 block">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Status
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Definition
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr>
            <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Functioning</td>
            <td class="px-3 py-4 text-sm text-gray-500">
              There has been at least one reading from the metering systems throughout the past 120
              hours (5 days) and the total produced electricity per metering system in this
              timeframe is above 150 Wh.
            </td>
          </tr>
          <tr class="bg-gray-50">
            <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              Never connected
            </td>
            <td class="px-3 py-4 text-sm text-gray-500">
              There hasn't been a reading from this metering system since the system has been
              delivered by the installation partner (as defined by the System Online date in Sugar).
            </td>
          </tr>
          <tr>
            <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              Recently disconnected
            </td>
            <td class="px-3 py-4 text-sm text-gray-500">
              No readings from this metering system for at least the past 120 hours (5 days).
            </td>
          </tr>
          <tr class="bg-gray-50">
            <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              Zero production measured from the beginning
            </td>
            <td class="px-3 py-4 text-sm text-gray-500">
              The metering system is connected, but the readings consistently show only 0 daily
              production since the system has been delivered by the installation partner (as defined
              by the System Online date).
            </td>
          </tr>
          <tr>
            <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              Zero production measured recently
            </td>
            <td class="px-3 py-4 text-sm text-gray-500">
              The metering system is connected, but the readings of at least the past 120 hours (5
              days) show 0 production.
            </td>
          </tr>
          <tr class="bg-gray-50">
            <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              Faulty production measured recently
            </td>
            <td class="px-3 py-4 text-sm text-gray-500">
              The metering system is connected, but the total production in the past 120 hours (5
              days) is lower than 150 Wh. This is highly likely be a reversed wiring case.
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-slot:buttons>
      <button class="medium-blue-button" @click="$emit('close')">Ok</button>
    </template>
  </dialog-layout>
</template>

<script>
import DialogLayout from '@/components/utils/DialogLayout'

export default {
  name: 'StatusInfo',
  components: { DialogLayout },
  emits: ['close'],
}
</script>
