<template>
  <div>
    <MeteringSystemStatusDialog
      v-if="communicationStatusDialog !== null"
      :meteringSystem="meteringSystems[communicationStatusDialog]"
      @close="communicationStatusDialog = null"
    />
    <table v-if="meteringSystems.length">
      <thead>
        <tr class="text-left">
          <th class="py-2">&nbsp;</th>
          <template v-if="!compact">
            <th>Total energy</th>
            <th class="px-3">First reading</th>
            <th class="px-3">Last reading</th>
          </template>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(ms, index) in meteringSystems" :key="index">
          <tr class="border-t">
            <td class="px-3 py-2">
              <div class="flex flex-row">
                <div class="flex flex-row cursor-pointer mr-1">
                  <span
                    :class="`rounded-full w-6 ${pickMeterStatusColor(ms)}`"
                    :title="`${ms.status}}`"
                  ></span>
                  <div
                    :title="
                      `PV system ${ms.installedBySungevity ? '' : 'not'} installed by Sungevity`
                    "
                    class="ml-1"
                  >
                    <sun
                      :class="`w-6 ${ms.installedBySungevity ? 'bg-yellow-100' : 'bg-red-200'}`"
                    />
                  </div>
                </div>

                <div>
                  {{
                    `${ms.brand} ${
                      ms.details?.simplMeterType ? ` - ${ms.details.simplMeterType}` : ''
                    }`
                  }}
                </div>

                <div
                  class="flex flex-row cursor-pointer"
                  v-if="ms.details?.communicationStatus && !ms.inactive"
                  @click="communicationStatusDialog = index"
                >
                  <div
                    :class="
                      `border b-2 border-r-0 rounded-l-md ml-1 p-0.5 ${statusClass(
                        ms.details?.communicationStatus.connection
                      )}`
                    "
                  >
                    <question-mark-circle
                      class="w-4"
                      v-if="!ms.details?.communicationStatus.connection"
                    />
                    <check-icon
                      class="w-4"
                      v-else-if="ms.details?.communicationStatus.connection.success"
                    />
                    <cross-icon class="w-4" v-else />
                  </div>

                  <div
                    v-if="ms.details?.simplMeterType === 'sungate'"
                    :class="
                      `border b-2 rounded-r-md p-0.5 ${statusClass(
                        ms.details?.communicationStatus.verification
                      )}`
                    "
                  >
                    <question-mark-circle
                      class="w-4"
                      v-if="!ms.details?.communicationStatus.verification"
                    />
                    <check-icon
                      class="w-4"
                      v-else-if="ms.details?.communicationStatus.verification.success"
                    />
                    <cross-icon class="w-4" v-else />
                  </div>

                  <div
                    v-if="ms.details?.simplMeterType === 'e350'"
                    :class="
                      `border b-2 rounded-r-md p-0.5 ${statusClass(
                        ms.details?.communicationStatus.production
                      )}`
                    "
                  >
                    <question-mark-circle
                      class="w-4"
                      v-if="!ms.details?.communicationStatus.production"
                    />
                    <check-icon
                      class="w-4"
                      v-else-if="ms.details?.communicationStatus.production.success"
                    />
                    <cross-icon class="w-4" v-else />
                  </div>
                </div>
              </div>
              <div class="text-sm text-gray-400">
                {{
                  ms.hardwareIdentifier?.logicalDeviceName ||
                    ms.serviceAssignedIdentifier?.plantId ||
                    ms.serviceAssignedIdentifier?.siteId
                }}
              </div>
            </td>
            <template v-if="!compact">
              <td>
                {{ formatKwh(ms.maxCumulativeEnergyWh, 0) }}
              </td>
              <td class="px-3 py-2">
                {{ formatDate(ms.firstReadingAt) }}
              </td>
              <td class="px-3 py-2" :title="formatDate(ms.lastReadingAt)">
                {{ formatDateRelative(ms.lastReadingAt) }}
              </td>
            </template>
            <td>
              <div class="flex items-center" v-if="!ms.inactive">
                <button
                  class="small-blue-button"
                  title="Move metering system to different location"
                  @click="moveMeteringSystemDialog(ms)"
                >
                  Move
                </button>

                <button
                  class="small-blue-button ml-2 whitespace-nowrap"
                  title="Mark metering system as inactive"
                  @click="markMeteringSystemAsInactive(ms)"
                >
                  Mark inactive
                </button>

                <button
                  class="small-blue-button ml-2 whitespace-nowrap"
                  title="remove metering system"
                  @click="removeMeteringSystem(ms)"
                >
                  Remove
                </button>

                <router-link
                  v-if="ms.brand === 'Simpl'"
                  :to="{ name: 'metering-system-details', params: { meteringSystemId: ms.id } }"
                  tag="button"
                  class="small-blue-button icon-button ml-2"
                >
                  <search-icon></search-icon>
                </router-link>
              </div>
              <div class="flex items-center" v-else>
                <button
                  class="small-blue-button"
                  title="Mark metering system as active"
                  @click="markMeteringSystemAsActive(ms)"
                >
                  Mark active
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="ms.alert && !ms.inactive">
            <td colspan="4" class="px-3 py-2 text-red-400 text-xs">
              {{ ms.alert.category + ': ' + ms.alert.message }}
            </td>
          </tr>
          <tr v-else-if="ms.inactive">
            <td
              colspan="12"
              class="px-3 pb-2 text-sm"
              :title="`Set to inactive by: ${ms.inactive.setBy}`"
            >
              <span class="inactive">
                {{
                  `Meter/inverter inactive (${mapInactiveMeterType(
                    ms.inactive.type
                  )}) since: ${formatDate(ms.inactive.since)}`
                }}
              </span>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-else>There are no metering systems connected to this location.</div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import SearchIcon from 'heroicons/vue/outline/Search'
import CheckIcon from 'heroicons/vue/outline/Check'
import CrossIcon from 'heroicons/vue/outline/X'
import { QuestionMarkCircle, Sun } from 'heroicons/vue/outline/'
import { ref } from 'vue'
import MeteringSystemStatusDialog from '@/components/MeteringSystemStatusDialog'

export default {
  props: [
    'meteringSystems',
    'moveMeteringSystemDialog',
    'markMeteringSystemAsInactive',
    'markMeteringSystemAsActive',
    'compact',
    'removeMeteringSystem',
  ],
  components: {
    SearchIcon,
    CheckIcon,
    CrossIcon,
    QuestionMarkCircle,
    MeteringSystemStatusDialog,
    Sun,
  },
  setup() {
    function formatDateRelative(d) {
      return d && DateTime.fromISO(d).toRelative()
    }
    function formatKwh(wh, digits) {
      return (
        wh &&
        `${Intl.NumberFormat('nl-NL', { maximumFractionDigits: digits }).format(wh / 1000)} kWh`
      )
    }
    function formatDate(d) {
      return d && DateTime.fromISO(d).toLocaleString(DateTime.DATE_SHORT)
    }
    function statusClass(communicationStatusDetails) {
      if (!communicationStatusDetails) return 'unknown-status'
      if (communicationStatusDetails.success) return 'success-status'
      return 'failed-status'
    }

    function mapInactiveMeterType(type) {
      return {
        forbidden: 'No permission from owner',
        unreachable: 'Owner is not reachable',
        replaced: 'Meter is replaced',
      }[type]
    }

    function pickMeterStatusColor(meter) {
      if (meter.inactive) return 'bg-yellow-200'
      if (meter.alert) return 'bg-red-100'
      else return 'bg-green-100'
    }

    return {
      pickMeterStatusColor,
      mapInactiveMeterType,
      formatDateRelative,
      formatKwh,
      formatDate,
      communicationStatusDialog: ref(null),
      statusClass,
    }
  },
}
</script>

<style lang="postcss" scoped>
thead {
  @apply bg-gray-100 uppercase text-xs text-gray-500;
}
table {
  @apply table-auto w-full border border-gray-200;
}
.small-blue-button {
  @apply text-xs rounded-md bg-blue-200 text-blue-800 py-2 px-3 hover:bg-blue-300;
}
.small-blue-button svg {
  @apply h-4 w-4 text-blue-800;
}
.medium-blue-button {
  @apply text-sm rounded-md bg-blue-200 text-blue-800 py-2 px-3 hover:bg-blue-300;
}
.success-status {
  @apply bg-green-100;
}
.failed-status {
  @apply bg-red-100;
}
.unknown-status {
  @apply bg-yellow-100;
}

.inactive {
  @apply bg-yellow-200;
}
</style>
