<template>
  <dialog-layout title="Mark metering system as inactive" width="2xl">
    <template v-slot:content>
      <div class="mb-4 flex justify-between">
        <p>
          Why should this meter be marked as inactive?
        </p>
        <select
          v-model="inactiveMeteringSystemType"
          name="inactiveMeteringSystemType"
          class="rounded-md border-gray-400 ml-8 mr-8"
        >
          <option value="replaced">Meter is replaced</option>
          <option value="unreachable">Owner is unreachable</option>
          <option value="forbidden">No permission from owner</option>
        </select>
      </div>

      <div class="mb-4 h-40 flex justify-between" v-if="inactiveMeteringSystemType">
        <p>
          Since when is this meter/inverter inactive?
        </p>
        <date-picker
          :upperLimit="upperLimit"
          v-model="inactiveSince"
          class="rounded-md border-gray-400 ml-8 mr-8"
        />
      </div>
      <div>
        <p
          class="my-5 text-yellow-600"
          v-if="meteringSystem.brand === 'Omnik' || meteringSystem.brand === 'Solis'"
        >
          Omnik/Solis: Due to data quality issues you can only select the 1st day of the month for
          Omnik or Solis. Please choose the 1st of the month best suited to the situation. E.g The
          inverter/meter went inactive on the 16 of may. If you want to include the data from may
          select the 1st of June as the inactive since date.
        </p>
        <p class="mt-5">
          Please make sure you select the right inverter/meter and date. You can not undo this
          change.
        </p>
      </div>
      <div class="mt-5" v-if="error">
        <p class="text-red-600">{{ error }}</p>
      </div>
    </template>

    <template v-slot:buttons>
      <button
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        @click="onSubmit"
      >
        <Spinner
          class="w-6 h-6 border-4 border-gray-500 rounded-full loader"
          v-if="loading"
        ></Spinner>
        Confirm
      </button>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        @click="$emit('close')"
      >
        Cancel
      </button>
    </template>
  </dialog-layout>
</template>

<script>
import { computed, ref } from 'vue'
import gql from 'graphql-tag'
import { useMutation } from '@vue/apollo-composable'
import DialogLayout from '@/components/utils/DialogLayout'
import Spinner from '@/components/utils/Spinner'
import DatePicker from 'vue3-datepicker'
import { DateTime } from 'luxon'

export default {
  components: {
    DialogLayout,
    Spinner,
    DatePicker,
  },
  emits: ['success', 'close'],
  props: {
    meteringSystem: Object,
    locationId: Number,
  },
  setup(props, { emit }) {
    const inactiveSince = ref()
    const inactiveMeteringSystemType = ref('')

    const { mutate, loading, error } = useMutation(gql`
      mutation DeactivateMeteringSystem(
        $meteringSystemId: Int!
        $brand: Brand!
        $since: AWSDate!
        $type: InactiveMeteringSystemType!
      ) {
        deactivateMeteringSystem(
          meteringSystemId: $meteringSystemId
          brand: $brand
          since: $since
          type: $type
        ) {
          id
          brand
          status
          inactive {
            since
            setBy
            type
          }
        }
      }
    `)

    async function onSubmit() {
      if (!inactiveSince.value || !inactiveMeteringSystemType.value)
        return alert('Please select an inactive type and since date ')

      const { brand } = props.meteringSystem
      const selectedDate = DateTime.fromJSDate(inactiveSince.value)
      if (selectedDate.day !== 1 && (brand === 'Omnik' || brand === 'Solis')) {
        alert('For Omnik/Solis inverters you can only select the 1st day of the month')
        return
      }

      const since = selectedDate.toISODate()
      await mutate(
        {
          meteringSystemId: props.meteringSystem.id,
          brand: props.meteringSystem.brand,
          since,
          type: inactiveMeteringSystemType.value,
        },
        {
          update: (cache, { data: { deactivateMeteringSystem: meteringSystem } }) => {
            cache.writeFragment({
              id: `MeteringSystem:{"id":${meteringSystem.id},"brand":"${meteringSystem.brand}"}`,
              fragment: gql`
                fragment UpdateInactiveMeteringSystem on MeteringSystem {
                  status
                  inactive {
                    since
                    setBy
                    type
                  }
                }
              `,
              data: meteringSystem,
            })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deactivateMeteringSystem: {
              __typename: 'MeteringSystem',
              ...props.meteringSystem,
              status: 'Inactive',
              inactive: {
                since,
                setBy: 'Loading',
                type: inactiveMeteringSystemType.value,
              },
            },
          },
        }
      )
      emit('close')
    }

    return {
      upperLimit: new Date(),
      inactiveSince,
      inactiveMeteringSystemType,
      loading,
      error: computed(() => error.value && error.value.message),
      onSubmit,
    }
  },
}
</script>

<style lang="postcss" scoped>
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>
