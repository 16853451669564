<template>
  <div>
    <div class="flex mt-6 text-red-600" v-if="!location.annualProductionEstimateWh">
      Annual production estimate is missing for this location, so you can't calulate missing production. 
    </div>
    <div v-else>
      <div class="flex gap-4 my-5">
        <div>
          <label for="intervalStartAt">Start date</label>
          <date-picker 
            v-model="intervalStartAt" 
            id="intervalStartAt" 
            class="rounded-md border-gray-400"
          />
        </div>
        <div>
          <label for="intervalEndAt">End date</label>
          <date-picker 
            v-model="intervalEndAt" 
            id="intervalEndAt" 
            class="rounded-md border-gray-400"
          />
        </div>
      </div>
      
      <div class="my-16" v-if="!months">
        This tool will calculate the missed production based on the annual production estimate. This can 
        be used to correct Sunsure-calculations when a system was malfunctioning.
      </div>

      <div v-else>
        <div class="mb-3 leading-7">
          <p>Total expected production: {{ formatWh(totalExpectedProduction, 1) }}</p>
          <p>Total guaranteed production: {{ formatWh(totalGuaranteedProduction, 1) }}</p>
        </div>

        <div class="text-gray-400 text-xs mb-5">
          <template v-if="location.locationGroup">
            This is a calculation for a B2B location, so this does not include yearly panel degradation.
          </template>
          <template v-else>
            This is a calculation for a B2C location, so it includes a yearly 0,5% panel degradation in the guaranteed production.
          </template>
        </div>
        

        <table>
          <thead>
            <th>Month</th>
            <th>Part of month</th>
            <th>Production percentage</th>
            <th>Expected production</th>
            <th>Guaranteed production</th>
          </thead>
          <tbody>
            <tr v-for="row in months" :key="row.date">
              <td>{{ formatMonth(row.date) }}</td>
              <td>{{ `${row.partOfMonth.days} days / ${formatPercentage(row.partOfMonth.ratio, 1)}` }}</td>
              <td>{{ formatPercentage(row.expectedProductionRatio, 1) }}</td>
              <td>{{ formatWh(row.expectedProduction, 1) }}</td>
              <td>{{ formatPercentage(row.guaranteedProductionRatio, 2) }} = {{ formatWh(row.guaranteedProduction, 1) }}</td>
            </tr>

          </tbody>
        </table>
        
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue3-datepicker'
import { computed, ref } from 'vue'
import { DateTime } from 'luxon'
import { formatWh, sum, monthRatio, formatPercentage } from '@/utils'

function generateMonthsInInterval(start, end) {
  const all = [start]
  while (all[all.length-1].endOf('month') < end) {
    const next = all[all.length-1].startOf('month').plus({ month: 1 })
    all.push(next)
  }

  return all
}

export default {
  components: {
    DatePicker,
  },
  props: ['location'],
  setup(props) {
    const intervalStartAt = ref()
    const intervalEndAt = ref()

    const months = computed(() => {
      if (!intervalStartAt.value || !intervalEndAt.value) return null

      const intervalStartDate = DateTime.fromJSDate(intervalStartAt.value).startOf('day')
      const intervalEndDate = DateTime.fromJSDate(intervalEndAt.value).endOf('day')
      const allMonths = generateMonthsInInterval(intervalStartDate, intervalEndDate)

      return allMonths.map((date, index, list) => {
        const next = list[index+1] || intervalEndDate
        const days = Math.round(next.diff(date, 'days').toObject().days)
        const partOfMonthRatio = days/date.daysInMonth

        // B2C: panel degradation
        // B2B: no panel degradation
        const degradation = location.locationGroup ? 0 : .005
        const yearsAfterInstallation = Math.floor(Math.max(0, date.startOf('month').diff(
          DateTime.fromISO(props.location.onlineSince), 
          'years'
        ).toObject().years))

        const guaranteedProductionRatio = props.location.sunsureGuaranteeRatio * Math.pow(1-degradation, yearsAfterInstallation)
        const expectedProduction = monthRatio[date.month]*partOfMonthRatio*props.location.annualProductionEstimateWh

        return {
          date,
          partOfMonth: {
            days,
            ratio: partOfMonthRatio
          },
          expectedProductionRatio: monthRatio[date.month],
          expectedProduction: (monthRatio[date.month]*partOfMonthRatio*props.location.annualProductionEstimateWh),
          guaranteedProduction: expectedProduction * guaranteedProductionRatio,
          guaranteedProductionRatio,
          yearsAfterInstallation,
        }
      })
    })

    function formatMonth(d) {
      return d && d.toLocaleString({ month: 'long', year: 'numeric' })
    }

    const totalExpectedProduction = computed(() => months.value && sum(months.value.map(m => m.expectedProduction)))
    const totalGuaranteedProduction = computed(() => months.value && sum(months.value.map(m => m.guaranteedProduction)))

    return {
      intervalEndAt,
      intervalStartAt,
      formatMonth,
      formatWh,
      formatPercentage,
      months,
      totalExpectedProduction,
      totalGuaranteedProduction,
    }
  }
}
</script>

<style lang="postcss" scoped>
label {
  @apply text-gray-500 text-xs uppercase
}
thead {
  @apply bg-gray-100 uppercase text-xs text-gray-500;
}
th {
  @apply p-2 text-left;
}
tr {
  @apply hover:bg-blue-50
}
td {
  @apply border-b p-2
}
table {
  @apply table-auto w-full border border-gray-200;
}
</style>
