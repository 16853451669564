<template>
  <dialog-layout title="Add Location Group">
    <template v-slot:content>
      <div class="text-xs mb-5">All fields are required, except those marked as optional.</div>

      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
          <label for="friendlyName" class="block text-sm font-medium text-gray-700">
            Partner name
          </label>
          <input
            type="text"
            maxlength="128"
            name="friendlyName"
            v-model.trim="vv.friendlyName.$model"
            id="friendlyName"
          />
          <span class="text-red-900 text-sm" v-if="vv.$dirty && vv.friendlyName.$errors.length">
            {{ vv.friendlyName.$errors[0].$message }}
          </span>
        </div>
        <div style="display:none;" class="col-span-3 grid grid-cols-6 gap-1">
          <div class="col-span-5">
            <label for="autoFillGroup" class="block text-sm font-medium text-gray-700">
              Autocomplete from CRM
            </label>
          </div>
          <div class="col-span-1">
            <input
              v-model="shouldAutoFillGroup"
              id="autoFillGroup"
              name="autoFillGroup"
              type="checkbox"
              class="h-4 w-4 border-gray-300 rounded mx-2"
            />
          </div>
          <div class="col-span-6">
            <Spinner
              class="border-blue-500 w-6 h-6 border-4 mx-auto mt-5"
              v-if="partnerLoading"
            ></Spinner>
            <div v-else-if="partnerError" class="bg-red-200 p-2 rounded text-sm">
              {{ partnerError }}
            </div>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
          <label for="odooTrusteeId" class="block text-sm font-medium text-gray-700">
            Odoo Trustee ID
          </label>
          <input
            title="Odoo Trustee ID"
            type="number"
            name="odooTrusteeId"
            id="odooTrusteeId"
            v-model="vv.odooTrusteeId.$model"
          />
          <span class="text-red-900 text-sm" v-if="vv.$dirty && vv.odooTrusteeId.$errors.length">
            {{ vv.odooTrusteeId.$errors[0].$message }}
          </span>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        @click="onSubmit"
      >
        <Spinner
          class="w-6 h-6 border-4 border-gray-500 rounded-full loader"
          v-if="isSubmitting"
        ></Spinner>
        Confirm
      </button>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        @click="$emit('close')"
      >
        Cancel
      </button>
      <span class="text-red-900 text-sm mr-5" v-if="createGroupError">
        {{ createGroupError.message }}
      </span>
    </template>
  </dialog-layout>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, minLength, maxLength } from '@vuelidate/validators'
import DialogLayout from '@/components/utils/DialogLayout'
import Spinner from '@/components/utils/Spinner'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import gql from 'graphql-tag'

export default {
  components: {
    DialogLayout,
    Spinner,
  },
  emits: ['close', 'success'],
  setup(_props, { emit }) {
    const store = useStore()
    const shouldAutoFillGroup = ref(false)
    const odooTrusteeId = ref('')
    const friendlyName = ref('')

    const cognitoGroupName = computed(() => {
      return 'b2b:' + friendlyName.value.replace(/[^\w+=,.@-]+/g, '').toLowerCase()
    })

    const { result: locationGroupsResult } = useQuery(gql`
      query GetLocationGroups {
        getAbstractLocationGroups {
          id
          cognitoGroupName
          odooTrusteeId
          friendlyName
        }
      }
    `)
    const locationGroups = useResult(locationGroupsResult)

    const vv = useVuelidate(
      {
        odooTrusteeId: {
          required,
          validOdooTrusteeId: helpers.withMessage(
            'Odoo Trustee ID must be an integer between 1 and 2^31',
            (v) => /^\d+$/.test(v) && parseInt(v) > 0 && parseInt(v) < Math.pow(2, 31)
          ),
        },
        friendlyName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(128),
        },
      },
      {
        odooTrusteeId,
        friendlyName,
      }
    )

    const getPartnerParams = ref({
      credentials: null,
      databaseName: null,
      cognitoGroupName: cognitoGroupName,
      groupInfo: {
        monitoringId: cognitoGroupName,
        odooTrusteeId,
        friendlyName,
      },
    })

    const getPartnerEnabled = computed(() => {
      return (
        shouldAutoFillGroup.value &&
        !!friendlyName.value &&
        !vv.value['friendlyName'].$invalid &&
        !!odooTrusteeId.value &&
        !vv.value['odooTrusteeId'].$invalid
      )
    })

    const { result: partnerResult, loading: partnerLoading } = useQuery(
      gql`
        query GetPartner($getPartnerParams: GetPartnerParams!) {
          getPartner(getPartnerParams: $getPartnerParams) {
            cognitoGroupName
            odooTrusteeId
            friendlyName
          }
        }
      `,
      { getPartnerParams },
      {
        enabled: getPartnerEnabled,
        debounce: 1000,
      }
    )

    const partner = computed(() => partnerResult.value && partnerResult.value.getPartner)
    watch(partner, (info) => {
      if (info && info[0]) {
        const first = info[0]
        cognitoGroupName.value = first.cognitoGroupName
        odooTrusteeId.value = first.odooTrusteeId
        friendlyName.value = first.friendlyName
      }
    })

    const partnerError = computed(() => {
      if (
        shouldAutoFillGroup.value &&
        vv.value.odooTrusteeId.$model &&
        !partnerLoading.value &&
        !partner.value
      ) {
        return 'Partner not found in CRM. Please check contact info!'
      }
    })

    const createGroup = useMutation(gql`
      mutation AddLocationGroup($locationGroup: AddLocationGroupInput!) {
        addLocationGroup(locationGroup: $locationGroup) {
          message
          success
          data {
            id
            cognitoGroupName
            odooTrusteeId
            friendlyName
          }
        }
      }
    `)

    async function onSubmit() {
      const isFormCorrect = await vv.value.$validate()

      if (!isFormCorrect) throw 'validation error'

      const addLocationGroupResponse = await createGroup.mutate({
        locationGroup: {
          cognitoGroupName: cognitoGroupName.value,
          odooTrusteeId: odooTrusteeId.value,
          friendlyName: friendlyName.value,
        },
      })

      if (
        addLocationGroupResponse.data &&
        addLocationGroupResponse.data.addLocationGroup &&
        addLocationGroupResponse.data.addLocationGroup.success
      ) {
        await store.dispatch('fetchCognitoGroups')
        emit('success', addLocationGroupResponse.data.addLocationGroup.data.cognitoGroupName)
        emit('close')
      }
    }

    return {
      vv,
      onSubmit,
      isSubmitting: createGroup.loading,
      createGroupError: createGroup.error,
      partnerLoading: computed(() => partnerLoading.value),
      partnerError,
      locationGroups,
      shouldAutoFillGroup,
    }
  },
}
</script>

<style lang="postcss" scoped>
input[type='text'],
input[type='number'],
input[type='date'],
select {
  @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}
label {
  cursor: pointer;
}
</style>
