<template>
  <div class="app text-gray-900 leading-tight bg-gray-50">
    <Dialog ref="modal" v-bind="dialog" v-if="dialog.active" />
    <div class="flex flex-col min-h-screen">
      <app-header></app-header>
      <router-view v-slot="{ Component }">
        <keep-alive include="LocationsList">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/main/AppHeader'
import Dialog from '@/components/utils/Dialog'
import { ref, reactive, provide, nextTick } from 'vue'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { onError } from '@apollo/client/link/error'
import { logErrorMessages } from '@vue/apollo-util'
import { Auth } from 'aws-amplify'

// HTTP connection to the API
const customFetch = async (uri, options) => {
  const {
    idToken: { jwtToken },
  } = await Auth.currentSession()
  options.headers.Authorization = jwtToken
  return fetch(uri, options)
}
const httpLink = createHttpLink({
  uri: process.env.VUE_APP_MONITORING_PLATFORM_API_BASE_URL,
  fetch: customFetch,
})
const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getLocation(_, { args, toReference }) {
            return toReference({
              __typename: 'Location',
              id: args.id,
            })
          },
        },
      },
      SunsureBirthday: {
        keyFields: ['birthday', 'location', ['id']],
      },
      MeteringSystem: {
        keyFields: ['id', 'brand'],
        fields: {
          alert: {
            merge: false,
          },
          details: {
            merge: true,
          },
        },
      },
      MeteringSystemDetails: {
        fields: {
          communicationStatus: {
            merge: true,
          },
        },
      },
      // Needed to make the removal of a meter possible without warning in console
      Location: {
        fields: {
          meteringSystems: {
            merge: false,
          },
        },
      },
    },
  }),
})
// log nice GraphQL errors to console.log for dev usage
onError((error) => {
  if (process.env.NODE_ENV !== 'production') {
    logErrorMessages(error)
  }
})

export default {
  name: 'app',
  components: {
    AppHeader,
    Dialog,
  },
  setup() {
    /* 
    Descendant component can now 'inject' openDialog
    and use it like:
    async deleteUser() {
      const confirmed = await openDialog({
        header: 'do you want to delete this user'
        body: 'etc',
        action: 'Delete'
      })
      if (!confirmed) return
      //continue
    */
    const inactive = {
      active: false,
      header: null,
      body: null,
      action: null,
    }
    const modal = ref(null)
    const dialog = reactive(inactive)
    const setDialog = ({ active, header, body, action }) => {
      dialog.active = active
      dialog.header = header
      dialog.body = body
      dialog.action = action
    }
    const openDialog = async (dialogOpts) => {
      setDialog({ active: true, ...dialogOpts })
      await nextTick()
      return new Promise((resolve) => {
        modal.value.$watch('confirmed', (v) => {
          setDialog({ active: false })
          resolve(v)
        })
      })
    }
    provide('openDialog', openDialog)
    provide(DefaultApolloClient, apolloClient)

    return { dialog, modal }
  },
}
</script>

<style lang="postcss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
