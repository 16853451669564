import routes from '@/routes'
import { createWebHistory, createRouter } from 'vue-router'
import { setCredentials } from '@/aws'
import Auth from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'

const defaultRedirectAfterLogin = { name: 'accounts-list' }

export default function(store) {
  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  router.beforeEach(async (to, _from, next) => {
    try {
      const session = await Auth.currentSession()
      const groups = session.idToken.payload['cognito:groups']
      if (!store.getters.authenticated) {
        store.commit('setAuthenticatedInfo', {
          groups,
          email: session.idToken.payload.email,
        })
        const creds = await Auth.currentCredentials()
        setCredentials(creds)
      }

      if (
        to.name !== 'access-denied' &&
        to.meta.needsGroup &&
        !groups.includes(to.meta.needsGroup)
      ) {
        next({ name: 'access-denied' })
      } else if (to.name === 'login') {
        next(store.state.auth.afterLoginRedirect || defaultRedirectAfterLogin)
      } else {
        next()
      }
    } catch (error) {
      if (store.getters.authenticated) {
        store.commit('setAuthenticatedInfo', null)
      }

      if (to.name !== 'login') {
        store.commit('setAfterLoginRedirect', to.fullPath)
        return next({
          name: 'login',
        })
      } else {
        next()
      }
    }
  })

  Hub.listen('auth', ({ payload: { event } }) => {
    if (event === 'signIn') {
      // we trigger routing after login to refresh the session
      // we should route directly to the right page,
      // otherwise we have multiple redirects
      router.push(store.state.auth.afterLoginRedirect || defaultRedirectAfterLogin)
    }
  })

  return router
}
