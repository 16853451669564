import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider'
import DynamoDB from 'aws-sdk/clients/dynamodb'
import S3 from 'aws-sdk/clients/s3'

const region = 'eu-central-1'

export const s3 = new S3({ region })
export const cognito = new CognitoIdentityServiceProvider({ region })
export const ddb = new DynamoDB({ region })

export function setCredentials(credentials) {
  cognito.config.credentials = credentials
  ddb.config.credentials = credentials
  s3.config.credentials = credentials
}

// This is the user pool for mySungevity customers
export const userPoolId = process.env.VUE_APP_CAST4ALL_USERS_POOL_ID
// This is the user pool for admin users
export const adminUserPoolId = process.env.VUE_APP_USER_POOL_ID
// this is the Cognito group name of the Cognito group that admins must have to access the B2B pages
export const adminB2BGroupName = 'b2bmanager'
// this is the Cognito group name of the Cognito group that admins must have to access the Sunsure pages
export const adminSunsureGroupName = 'sunsure-manager'
// this is the Cognito group name of the Cognito group that admins must have to access the Lead Form pages
export const adminLeadFormGroupName = 'lead-form-manager'

export function toDDBFormat(obj) {
  return Object.entries(obj).reduce((acc, [k, v]) => {
    acc[k] = { S: v }
    return acc
  }, {})
}
