<template>
  <div class="flex bg-gray-50 border-t border-gray-200 flex-grow">
    <div class="flex flex-shrink-0">
      <div class="flex flex-col w-100 border-r border-gray-200 bg-gray-100 px-3 py-10">
        <slot name="sidebar"></slot>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1">
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="pt-2 pb-6">
          <slot name="content"></slot>
        </div>
      </main>
    </div>
  </div>
</template>
