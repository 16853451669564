<template>
  <Modal :type="'info'" @cancel="$emit('close')">
    <template v-slot:header>
      <div v-if="definedRoutes.includes($route.name)">Usage Info:</div>
      <div v-else>
        Sorry! No usage information provided for this page. Contact IT if you are stuck
      </div>
    </template>
    <template v-slot:body>
      <!-- for each route some 'how to use' info can be provided here -->
      <div v-if="$route.name === 'metering-db-view'">
        <p>
          On this page you can manage locations and location ownership in the monitoring platform
        </p>
      </div>
    </template>
    <template v-slot:confirm><div></div></template>
    <template v-slot:reject>Close</template>
  </Modal>
</template>

<script>
import Modal from '@/components/utils/Modal'

export default {
  components: { Modal },
  data() {
    return {
      definedRoutes: ['metering-db-view'],
    }
  },
}
</script>
