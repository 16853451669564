<template>
  <button
    class="inline-flex px-5 py-2 rounded-lg font-semibold"
    :class="classList"
  >
    <svg
      v-if="loading"
      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    {{ text }}
    <hero-icon
      v-if="iconRight"
      :icon-name="iconRight"
      class="-mr-1 ml-2 mt-0 h-5 w-5"
    />
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    color: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    iconRight: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    // Yes i know i can use string interpolation
    // but this will (potentially) mean the classess
    // are purged from the production bundle
    // by postcss.
    const classList = {
      red: [
        'bg-red-200',
        'text-red-800',
        'hover:bg-red-100',
        'hover:border-2',
        'hover:border-red-800',
      ],
      yellow: [
        'bg-yellow-200',
        'text-yellow-800',
        'hover:bg-yellow-100',
        'hover:border-2',
        'hover:border-yellow-800',
      ],
      green: [
        'bg-green-200',
        'text-green-800',
        'hover:bg-green-100',
        'hover:border-2',
        'hover:border-green-800',
      ],
      blue: [
        'bg-blue-200',
        'text-blue-800',
        'hover:bg-blue-100',
        'hover:border-2',
        'hover:border-blue-800',
      ],
      transparent: ['bg-transparent', 'hover:bg-gray-200'],
    }[props.color]

    return {
      classList,
    }
  },
}
</script>
