<template>
  <div>
    <div class="p-4 sm:rounded-md bg-red-100 sm:w-1/2 lg:w-1/3 w-full mx-auto mt-4" v-if="error">
      {{ error }}
    </div>
    <div class="mt-5">
      <div class="mb-5 text-xs text-gray-400">
        The numbers in this graph for guaranteed production <strong>do not include panel degradation</strong>. Please treat these graphs as approximations and use the Sunsure tab for any the real calculations. 
      </div>
      <label for="selectedYear">Year</label>
      <div>
        <select v-model="selectedYear" id="selectedYear" class="rounded-md border-gray-400">
          <option :value="year" v-for="year in years" :key="year">{{ year }}</option>
        </select>
      </div>
    </div>
    <div :class="{ loading }" v-if="graphData">
      <div class="mt-5">
        <div>
          Production: {{ formatKwh(totalProductionKwh) }}
        </div>
        <div>
          Guaranteed production: {{ formatKwh(totalProductionGuaranteeKwh) }}
        </div>
      </div>
      <column-chart
        class="mt-5"
        :data="graphData"
        :colors="['#1D4ED8', '#9CA3AF']"
      ></column-chart>
    </div>
    <div v-else-if="loading">
      <spinner
        class="border-blue-500 w-6 h-6 border-2 mx-auto mt-5"
      ></spinner>
    </div>
  </div>
</template>

<script>
import { useQuery, useResult } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import { DateTime } from 'luxon'
import { formatKwh, range } from '@/utils'
import Spinner from '@/components/utils/Spinner'

export default {
  components: {
    Spinner,
  },
  props: ['location'],
  setup(props) {
    const selectedYear = ref(DateTime.local().year)
    const years = computed(() => {
      if (!props.location) return []

      const onlineSince = DateTime.fromISO(props.location.onlineSince).startOf('year')
      const numberOfYearsTillNow = DateTime.local().diff(onlineSince, 'years').toObject().years + 1
      return range(numberOfYearsTillNow).map(n => onlineSince.year+n)
    })

    const { result, loading, error } = useQuery(
      gql`
        query getMonthlyDeltas($filter: MonthlyDeltasFilter!) {
          getMonthlyDeltas(filter: $filter) {
            totalProductionGuarantee
            totalProductionDelta {
              kwhProduced
            }
            deltas {
              intervalStartAt
              productionDelta {
                kwhProduced
              }
              productionGuarantee
            }
          }
        }
      `,
      () => {
        const currentYear = DateTime.fromObject({ year: selectedYear.value })
        return {
          filter: {
            locationId: props.location.id,
            intervalStartAt: currentYear.toISODate(),
            intervalEndAt: currentYear.endOf('year').toISODate(),
          },
        }
      }
    )

    function formatMonthShort(d) {
      return d && DateTime.fromISO(d).toLocaleString({ month: 'long' })
    }

    const raw = useResult(result)
    const productionData = computed(() => raw.value && raw.value.deltas.map(d => [formatMonthShort(d.intervalStartAt), d.productionDelta.kwhProduced]))
    const guaranteeData = computed(() => raw.value && raw.value.deltas.map(d => [formatMonthShort(d.intervalStartAt), d.productionGuarantee]))

    const graphData = computed(() => {
      if (!productionData.value || !guaranteeData.value) return
      return [
        {
          name: 'Actual production [kWh]',
          data: productionData.value,
        },
        {
          name: 'Guaranteed production [kWh]',
          data: guaranteeData.value,
        },
      ]
    })

    const totalProductionKwh = computed(() => raw.value && raw.value.totalProductionDelta.kwhProduced)
    const totalProductionGuaranteeKwh = computed(() => raw.value && raw.value.totalProductionGuarantee)

    return {
      loading,
      error,
      selectedYear,
      graphData,
      totalProductionKwh,
      totalProductionGuaranteeKwh,
      formatKwh,
      years,
    }
  }
}
</script>

<style lang="postcss" scoped>
.loading {
  opacity: .3;
}
label {
  @apply text-gray-500 text-xs uppercase
}
</style>
