import Login from '@/pages/Login'
import AccountsList from '@/pages/AccountsList'
import LocationsList from '@/pages/LocationsList'
import LocationDetails from '@/pages/LocationDetails'
import OnboardingImages from '@/pages/OnboardingImages'
import B2BOverview from '@/pages/B2BOverview'
import B2BGroupView from '@/pages/B2BGroupView'
import SunsureOverview from '@/pages/SunsureOverview'
import PermissionList from '@/pages/PermissionList'
import AccessDenied from '@/pages/AccessDenied'
import LeadForm from '@/pages/LeadForm'
import MeteringSystemDetails from '@/components/MeteringSystemDetails'
import { adminB2BGroupName, adminSunsureGroupName, adminLeadFormGroupName } from '@/aws'

export default [
  {
    path: '',
    name: 'login',
    component: Login,
  },
  {
    path: '/access-denied',
    name: 'access-denied',
    component: AccessDenied,
  },
  {
    path: '/b2b',
    name: 'b2b-overview',
    component: B2BOverview,
    meta: { needsGroup: adminB2BGroupName },
  },
  {
    path: '/b2b/:groupName',
    name: 'b2b-group-view',
    component: B2BGroupView,
    meta: { needsGroup: adminB2BGroupName },
  },
  {
    path: '/sunsure',
    name: 'sunsure-overview',
    component: SunsureOverview,
    meta: { needsGroup: adminSunsureGroupName },
  },
  {
    path: '/lead-form',
    name: 'lead-form',
    component: LeadForm,
    meta: { needsGroup: adminLeadFormGroupName },
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: PermissionList,
  },
  {
    path: '/accounts',
    name: 'accounts-list',
    component: AccountsList,
  },
  {
    path: '/locations',
    name: 'locations-list',
    component: LocationsList,
  },
  {
    path: '/locations/:id',
    name: 'location-details',
    component: LocationDetails,
    children: [
      {
        name: 'metering-system-details',
        path: ':meteringSystemId',
        component: MeteringSystemDetails,
      },
    ],
  },
  {
    path: '/onboarding-images/:accountNumber',
    name: 'onboarding-images-view',
    component: OnboardingImages,
  },
]
