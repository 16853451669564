import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/styles/main.css'
import Amplify from '@aws-amplify/core'
import router from '@/router'
import { VuelidatePlugin } from '@vuelidate/core'
import store from '@/store'
import HeroIcon from '@/components/utils/HeroIcon'
import Button from '@/components/utils/Button'
import '@/assets/styles/main.css'
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'

const {
  VUE_APP_REDIRECT_SIGNIN: redirectSignIn,
  VUE_APP_REDIRECT_SIGNOUT: redirectSignOut,
  VUE_APP_IDENTITY_POOL_ID: identityPoolId,
  VUE_APP_USER_POOL_ID: userPoolId,
  VUE_APP_USER_POOL_WEB_CLIENT_ID: userPoolWebClientId,
  VUE_APP_OAUTH_DOMAIN: domain,
} = process.env

const profile = {
  Auth: {
    oauth: {
      domain,
      scope: ['openid', 'email', 'aws.cognito.signin.user.admin'],
      redirectSignIn,
      redirectSignOut,
      responseType: 'code',
    },
    identityPoolId,
    region: 'eu-central-1',
    userPoolId,
    userPoolWebClientId,
  },
}

Amplify.configure(profile)

createApp(App)
  .use(store)

  .use(router(store))
  .use(VuelidatePlugin)
  .use(VueChartkick)
  .component('hero-icon', HeroIcon)
  .component('sg-button', Button)
  .mount('#app')
