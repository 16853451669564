<template>
  <div class="mt-5">
    <div class="flex gap-4 my-5">
      <div>
        <label for="intervalStartAt">Start date</label>
        <date-picker 
          v-model="intervalStartAt" 
          id="intervalStartAt" 
          class="rounded-md border-gray-400"
        />
      </div>
      <div>
        <label for="intervalEndAt">End date</label>
        <date-picker 
          v-model="intervalEndAt" 
          id="intervalEndAt" 
          class="rounded-md border-gray-400"
        />
      </div>
    </div>

    <div class="p-4 sm:rounded-md bg-red-100 sm:w-1/2 lg:w-1/3 w-full mx-auto mt-4" v-if="error">
      {{ error }}
    </div>

    <div v-if="months" :class="{ loading }">
      <div v-if="months.length === 0">
        No production data available for the given interval.
      </div>
      <div v-else>
        <div class="my-3">
          Total production: {{ formatKwh(totalProductionKwh) }}
        </div>

        <div v-for="month in months" :key="month.deltas[0].intervalStartAt">
          <button class="month-row" @click="toggleOpen(month.deltas[0].intervalStartAt)">
            <div class="flex items-center flex-grow">
              <ChevronRight class="w-4 h-4 mr-1" v-if="!open.includes(month.deltas[0].intervalStartAt)" />
              <ChevronDown class="w-4 h-4 mr-1" v-else />
              <div>{{ formatMonth(month.deltas[0].intervalStartAt) }}</div>
            </div>
            <div>{{ formatKwh(month.totalProductionKwh) }}</div>
            <div :class="ratioToClass(month.expectedProductionRatio)" class="p-2">
              {{ formatPercentage(month.expectedProductionRatio) }} of expected
            </div>
          </button>
          <div v-if="open.includes(month.deltas[0].intervalStartAt)">
            <div v-for="delta in month.deltas" :key="delta.intervalStartAt" class="day-row">
              <div class="w-32">{{ formatDate(delta.intervalStartAt) }}</div>
              <div class="w-32 text-right">{{ formatKwh(delta.productionDelta.kwhProduced) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="loading">
      <spinner class="border-blue-500 w-6 h-6 border-2 mt-5"></spinner>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import DatePicker from 'vue3-datepicker'
import { DateTime } from 'luxon'
import gql from 'graphql-tag'
import { useQuery, useResult } from '@vue/apollo-composable'
import Spinner from '@/components/utils/Spinner'
import { sum, formatKwh, formatDate, monthRatio, formatPercentage, kwhToWh } from '@/utils'
import { ChevronRight, ChevronDown } from 'heroicons/vue/outline'
import { compose, curry, groupWith, map, sortBy } from 'rambda'

export default {
  components: {
    DatePicker,
    Spinner,
    ChevronRight,
    ChevronDown,
  },
  props: ['location'],
  setup(props) {
    const intervalStartAt = ref()
    const intervalEndAt = ref()
    const open = ref([])

    function toggleOpen(key) {
      if (open.value.includes(key)) {
        open.value = open.value.filter(k => k !== key)
      }
      else {
        open.value.push(key)
      }
    }

    const { result, loading, error } = useQuery(
      gql`
        query GetDeltas($locationId: Int!, $intervalStartAt: AWSDate!, $intervalEndAt: AWSDate!) {
          getDeltas(locationId: $locationId, intervalStartAt: $intervalStartAt, intervalEndAt: $intervalEndAt, unitOfTime: DAY) {
            intervalStartAt
            productionDelta {
              kwhProduced
            }
          }
        }

      `,
      () => ({
        locationId: props.location?.id,
        intervalStartAt: DateTime.fromJSDate(intervalStartAt.value).toISODate(),
        // we want to show all dates including the last date, so we add 1 day to the endAt
        intervalEndAt: DateTime.fromJSDate(intervalEndAt.value).plus({ day: 1 }).toISODate(),
      }),
      {
        enabled: computed(() => !!props.location && !!intervalStartAt.value && !!intervalEndAt.value)
      }
    )


    const deltas = useResult(result)
    const equalMonth = (d1, d2) => DateTime.fromISO(d1.intervalStartAt).hasSame(DateTime.fromISO(d2.intervalStartAt), 'month')
    // for an unknown reason, groupWith is NOT automatically curried
    const groupWithMonth = curry(groupWith)(equalMonth)
    const sortByDate = sortBy((d) => d.intervalStartAt)

    const groupAndSortDeltas = compose(
      map((deltas) => {
        const intervalStartDate = DateTime.fromJSDate(intervalStartAt.value)
        const intervalEndDate = DateTime.fromJSDate(intervalEndAt.value)

        const startOfMonth = DateTime.fromISO(deltas[0].intervalStartAt).startOf('month')
        const endOfMonth = startOfMonth.endOf('month')

        // when this month is partial, adjust the start and end of interval, otherwise use begin/end of month
        const monthIntervalStart = startOfMonth < intervalStartDate ? intervalStartDate : startOfMonth
        const monthIntervalEnd = endOfMonth > intervalEndDate ? intervalEndDate : endOfMonth

        // calculate the part of the month, full month = 100%, 15 days of a 30-day month = 50%
        const daysOfMonth = Math.round(monthIntervalEnd.diff(monthIntervalStart, 'days').toObject().days)
        const partOfMonthRatio = daysOfMonth/monthIntervalStart.daysInMonth
        
        const totalProductionKwh = sum(deltas.map(d => d.productionDelta.kwhProduced))

        // calculate the percentage of expected production, taking into account it could be a partial month
        const expectedProductionInPartOfMonth = monthRatio[monthIntervalStart.month]*partOfMonthRatio*props.location.annualProductionEstimateWh
        const expectedProductionRatio = kwhToWh(totalProductionKwh) / expectedProductionInPartOfMonth

        return {
          totalProductionKwh,
          expectedProductionRatio,
          deltas,
        }
      }),
      groupWithMonth,
      sortByDate,
    )

    const sortedMonths = computed(() => deltas.value && groupAndSortDeltas(deltas.value))

    const totalProductionKwh = computed(() => sum(sortedMonths.value.map(m => m.totalProductionKwh)))

    function formatMonth(d) {
      return d && DateTime.fromISO(d).toLocaleString({ month: 'long', year: 'numeric' })
    }

    function formatDateWrapped(d) {
      return formatDate(DateTime.fromISO(d))
    }

    function ratioToClass(r) {
      if (r < .5) return 'bg-red-100'
      else if (r < .9) return 'bg-yellow-100'
      else return 'bg-green-100'
    }

    return {
      intervalStartAt, 
      intervalEndAt,
      error,
      loading,
      months: sortedMonths,
      formatMonth,
      formatKwh,
      totalProductionKwh,
      open,
      formatDate: formatDateWrapped,
      toggleOpen,
      formatPercentage,
      ratioToClass,
    }
  }
}
</script>

<style lang="postcss" scoped>
label {
  @apply text-gray-500 text-xs uppercase
}
.loading {
  opacity: .3;
}
.month-row {
  @apply flex bg-gray-100 border-b md:mr-16 my-1 p-2 block w-full gap-4 items-center
}
.day-row {
  @apply p-3 flex
}
</style>
