<template>
  <dialog-layout :title="`Audit log for meters/inverters of ${location.address}`" width="2xl">
    <template v-slot:content>
      <table v-if="logs.length">
        <thead>
          <th>Meter/inverter</th>
          <th>Desciption</th>
          <th>Date</th>
          <th>By</th>
        </thead>
        <tbody>
          <tr v-for="log in logs" :key="log.completedAt" class="even:bg-gray-100">
            <td>
              <div>{{ log.meteringSystem.brand }}</div>
              <div class="text-sm text-gray-400">
                {{
                  log.meteringSystem.hardwareIdentifier?.logicalDeviceName ||
                    log.meteringSystem.serviceAssignedIdentifier?.plantId ||
                    log.meteringSystem.serviceAssignedIdentifier?.siteId
                }}
              </div>
            </td>
            <td>
              <div v-if="!log.fromLocation">
                Created/added to this location
              </div>
              <div v-else-if="!log.toLocation">
                Removed from this location
              </div>
              <div v-else-if="log.toLocation.id === location.id">
                Moved from
                <router-link
                  :to="{ name: 'location-details', params: { id: log.fromLocation.id } }"
                  class="location-link"
                >
                  <HomeIcon />
                  <div>{{ log.fromLocation.address }}</div>
                </router-link>
                to this location
              </div>
              <div v-else>
                Moved from this location to
                <router-link
                  :to="{ name: 'location-details', params: { id: log.toLocation.id } }"
                  class="location-link"
                >
                  <HomeIcon />
                  <div>{{ log.toLocation.address }}</div>
                </router-link>
              </div>
            </td>
            <td>
              <div :title="formatDateFull(log.completedAt)">
                {{ formatDate(log.completedAt) }}
              </div>
            </td>
            <td>
              {{ formatEmailAsName(log.completedByEmail) }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        No logs.
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        @click="$emit('close')"
      >
        Ok
      </button>
    </template>
  </dialog-layout>
</template>

<script>
import { DialogLayout } from '@/components/utils'
import { formatDate, formatDateFull } from '@/utils'
import { Home as HomeIcon } from 'heroicons/vue/solid'

export default {
  components: {
    DialogLayout,
    HomeIcon,
  },
  emits: ['close'],
  props: {
    location: Object,
  },
  setup(props) {
    function formatEmailAsName(val) {
      return val.split('@')[0] || 'system'
    }

    return {
      logs: props.location.auditLog.filter((l) => l.__typename === 'MeteringSystemAuditLog'),
      formatDate,
      formatEmailAsName,
      formatDateFull,
    }
  },
}
</script>

<style lang="postcss" scoped>
.location-link {
  @apply border-b border-blue-800 text-blue-800 inline-flex items-center;
}
.location-link svg {
  @apply w-4 h-4;
}
thead {
  @apply bg-gray-200 uppercase text-xs text-gray-600;
}
table {
  @apply table-auto w-full border border-gray-300;
}
th,
td {
  @apply p-2;
}
tbody {
}
</style>
