<template>
  <div class="flex flex-row justify-between">
    <div class="px-6 py-3 col-auto">
      <button
        v-if="page > 0"
        class="flex px-3 py-2 items-center justify-center rounded-md bg-blue-300 hover:bg-blue-400 text-white"
        @click="emit('previous')"
      >
        Previous
      </button>
    </div>
    <div v-if="page + 1 < pages" class="px-6 py-3 col-auto">
      <button
        class="flex px-3 py-2 items-center justify-center rounded-md bg-blue-300 hover:bg-blue-400 text-white"
        @click="emit('next')"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['next', 'previous'],
  props: ['page', 'pages'],
  setup(_, { emit }) {
    return {
      emit,
    }
  },
}
</script>
