import { adminB2BGroupName, adminSunsureGroupName, adminLeadFormGroupName } from '@/aws'

export default {
  state: {
    afterLoginRedirect: null,
    groups: null,
    email: null,
  },
  getters: {
    authenticated: (state) => !!state.groups,
    isB2BManager: (state) => !!state.groups && state.groups.includes(adminB2BGroupName),
    isSunsureManager: (state) => !!state.groups && state.groups.includes(adminSunsureGroupName),
    isLeadFormManager: (state) => !!state.groups && state.groups.includes(adminLeadFormGroupName),
  },
  mutations: {
    setAuthenticatedInfo(state, info) {
      if (!info) {
        state.groups = null
        state.email = null
      } else {
        state.groups = info.groups
        state.email = info.email
      }
    },
    setAfterLoginRedirect(state, val) {
      state.afterLoginRedirect = val
    },
  },
}
