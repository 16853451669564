<template>
  <div class="rounded-full loader"></div>
</template>

<script>
export default {

}
</script>

<style lang="postcss">
@keyframes loader-rotate {
  0% {
      transform: rotate(0);
  }
  100% {
      transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>>
