<template>
  <dialog-layout :title="`Remove inverter/meter from location`">
    <template v-slot:content>
      <p class="mb-4">
        This action will remove the inverter/meter from this location. Are you sure?
      </p>
      <div class="mt-5" v-if="error">
        <p class="text-red-600">{{ error }}</p>
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        @click="onSubmit"
      >
        <Spinner
          class="w-6 h-6 border-4 border-gray-500 rounded-full loader"
          v-if="loading"
        ></Spinner>
        Confirm
      </button>
      <button
        :disabled="loading"
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        @click="$emit('close')"
      >
        Cancel
      </button>
    </template>
  </dialog-layout>
</template>

<script>
import { computed } from 'vue'
import gql from 'graphql-tag'
import { useMutation } from '@vue/apollo-composable'
import DialogLayout from '@/components/utils/DialogLayout'
import Spinner from '@/components/utils/Spinner'

export default {
  components: {
    DialogLayout,
    Spinner,
  },
  emits: ['success', 'close'],
  props: {
    meteringSystem: Object,
    locationId: Number,
  },
  setup(props, { emit }) {
    const { mutate, loading, error } = useMutation(gql`
      mutation removeMeteringSystem($id: Int!, $locationId: Int!, $brand: Brand!) {
        removeMeteringSystem(id: $id, locationId: $locationId, brand: $brand) {
          id
          address
          postalCode
          city
          accountNumber
          countryCode
          lastReadingAt
          locationGroupId
          onlineSince
          annualProductionEstimateWh
          sunsureGuaranteeRatio
          sunsureDurationYears
          customerReference
          meteringSystems {
            id
            id
            alert {
              category
              message
            }
            maxCumulativeEnergyWh
            brand
            firstReadingAt
            installedBySungevity
            lastReadingAt
            hardwareIdentifier {
              serialNumber
              logicalDeviceName
            }
            serviceAssignedIdentifier {
              plantId
              siteId
              countryCode
            }
            details {
              simplMeterType
              communicationStatus {
                verification {
                  performedOn
                  result
                  success
                }
                connection {
                  performedOn
                  result
                  success
                }
                production {
                  performedOn
                  result
                  success
                }
              }
            }
            status
            inactive {
              since
              setBy
              type
            }
          }
          auditLog {
            completedByEmail
            completedAt
            ... on MeteringSystemAuditLog {
              toLocation {
                id
                address
              }
              fromLocation {
                id
                address
              }
              meteringSystem {
                id
                brand
                hardwareIdentifier {
                  serialNumber
                  logicalDeviceName
                }
                serviceAssignedIdentifier {
                  plantId
                  siteId
                  countryCode
                }
              }
            }
          }
          locationGroup {
            friendlyName
          }
          ownershipHistory {
            accountNumber
            ownershipStartedAt
          }
        }
      }
    `)

    async function onSubmit() {
      await mutate({
        id: props.meteringSystem.id,
        locationId: props.locationId,
        brand: props.meteringSystem.brand,
      })
      emit('close')
    }

    return {
      loading,
      error: computed(() => error.value && error.value.message),
      onSubmit,
    }
  },
}
</script>

<style lang="postcss" scoped>
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>
