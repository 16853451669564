import { cognito, userPoolId } from '@/aws'

export default {
  state: {
    cognitoGroups: 'not_set',
  },
  mutations: {
    setCognitoGroups(state, val) {
      state.cognitoGroups = val
    },
  },
  actions: {
    fetchCognitoGroupsIfNotSet({ dispatch, state }) {
      if (!Array.isArray(state.cognitoGroups)) {
        dispatch('fetchCognitoGroups')
      }
    },
    async fetchCognitoGroups({ commit }) {
      const groups = await cognito
        .listGroups({
          UserPoolId: userPoolId,
          Limit: 60,
        })
        .promise()

      if (groups.Groups.length > 59) alert('Alert IT: Somebody should implement pagination')

      const filteredGroups = groups.Groups.map(({ GroupName, Description }) => ({
        name: GroupName,
        description: Description,
      })).filter(({ name }) => name.startsWith('b2b:'))

      commit('setCognitoGroups', filteredGroups)
    },
  },
}
