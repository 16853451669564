<template>
  <div class="container mx-auto px-4">
    <div class="login-error-message" v-if="error">
      {{ error }}
    </div>
    <div v-else class="mt-40 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 m-auto">
      <div class="max-w-md w-full space-y-8">
        <div>
          <img class="mx-auto h-12 w-auto" src="@/assets/images/sun_orange.svg" alt="Sungevity" />
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in using Google
          </h2>
        </div>
        <div>
          <button
            @click="login"
            type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: lock-closed -->
              <svg
                class="h-5 w-5 text-white group-hover:text-yellow-900"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            Sign in
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  props: ['error'],
  methods: {
    login() {
      Auth.federatedSignIn({ provider: 'Google' })
    },
  },
}
</script>

<style scoped>
.login-button-wrapper {
  margin-top: 100px;
  text-align: center;
}

.login-button {
  padding: 50px;
  font-size: 32px;
}

.login-error-message {
  margin-bottom: 20px;
}
</style>
