<template>
  <div class="container mx-auto px-4">
    <div class="mt-20 overflow-hidden rounded-lg w-full m-auto">
      <h2 class="text-xl font-semibold margin-auto text-center p-2">
        MySungevity B2B overview
      </h2>
      <div class="flex flex-row">
        <div class="mx-3 overflow-hidden sm:rounded-lg w-5/6 h-auto">
          <div class="flex justify-between">
            <div class="px-4 py-5 sm:px-6 bg-white rounded-lg">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                B2B Accounts
              </h3>

              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Click to view and add logins
              </p>
            </div>
            <div class="mb-5">
              <sg-button
                class="m-auto"
                :color="'green'"
                :text="'Create New Location Group'"
                @click="showCreateGroupForm = true"
              />
            </div>
          </div>
          <div class="border-t border-gray-400 shadow">
            <dl>
              <div
                @click="navToGroup(group.name)"
                v-for="(group, idx) in groups"
                :key="group.description"
                class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 cursor-pointer hover:bg-blue-100"
                :class="idx % 2 !== 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <h1>{{ group.description }}</h1>
                <div class="text-xs text-gray-400">{{ group.name }}</div>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CreateGroupForm
    v-if="showCreateGroupForm"
    @close="showCreateGroupForm = false"
    @success="navToGroup"
  />
</template>

<script>
import { cognito, adminUserPoolId, adminB2BGroupName } from '@/aws'
import CreateGroupForm from '@/components/CreateGroupForm'

export default {
  components: {
    CreateGroupForm,
  },
  data() {
    return {
      showCreateGroupForm: false,
      b2bAdminUsers: [],
      b2bAdminName: '',
      formError: '',
    }
  },
  computed: {
    groups() {
      return this.$store.state.b2b.cognitoGroups
    },
  },
  methods: {
    navToGroup(groupName) {
      this.$router.push({ name: 'b2b-group-view', params: { groupName } })
    },
    resetForm() {
      this.b2bAdminName = this.formError = ''
    },
    async addAdminUserToGroup() {
      try {
        const {
          Users: [user],
        } = await cognito
          .listUsers({
            UserPoolId: adminUserPoolId,
            Filter: `email="${this.b2bAdminName.trim()}@sungevity-intl.com"`,
          })
          .promise()
        if (!user) throw new Error('User does not exist')
        await cognito
          .adminAddUserToGroup({
            UserPoolId: adminUserPoolId,
            GroupName: adminB2BGroupName,
            Username: user.Username,
          })
          .promise()
        this.resetForm()
        await this.fetchB2BAdminUsers()
      } catch (error) {
        this.formError = `Error: ${error.message}`
      }
    },
    async removeAdminUserFromGroup(user) {
      await cognito
        .adminRemoveUserFromGroup({
          UserPoolId: adminUserPoolId,
          GroupName: adminB2BGroupName,
          Username: user.id,
        })
        .promise()
      await this.fetchB2BAdminUsers()
    },
    async fetchB2BAdminUsers() {
      const { Users: users } = await cognito
        .listUsersInGroup({
          GroupName: adminB2BGroupName,
          UserPoolId: adminUserPoolId,
          Limit: 60,
        })
        .promise()

      if (users.length > 59) alert('Somebody alert IT: we need pagination')

      this.b2bAdminUsers = users.map(({ Username, Attributes }) => {
        const get = (n) => Attributes.filter(({ Name }) => Name === n).map((a) => a.Value)[0]
        return {
          id: Username,
          email: get('email'),
        }
      })
    },
  },
  mounted() {
    this.fetchB2BAdminUsers()
    this.$store.dispatch('fetchCognitoGroupsIfNotSet')
  },
}
</script>
