<template>
  <div v-if="!finished" class="container px:4 sm:px-8 lg:px-16 my-10">
    <div>
      <div>
        <h2 class="text-xl font-semibold margin-auto text-center p-2">
          Sungevity Lead Form
        </h2>
        <p class="text-m margin-auto text-center p-2">
          This form allows you to easily interact with the Sungevity Lead API. It is mainly meant to
          assist testing outside of the production environment.
        </p>
        <p v-if="isProductionEnv" class="text-xl font-semibold margin-auto text-center p-2">
          You are in the Production environment. Please only use this form if you're absolutely
          certain about what you are doing.
        </p>
        <div class="flex items-center">
          <switch-component :value="extendedForm" @input="(e) => extendForm(e)"></switch-component>
          <span class="ml-3 my-2 text-sm font-medium text-gray-900">Use the extended form</span>
        </div>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-2">
          <div class="col-span-2">
            <label for="firstName" class="form-label">
              First Name*
            </label>
            <input
              v-model="vv.firstName.$model"
              title="First Name"
              type="text"
              name="firstName"
              id="firstName"
            />
            <span
              class="text-red-900 text-sm"
              v-if="vv.firstName.$dirty && vv.firstName.$errors.length"
            >
              {{ vv.firstName.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-2">
            <label for="lastName" class="form-label">
              Last Name*
            </label>
            <input
              v-model="vv.lastName.$model"
              title="Last Name"
              type="text"
              name="lastName"
              id="lastName"
            />
            <span
              class="text-red-900 text-sm"
              v-if="vv.lastName.$dirty && vv.lastName.$errors.length"
            >
              {{ vv.lastName.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-2">
            <label for="email" class="form-label">
              Email*
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MailIcon class="h-5 w-5 text-gray-400"></MailIcon>
              </div>
              <input
                type="text"
                name="email"
                id="email"
                v-model.lazy.trim="vv.email.$model"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="you@example.com"
              />
            </div>
            <span class="text-red-900 text-sm" v-if="vv.email.$dirty && vv.email.$errors.length">
              {{ vv.email.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-2">
            <label for="telephone" class="form-label">
              Telephone*
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <PhoneIcon class="h-6 w-6 text-gray-400"></PhoneIcon>
              </div>
              <input
                type="text"
                name="telephone"
                id="telephone"
                v-model.lazy.trim="vv.telephone.$model"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="0612345678"
              />
            </div>
            <span
              class="text-red-900 text-sm"
              v-if="vv.telephone.$dirty && vv.telephone.$errors.length"
            >
              {{ vv.telephone.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-2" v-if="(vv.countryCode.$model != 'nl') | extendedForm">
            <label for="street" class="form-label">
              {{ vv.countryCode.$model == 'nl' ? 'Street' : 'Street*' }}
            </label>
            <input
              title="Street"
              type="text"
              name="street"
              id="street"
              v-model.lazy.trim="vv.street.$model"
            />
            <span class="text-red-900 text-sm" v-if="vv.street.$dirty && vv.street.$errors.length">
              {{ vv.street.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-2" v-if="vv.countryCode.$model == 'nl' && !extendedForm">
            <label for="postalCode" class="form-label">
              Postal Code*
            </label>
            <input
              title="Postal Code"
              type="text"
              name="postalCode"
              id="postalCode"
              v-model.lazy.trim="vv.postalCode.$model"
            />
            <span
              class="text-red-900 text-sm"
              v-if="vv.postalCode.$dirty && vv.postalCode.$errors.length"
            >
              {{ vv.postalCode.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-1">
            <label for="houseNumber" class="form-label">
              House Number*
            </label>
            <input
              title="House Number"
              type="number"
              name="houseNumber"
              id="houseNumber"
              v-model.lazy.trim="vv.houseNumber.$model"
            />
            <span
              class="text-red-900 text-sm"
              v-if="vv.houseNumber.$dirty && vv.houseNumber.$errors.length"
            >
              {{ vv.houseNumber.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-1">
            <label for="houseNumberAddition" class="form-label">
              House Number Addition
            </label>
            <input
              title="House Number Addition"
              type="text"
              name="houseNumberAddition"
              id="houseNumberAddition"
              v-model.lazy.trim="vv.houseNumberAddition.$model"
            />
            <span
              class="text-red-900 text-sm"
              v-if="vv.houseNumberAddition.$dirty && vv.houseNumberAddition.$errors.length"
            >
              {{ vv.houseNumberAddition.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-2" v-if="(vv.countryCode.$model != 'nl') | extendedForm">
            <label for="postalCode" class="form-label">
              Postal Code*
            </label>
            <input
              title="Postal Code"
              type="text"
              name="postalCode"
              id="postalCode"
              v-model.lazy.trim="vv.postalCode.$model"
            />
            <span
              class="text-red-900 text-sm"
              v-if="vv.postalCode.$dirty && vv.postalCode.$errors.length"
            >
              {{ vv.postalCode.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-2" v-if="(vv.countryCode.$model != 'nl') | extendedForm">
            <label for="city" class="form-label">
              {{ vv.countryCode.$model == 'nl' ? 'City' : 'City*' }}
            </label>
            <input
              title="City"
              type="text"
              name="city"
              id="city"
              v-model.lazy.trim="vv.city.$model"
            />
            <span class="text-red-900 text-sm" v-if="vv.street.$dirty && vv.street.$errors.length">
              {{ vv.street.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-1">
            <label for="countryCode" class="form-label">
              Country Code*
            </label>
            <select v-model="vv.countryCode.$model" name="countryCode" id="countryCode">
              <option>nl</option>
              <option>be</option>
            </select>
          </div>
          <div class="col-span-1">
            <label for="language" class="form-label">
              Language
            </label>
            <select v-model="vv.language.$model" name="language" id="language">
              <option>nl</option>
              <option v-if="vv.countryCode.$model == 'be'">fr</option>
            </select>
          </div>
          <div class="col-span-1">
            <label for="marketType" class="form-label">
              Market Type
            </label>
            <select v-model="vv.marketType.$model" name="marketType" id="marketType">
              <option>B2C</option>
              <option>B2B</option>
              <option></option>
            </select>
          </div>
          <div class="col-span-1">
            <label for="campaignName" class="form-label">
              Campaign
            </label>
            <input
              title="Campaign"
              type="text"
              name="campaignName"
              id="campaignName"
              v-model.lazy.trim="vv.campaignName.$model"
            />
          </div>
          <div class="col-span-1">
            <label for="campaignChannel" class="form-label">
              Channel
            </label>
            <input
              title="Channel"
              type="text"
              name="campaignChannel"
              id="campaignChannel"
              v-model.lazy.trim="vv.campaignChannel.$model"
            />
          </div>
          <div class="col-span-1">
            <label for="promoCode" class="form-label">
              Promo-code
            </label>
            <input
              title="Promo-code"
              type="text"
              name="promoCode"
              id="promoCode"
              v-model.lazy.trim="vv.promoCode.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="referralCode" class="form-label">
              Referral Code
            </label>
            <input
              title="Referral Code"
              type="text"
              name="referralCode"
              id="referralCode"
              v-model.lazy.trim="vv.referralCode.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="annualUsageDay" class="form-label">
              Annual Usage (day) kWh
            </label>
            <input
              title="Annual Usage (day)"
              type="number"
              name="annualUsageDay"
              id="annualUsageDay"
              v-model.lazy.trim="vv.annualUsageDay.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="annualUsageNight" class="form-label">
              Annual Usage (night) kWh
            </label>
            <input
              title="Annual Usage (night)"
              type="number"
              name="annualUsageNight"
              id="annualUsageNight"
              v-model.lazy.trim="vv.annualUsageNight.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="totalAnnualUsage" class="form-label">
              Total Annual Usage kWh
            </label>
            <input
              title="Total Annual Usage"
              type="number"
              name="totalAnnualUsage"
              id="totalAnnualUsage"
              v-model.lazy.trim="vv.totalAnnualUsage.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="roofType" class="form-label">
              Roof Type
            </label>
            <select v-model="vv.roofType.$model" name="roofType" id="roofType">
              <option>asbestos</option>
              <option>curved</option>
              <option>fiber</option>
              <option>green</option>
              <option>metal_cor</option>
              <option>metal_standing</option>
              <option>metal_tile</option>
              <option>other</option>
              <option>slate</option>
              <option>tar_flat</option>
              <option>tar_gravel_flat</option>
              <option>tar_pitch</option>
              <option>tar_shingles</option>
              <option>thatched</option>
              <option>tile_dark</option>
              <option>tile_red</option>
              <option></option>
            </select>
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="capacityMainFuse" class="form-label">
              Capacity Main Fuse
            </label>
            <select
              v-model="vv.capacityMainFuse.$model"
              name="capacityMainFuse"
              id="capacityMainFuse"
            >
              <option>other</option>
              <option value="1_x_15a">1 x 15A</option>
              <option value="1_x_18a">1 x 18A</option>
              <option value="1_x_20a">1 x 20A</option>
              <option value="1_x_25a">1 x 25A</option>
              <option value="1_x_27a">1 x 27A</option>
              <option value="1_x_30a">1 x 30A</option>
              <option value="1_x_32a">1 x 32A</option>
              <option value="1_x_35a">1 x 35A</option>
              <option value="1_x_40a">1 x 40A</option>
              <option value="1_x_50a">1 x 50A</option>
              <option value="1_x_63a">1 x 63A</option>
              <option value="3_x_15a">3 x 15A</option>
              <option value="3_x_18a">3 x 18A</option>
              <option value="3_x_20a">3 x 20A</option>
              <option value="3_x_25a">3 x 25A</option>
              <option value="3_x_27a">3 x 27A</option>
              <option value="3_x_30a">3 x 30A</option>
              <option value="3_x_32a">3 x 32A</option>
              <option value="3_x_35a">3 x 35A</option>
              <option value="3_x_40a">3 x 40A</option>
              <option value="3_x_50a">3 x 50A</option>
              <option value="3_x_63a">3 x 63A</option>
              <option value="3_x_80a">3 X 80A</option>
              <option value="3+n_x_16a">3+N x 16A</option>
              <option value="3+n_x_20a">3+N x 20A</option>
              <option value="3+n_x_25a">3+N x 25A</option>
              <option value="3+n_x_32a">3+N x 32A</option>
              <option value="1_x_dont_know_a">1 x don't know A</option>
              <option value="3_x_dont_know_a">3 x don't know A</option>
              <option value="3+n_x_dont_know_a">3+N x don't know A</option>
              <option></option>
            </select>
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="ageOfProperty" class="form-label">
              Age of Property
            </label>
            <select v-model="vv.ageOfProperty.$model" name="ageOfProperty" id="ageOfProperty">
              <option>new_build</option>
              <option>existing_build</option>
              <option>building_younger_than_10_years</option>
              <option>building_older_than_10_years</option>
              <option></option>
            </select>
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="leadScore" class="form-label">
              Lead Score
            </label>
            <input
              title="Lead Score"
              type="number"
              name="leadScore"
              id="leadScore"
              v-model.lazy.trim="vv.leadScore.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="offerRequestDate" class="form-label">
              Offer Request Date
            </label>
            <input
              title="Offer Request Date"
              type="text"
              name="offerRequestDate"
              id="offerRequestDate"
              placeholder="2023-06-14"
              v-model.lazy.trim="vv.offerRequestDate.$model"
            />
          </div>
          <div class="col-span-2 md:col-span-4" v-if="extendedForm">
            <label for="comments" class="form-label">
              Comments
            </label>
            <textarea
              rows="4"
              name="comments"
              id="comments"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              v-model.lazy.trim="vv.comments.$model"
            ></textarea>
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="interestedInFinancing" class="form-label">
              Interested in Financing
            </label>
            <input
              title="Interested in Financing"
              type="checkbox"
              name="interestedInFinancing"
              id="interestedInFinancing"
              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              v-model.lazy.trim="vv.interestedInFinancing.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="offerBattery" class="form-label">
              Offer Battery
            </label>
            <input
              title="Offer Battery"
              type="checkbox"
              name="offerBattery"
              id="offerBattery"
              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              v-model.lazy.trim="vv.offerBattery.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="offerChargingPoint" class="form-label">
              Offer Charging Point
            </label>
            <input
              title="Offer Charging Point"
              type="checkbox"
              name="offerChargingPoint"
              id="offerChargingPoint"
              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              v-model.lazy.trim="vv.offerChargingPoint.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="offerHeatPump" class="form-label">
              Offer Heat Pump
            </label>
            <input
              title="Offer Heat Pump"
              type="checkbox"
              name="offerHeatPump"
              id="offerHeatPump"
              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              v-model.lazy.trim="vv.offerHeatPump.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="offerInverter" class="form-label">
              Offer Inverter
            </label>
            <input
              title="Offer Inverter"
              type="checkbox"
              name="offerInverter"
              id="offerInverter"
              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              v-model.lazy.trim="vv.offerInverter.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="offerMeter" class="form-label">
              Offer Meter
            </label>
            <input
              title="Offer Meter"
              type="checkbox"
              name="offerMeter"
              id="offerMeter"
              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              v-model.lazy.trim="vv.offerMeter.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="offerMonitoring" class="form-label">
              Offer Monitoring
            </label>
            <input
              title="Offer Monitoring"
              type="checkbox"
              name="offerMonitoring"
              id="offerMonitoring"
              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              v-model.lazy.trim="vv.offerMonitoring.$model"
            />
          </div>
          <div class="col-span-1" v-if="extendedForm">
            <label for="offerPanel" class="form-label">
              Offer Panel
            </label>
            <input
              title="Offer Panel"
              type="checkbox"
              name="offerPanel"
              id="offerPanel"
              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              v-model.lazy.trim="vv.offerPanel.$model"
            />
          </div>
          <div class="col-span-3" v-if="extendedForm">
            <label for="offerService" class="form-label">
              Offer Service
            </label>
            <input
              title="Offer Service"
              type="checkbox"
              name="offerService"
              id="offerService"
              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              v-model.lazy.trim="vv.offerService.$model"
            />
          </div>
          <div class="col-span-2" v-if="!isProductionEnv">
            <div class="form-label">
              To which environment should this lead be sent?
            </div>
            <div class="col-span-2">
              <select v-model="vv.crmInstance.$model" name="crmInstance" id="crmInstance">
                <option>odoo-sandbox</option>
                <option>odoo-test</option>
                <option>odoo-acceptance</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 text-red-600 text-sm" v-if="vv.$error || error">
        {{ error || 'Please resolve the errors above' }}
      </div>
    </div>
    <div class="container mx-auto pt-6">
      <button
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        @click="onSubmit"
      >
        <Spinner
          class="w-6 h-6 border-4 border-gray-500 rounded-full loader"
          v-if="loading"
        ></Spinner>
        Confirm
      </button>
    </div>
  </div>
  <div v-if="finished">
    <div class="p-4">
      <div class="container mx-auto bg-green-50 rounded-md  p-4">
        <div class="flex">
          <CheckCircleIcon class="-ml-1 mr-3 h-5 w-5"></CheckCircleIcon>
          <h3 class="text-sm font-medium text-green-800">
            Lead has been accepted by the Lead API.
          </h3>
        </div>
        <div class="mt-2 text-sm text-green-700">
          <p>
            It should appear in the chosen CRM soon.
          </p>
        </div>
        <div class="mt-4">
          <div class="-mx-2 -my-1.5 flex">
            <button
              type="button"
              class="ml-3 bg-green-100 px-2 py-1.5 rounded-md text-sm font-medium text-green-900 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
              @click="finished = false"
            >
              Give me another!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import {
  email as emailValidation,
  helpers,
  maxLength,
  numeric,
  required,
  requiredIf,
} from '@vuelidate/validators'
import SwitchComponent from '@/components/utils/Switch'
import MailIcon from 'heroicons/vue/solid/Mail'
import PhoneIcon from 'heroicons/vue/outline/Phone'
import CheckCircleIcon from 'heroicons/vue/solid/CheckCircle'
import Spinner from '@/components/utils/Spinner'

export default {
  components: {
    SwitchComponent,
    MailIcon,
    PhoneIcon,
    CheckCircleIcon,
    Spinner,
  },
  setup() {
    const isProductionEnv = window.location.href.includes('admin.sungevity')
    const loading = ref(false)
    const error = ref('')
    const finished = ref(false)
    const extendedForm = ref(false)

    const firstName = ref('')
    const lastName = ref('')
    const email = ref('')
    const telephone = ref('')
    const street = ref('')
    const houseNumber = ref(null)
    const houseNumberAddition = ref('')
    const postalCode = ref('')
    const city = ref('')
    const countryCode = ref('nl')
    const language = ref('nl')
    const marketType = ref('B2C')
    const campaignName = ref('B2C-BE-DEFAULT')
    const campaignChannel = ref('ENGIE-Customer')
    const promoCode = ref('ENGIE-SUNSHINE')
    const referralCode = ref('')
    const annualUsageDay = ref(null)
    const annualUsageNight = ref(null)
    const totalAnnualUsage = ref(null)
    const roofType = ref('')
    const capacityMainFuse = ref('')
    const ageOfProperty = ref('')
    const leadScore = ref(null)
    const offerRequestDate = ref(undefined)
    const comments = ref('')
    const crmInstance = ref('odoo')
    const interestedInFinancing = ref(undefined)
    const offerBattery = ref(undefined)
    const offerChargingPoint = ref(undefined)
    const offerHeatPump = ref(undefined)
    const offerInverter = ref(undefined)
    const offerMeter = ref(undefined)
    const offerMonitoring = ref(undefined)
    const offerPanel = ref(undefined)
    const offerService = ref(undefined)

    const vv = useVuelidate(
      {
        firstName: { required },
        lastName: { required },
        email: {
          emailValidation,
          required,
        },
        telephone: {
          required,
          validTelephone: helpers.withMessage(
            'Must be a valid telephone number',
            (v) => ['+', '0'].includes(v[0]) && v.length >= 6
          ),
        },
        street: {
          required: requiredIf(function() {
            return countryCode.value !== 'nl'
          }),
        },
        houseNumber: { numeric, required },
        houseNumberAddition: {
          maxLength: maxLength(6),
        },
        postalCode: {
          required,
          validPostalCode: helpers.withMessage(
            'Must be a valid postal code',
            (v) => /^\d{4}\s?\w{2}$/.test(v) || /^\d{4}$/.test(v)
          ),
        },
        city: {
          required: requiredIf(function() {
            return countryCode.value !== 'nl'
          }),
        },
        countryCode: { required },
        language: {},
        marketType: {},
        campaignName: {},
        campaignChannel: {},
        promoCode: {},
        referralCode: {},
        annualUsageDay: { numeric },
        annualUsageNight: { numeric },
        totalAnnualUsage: { numeric },
        roofType: {},
        capacityMainFuse: {},
        ageOfProperty: {},
        leadScore: { numeric },
        offerRequestDate: {},
        comments: {},
        crmInstance: { required },
        interestedInFinancing: {},
        offerBattery: {},
        offerChargingPoint: {},
        offerHeatPump: {},
        offerInverter: {},
        offerMeter: {},
        offerMonitoring: {},
        offerPanel: {},
        offerService: {},
      },
      {
        firstName,
        lastName,
        email,
        telephone,
        street,
        houseNumber,
        houseNumberAddition,
        postalCode,
        city,
        countryCode,
        language,
        marketType,
        campaignName,
        campaignChannel,
        promoCode,
        referralCode,
        annualUsageDay,
        annualUsageNight,
        totalAnnualUsage,
        roofType,
        capacityMainFuse,
        ageOfProperty,
        leadScore,
        offerRequestDate,
        comments,
        crmInstance,
        interestedInFinancing,
        offerBattery,
        offerChargingPoint,
        offerHeatPump,
        offerInverter,
        offerMeter,
        offerMonitoring,
        offerPanel,
        offerService,
      }
    )

    function extendForm(e) {
      extendedForm.value = e
    }

    const postToleadAPI = async (data) => {
      loading.value = true
      error.value = ''

      const api = process.env.VUE_APP_LEAD_API_URL
      const apiKey = process.env.VUE_APP_LEADS_API_KEY

      const response = await fetch(api, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'X-Api-Key': apiKey,
        },
      })
      loading.value = false
      if (response.ok) finished.value = true
      const body = await response.json()
      if (body.error) {
        console.error(body.error)
        error.value = body.error
      }
      return body
    }

    const onSubmit = async () => {
      console.log(vv)
      vv.value.$touch()
      if (vv.value.$invalid) return

      const variables = {
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
        telephone: telephone.value,
        street: street.value || undefined,
        house_number: houseNumber.value,
        house_number_addition: houseNumberAddition.value || undefined,
        postal_code: postalCode.value,
        city: city.value || undefined,
        country: countryCode.value,
        language: language.value,
        market_type: marketType.value,
        campaign_name: campaignName.value,
        campaign_channel: campaignChannel.value,
        promo_code: promoCode.value,
        referral_code: referralCode.value || undefined,
        annual_usage_day_kwh: annualUsageDay.value || undefined,
        annual_usage_night_kwh: annualUsageNight.value || undefined,
        annual_usage_total_kwh: totalAnnualUsage.value || undefined,
        roof_type: roofType.value || undefined,
        capacity_main_fuse: capacityMainFuse.value || undefined,
        age_of_property: ageOfProperty.value || undefined,
        lead_score: leadScore.value || undefined,
        offer_request_date: offerRequestDate.value,
        comments: comments.value || undefined,
        crm_instance: crmInstance.value,
        interested_in_financing: interestedInFinancing.value,
        offer_battery: offerBattery.value,
        offer_charging_point: offerChargingPoint.value,
        offer_heat_pump: offerHeatPump.value,
        offer_inverter: offerInverter.value,
        offer_meter: offerMeter.value,
        offer_monitoring: offerMonitoring.value,
        offer_panel: offerPanel.value,
        offer_service: offerService.value,
      }
      await postToleadAPI(variables)
      return 'OK'
    }
    return {
      vv,
      onSubmit,
      loading,
      error,
      finished,
      isProductionEnv,
      extendedForm,
      extendForm,
    }
  },
}
</script>

<style lang="postcss" scoped>
input[type='text'],
input[type='number'],
input[type='date'],
select {
  @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}

label {
  cursor: pointer;
}

.form-label {
  @apply block text-sm font-medium text-gray-700;
}
</style>
